.request-service {
  padding: 20px 10px 0;

  .container-address-confirm-actions {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;
    align-items: center;

    button {
      width: 190px;
      border-radius: 1px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}


.text-danger {
  position: absolute;
  text-align: start;
}