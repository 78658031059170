.container-main {
  padding-top: 30px;

  .wrap-form {
    margin-bottom: 20px;

    .input-icon-wrap {
      position: relative;
      border: none;
      display: flex;
      flex-direction: row-reverse;
      margin: 0 auto;

      .input-icon {
        width: 42px;
        height: 40px;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        color: #194883;
        padding: 0px 10px;
        align-items: center;
        background: #edeef0;
        border-radius: 0 4px 4px 0;


        path {
          fill: #194883
        }
      }
    }
  }
}