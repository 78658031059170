.device-mobile .clients-filter-content {
	width: 100%;
	position: relative;

	.block-filter .block-filter-fields label {
		width: auto;
	}

	.clients-filter-actions {
		bottom: 0px;
		width: 100%;
		display: flex;
		position: absolute;
		justify-content: center;
	}
}
