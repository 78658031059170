.prp-users-app-list {
    height: 100%;
    display: flex;
    padding: 0px 20px;
    margin: 0 -20px;
    flex-direction: column;

    .prp-users-app-table {
        gap: 10px;
        height: 100%;
        display: flex;
        overflow-y: auto;
        margin: 20px -20px;
        flex-direction: column;
        max-height: calc(100% - 130px);
    }
}

.device-mobile .prp-users-app-list {
    .prp-users-app-table {
        max-height: calc(100% - 112px);
        margin: 20px -20px 10px;
    }
}