.auto-complete-form-content {
	.MuiFormControl-root {
		width: 100%;
	}
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
		padding: 0px 0px 0px 10px;
		border-radius: 0px !important;
		border-color: #cccccc;
		border-top: 0px !important;
		border-right: 0px !important;
		border-bottom: 0px !important;
	}
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border: 1px solid #cccccc !important;
		border-radius: 2px;
		outline: none;
		content: '' !important;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid #cccccc !important;
		border-radius: 2px;
		outline: none;
		content: '' !important;
	}
	#dep-select-list-popup {
		padding: 0px;
		border-radius: 6px !important;
		box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15) !important;
	}
	#dep-select-list-popup .MuiAutocomplete-option {
		font-size: 12px !important;
		line-height: 16px !important;
		text-transform: capitalize;
	}
	.MuiFormControl-marginNormal {
		margin: 0px 0px 15px;
	}
	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment {
		right: 15px;
	}
}
