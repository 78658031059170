.title-modal {
  font-size: 16px;
  color: #194883;
  font-weight: 400;
  text-align: start;
}

.container-address-confirm {
  padding-top: 20px;
  max-width: 480px;

  .container-address {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    .container-f {
      gap: 5px;
      width: 100%;
      display: flex;
      margin: auto;
      padding: 20px;
      border-radius: 2px;
      background: #ffffff;
      border: 0.5px solid;
      border-image-slice: 1;
      background-origin: border-box;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      border-image-source: linear-gradient(to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0) 80%,
          rgba(0, 0, 0, 0.02) 95%,
          rgba(0, 0, 0, 0.04));
      background-image: #ffffff,
        linear-gradient(to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0) 80%,
          rgba(0, 0, 0, 0.02) 95%,
          rgba(0, 0, 0, 0.04));

      .item-f {
        margin: 5px;
        color: #194883;
      }

      .text-align-l {
        text-align: left;
      }
    }
  }

  .not-address-action {
    margin-top: 15px;

    span {
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      color: #194883;
      text-decoration: underline 2px #194883;
    }
  }

  .container-address-confirm-actions {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;
    text-transform: uppercase;
    font-weight: 600;

    button {
      width: 190px;
      border-radius: 1px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}