.order-follow-up-tab-guide-selector {
	gap: 40px;
	width: 100%;
	display: flex;
	margin-top: 30px;
	padding: 5px 20px;
	margin-left: -20px;
	background: transparent linear-gradient(90deg, #CBDAEB40 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;

	span {
		white-space: nowrap;

		cursor: pointer !important;
		font-size: 14px;
		color: #004687;
		font-weight: 500;
		text-align: center;
		letter-spacing: 0.28px;
		text-transform: uppercase;

		&:hover {
			text-decoration: underline;
		}
	}
}

@media only screen and (max-width: 577px) {
	.order-follow-up-tab-guide-selector {
		overflow-x: auto;
	}
}