.history-pendency-list {
	display: flex;
	flex-direction: column;
	width: 710px;
	height: 510px;
	gap: 20px;
	.history-pendency-header {
		font-size: 13px;
		label {
			margin: 0;
		}
		div {
			display: grid;
			grid-template-columns: 40% 30%;
		}
	}
	.history-pendency-body {
		height: 100%;
		min-height: 297px;
		max-height: 442px;
		overflow: overlay;
		.timeline-pendency {
			display: flex;
			height: auto;
			border: none;

			.obs-history-timeline {
				margin-bottom: 30px;
				font-size: 13px;
				label {
					margin-top: 5px;
				}
			}

			@media screen and (max-width: 479px) {
				position: relative;
				margin-bottom: 0px;
			}

			.service-indicators {
				height: auto;
				min-height: 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				left: 6px;
				.circle-indicator {
					position: absolute;
					top: 4px;
					width: 10px;
					height: 10px;
					border-radius: 20px;
					background-color: #4e8de8;
				}
				.line-indicator {
					width: 2px;
					margin-top: 20px;
					min-height: 100%;
					background-color: #4e8de8;
				}
				.line-indicator.first-last {
					min-height: 100%;
					margin-top: 0px;
					position: absolute;
					top: 4px;
				}
				.line-indicator.last {
					min-height: 100%;
					margin: 0px;
					position: absolute;
					top: 4px;
				}
				.dots-indicator {
					display: none;
					height: 20px;
					border-style: dashed;
					border-width: 1px;
					border-color: #4e8de8;
				}

				.dots-indicator.last {
					display: unset;
					position: absolute;
					bottom: -24px;
				}
			}

			.service-indicators.closed .dots-indicator,
			.service-indicators.closed .line-indicator {
				display: none;
			}

			.timeline-pendency-template {
				margin-bottom: 40px;
				margin-left: 16px;

				.template-title {
					margin-top: 2px;
					margin-bottom: 1px;
					font-size: 16px;
					font-weight: 700;
					text-align: left;
					text-transform: none;
				}

				.template-body {
					padding-bottom: 50px;
					color: rgba(51, 51, 51, 0.8);
					text-align: left;
				}
			}
		}

		.device-mobile .timeline-service {
			display: flex;
			height: auto;
			border: none;

			.timeline-pendency-template {
				margin-left: 16px;
				margin-bottom: 40px;

				.template-title {
					font-size: 12px;
					margin-top: 2px;
					margin-bottom: 0px;
				}

				.template-date-of-creation {
					font-size: 12px;
					div {
						font-size: 12px;
					}
				}

				.template-body {
					font-size: 12px;
					padding-bottom: 20px;
				}
			}
		}
	}
	.history-pendency-action {
		display: flex;
		justify-content: center;
		gap: 20px;
		button {
			width: 188px;
			font-size: 13px;
			height: 38px;
		}
	}
}

@media only screen and (max-width: 480px) {
	.history-pendency-list {
		width: 100%;
		display: flex;
		row-gap: 20px;
		flex-direction: column;
		height: 100%;
		position: relative;
		.history-pendency-header {
			div {
				display: grid;
				grid-template-columns: 50% 30%;
			}
		}
		.history-pendency-body {
			height: 100%;
			min-height: 0px;
		}
		.history-pendency-add-observation {
			margin: 0 0 50px 0;
		}
		.history-pendency-action {
			position: absolute;
			bottom: 0px;
			width: 100%;
			#add-observation {
				width: 188px;
			}
			button {
				width: 150px;
			}
		}
	}
}
