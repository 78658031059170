.filter-view-contain {
	width: 760px;
	height: 450px;
	position: relative;

	.filter-view-contain-table {
		height: 100%;
		margin: 0px -20px;
		overflow-y: auto;
		overflow-x: hidden;

		.filter-view-contain-table-header {
			height: auto;
			display: flex;
			padding: 10px 20px;
			align-items: center;
			align-items: flex-start;
			justify-content: space-between;

			.filter-view-contain-table-column {
				font-size: 12px;
				font-weight: 700;
			}
		}

		.filter-view-contain-table-lines {
			height: auto;
			display: flex;
			padding: 10px 20px;
			align-items: center;
			align-items: flex-start;
			justify-content: space-between;

			.filter-view-contain-table-column {
				font-size: 12px;
				font-weight: 400;
			}
		}
		.filter-view-contain-table-lines:nth-child(even) {
			background-color: #e5e5e5;
		}

		.column-filter-states {
			width: 100px;
		}
		.column-filter-status {
			width: 100px;
		}
		.column-filter-type {
			width: 120px;
		}
		.column-filter-cliente-group {
			width: 200px;
		}
		.column-filter-name {
			width: 120px;
		}
		.column-filter-more-options {
			width: 25px;
			button {
				background-color: transparent;
			}

			.more-option-item-click {
				transform: scale(0.5);
			}
		}
	}

}
.filter-view-footer-contain {
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;
}

.device-mobile .filter-view-contain {
	width: 100%;
	height: 100%;

	.filter-view-contain-table {
		margin: 0px -20px;

		.filter-view-contain-table-lines {
			height: auto;
			padding: 20px;
			position: relative;
			flex-direction: column;
			align-items: flex-start;

			.filter-view-contain-table-column {
				width: 100%;
				display: flex;
				span {
					margin-right: 5px;
					font-weight: 700;
				}
			}

			.column-filter-more-options {
				width: 25px;
				top: 5px;
				right: 0;
				position: absolute;
			}
		}
	}
}
