#obsdelete,
.delete-os-warning {
	width: 650px;
	margin-top: 10px;
}

.device-mobile .MuiFormControl-fullWidth,
.device-mobile .delete-os-warning {
	width: 100% !important;
}
