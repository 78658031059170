.service-orders-list-header {
	width: 100%;
	height: 35px;
	display: flex;
	padding: 0px 20px;
	align-items: center;
	background-color: #ffffff;

	span {
		font-weight: 700;
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-display: swap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.service-orders-list-item {
		margin: 0px 10px 0px 0px;
		padding: 0px;
	}

	.service-orders-list-created_at {
		width: 7%;
		white-space: nowrap;
	}
	.service-orders-list-order {
		width: 8%;
	}
	.service-orders-list-state {
		width: 6%;
		white-space: nowrap;
	}
	.service-orders-list-company-name {
		width: 15%;
	}
	.service-orders-list-equipment-name {
		width: 20%;
	}
	.service-orders-list-equipment-serie {
		width: 7%;
	}
	.service-orders-list-status {
		width: 11%;
		white-space: nowrap;
	}
	.service-orders-list-type {
		width: 15%;
		white-space: nowrap;
	}
	.service-orders-list-follow-up {
		width: 9%;
		white-space: nowrap;
	}
	.service-orders-list-more-options {
		width: 2%;
		max-width: 10px;
	}
}

.device-mobile .service-orders-list-header {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}
