.image-background {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: block;
	overflow: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	background-color: rgba(219, 219, 219, 0.41);
}

@media screen and (max-width: 991px) {
	.image-background {
		position: static;
	}
}

@media screen and (max-width: 767px) {
	.image-background {
		width: 100%;
		padding: 70px 0px 0px;
	}
}

.Opt:hover {
	background-color: rgba(0, 0, 0, 0.19);
}

.modal {
	backdrop-filter: blur(5px);
}
