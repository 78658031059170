.service-order-equipment-edit-form button {
	width: 38px;
	background-color: transparent;
	display: flex;
	margin-left: 0px;
	margin-top: 5px;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.service-order-equipment-edit-form input {
	width: 240px;
}
