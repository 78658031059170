.new-pendency {
	width: 610px;
	height: 272px;
	display: grid;
	position: relative;
	grid-template-columns: 1fr 2fr;

	.new-pendency-category {
		margin-right: 20px;

		.new-pendency-category-name {
			font-size: 13px;
			font-weight: bold;
		}

		.dropdown-show {
			position: relative;
			max-height: 156px;
			text-transform: capitalize;
		}
	}

	.new-pendency-category:last-child {
		margin-right: 0px;
	}
}

.new-pendency-actions {
	bottom: 0;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;

	button {
		width: 108px;
		margin-right: 20px;
	}

	button:last-child {
		margin-right: 0px;
	}
}

@media only screen and (max-width: 700px) {
	.new-pendency {
		width: 100%;
	}
}

@media only screen and (max-width: 430px) {
	.new-pendency {
		width: 100%;
		display: flex;
		row-gap: 20px;
		flex-direction: column;
		.new-pendency-category {
			margin-right: 0px;
		}
	}
}

.device-mobile .new-pendency {
	width: 100%;
	display: flex;
	row-gap: 20px;
	flex-direction: column;
	height: 100%;

	.new-pendency-category {
		margin-right: 0px;

		.dropdown-show {
			position: relative;
			max-height: calc(var(--app-height) - 45vh);
			text-transform: capitalize;
		}
	}
}

.device-mobile .new-pendency-actions {
	bottom: 0px;
	display: flex;
	position: relative;
	justify-content: center;
}
