.custom-button-primary,
.custom-button-secondary {
	width: 120px;
	height: 36px;
	border-radius: 6px;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px 10px;
	border: none;
	cursor: pointer;
}

.custom-button-primary:disabled,
.custom-button-secondary:disabled {
	cursor: not-allowed;
	filter: grayscale(100);
}

.custom-button-primary {
	background-color: #225294;
	color: #fff;
}

.custom-button-secondary {
	color: #225294;
	background-color: transparent;
	text-decoration: underline;
}
