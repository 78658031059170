.version-list {
    height: calc(100dvh - 105px);
    display: flex;
    margin: 0 -20px;
    padding: 0px 20px;
    flex-direction: column;

    .version-table {
        gap: 10px;
        height: 100%;
        display: flex;
        overflow-y: auto;
        margin: 30px -20px 0px;
        flex-direction: column;
        max-height: calc(100% - 70px);
    }

    .version-list-paginator {
        margin-top: 20px;
    }
}

.device-mobile .version-list {
    .version-table {
        margin: 20px -20px 10px;
    }
}

.new-app-version-modal {
    width: 500px;

    .new-app-version-form {
        gap: 20px;
        display: flex;
        flex-direction: column;

        .new-app-version-form-inputs {
            gap: 20px;
            display: flex;
            flex-direction: row;

            .new-app-version-form-input {
                width: 100%;

                &:first-child {
                    width: 40%;
                }
            }

        }
    }
}
.new-app-version-actions {
    bottom: 0;
    width: 100%;
    display: flex;
    margin-top: 20px;
    position: relative;
    justify-content: center;

    button {
        width: 108px;
        margin-right: 20px;
    }

    button:last-child {
        margin-right: 0px;
    }
}