.all-pendency-list-header {
	width: 100%;

	height: 35px;
	display: flex;
	min-width: 1070px;
	align-items: center;
	background-color: #ffffff;

	span {
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-weight: bold;
		// overflow: hidden;
		// white-space: nowrap;
		// text-overflow: ellipsis;
	}

	.all-pendency-list-item {
		padding: 0px;
		margin: 0px 20px 0px 0px;
	}
	.all-pendency-list-pendency {
		width: 30%;
		margin-left: 20px;
		min-width: 206px;
	}
	.all-pendency-list-inclusion-date {
		width: 102px;
		min-width: 102px;
	}
	.all-pendency-list-origin {
		width: 180px;
		min-width: 120px;
	}
	.all-pendency-list-pendency-type {
		width: 180px;
		min-width: 120px;
	}
	.all-pendency-list-resp-solution {
		width: 180px;
		min-width: 120px;
	}
	.all-pendency-list-expected-date {
		width: 180px;
		min-width: 110px;
	}
	.all-pendency-list-status {
		width: 110px;
		min-width: 80px;
	}

	.all-pendency-list-item-actions {
		width: 35px;
		margin: 0px 5px 0px;
		padding: 0px;
	}
	.all-pendency-list-item-options {
		display: flex;
		margin: 0px 20px 0px 0px;
		min-width: 10px;
	}
}

@media only screen and (max-width: 480px) {
	.all-pendency-list-header {
		display: none;
	}
}
