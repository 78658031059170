.new-order-information {
	gap: 20px;
	width: 100%;
	display: grid;

	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

	.message-for-invoice,
	.new-order-information-contain-action {
		grid-column-start: 1;
		grid-column-end: 6;
	}

	@media screen and (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;

		.message-for-invoice,
		.new-order-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 5;
		}
	}

	@media screen and (max-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr;

		.message-for-invoice,
		.new-order-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 4;
		}
	}

	@media screen and (max-width: 980px) {
		grid-template-columns: 1fr 1fr;

		.message-for-invoice,
		.new-order-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}

	@media screen and (max-width: 580px) {
		grid-template-columns: 1fr;
		.message-for-invoice,
		.new-order-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 1;
		}
	}

	.new-order-information-contain-information {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0px 10px 0px 0px;

		.invoice-span-button {
			border: none;
			height: 100%;
			display: flex;
			color: #004992;
			align-items: flex-end;
			background: transparent;
			text-decoration-line: underline;
		}

		.order-issue-date {
			display: flex;
			align-items: center;
			height: 40px;
		}

		.error-order-customer-container {
			background-color: #ffe5e5 !important;
			border: 1px solid #c21618 !important;

			.fake-placeholder {
				color: #c21618 !important;
			}
		}

		.order-customer-container {
			height: 40px;
			display: grid;
			column-gap: 10px;
			border-radius: 6px;
			align-items: center;
			background-color: #f2f2f2;
			justify-content: space-between;
			grid-template-columns: calc(100% - 40px) 30px;

			small {
				grid-row-start: 2;

				span {
					padding: 0px;
					font-size: 11px;
				}
			}

			.fake-placeholder {
				color: #757575
			}

			span {
				font-size: 12px;
				padding-left: 10px;
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}

			div {
				grid-column-start: 2;
				width: 30px;

				svg {
					cursor: pointer;
					width: 25px;
				}
			}
		}

		.order-payment-form-container {
			display: grid;
			align-items: center;
			justify-content: space-between;
			column-gap: 10px;
			grid-template-columns: calc(100% - 40px) 30px;

			small {
				grid-row-start: 2;
			}

			.MuiSvgIcon-root {
				color: #5daeff;
				cursor: pointer;
			}

			img {
				grid-column-start: 2;
				width: 30px;
				cursor: pointer;
			}
		}

		@media screen and (min-width: 1000px) {
			span {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

		header {
			font-size: 12px;
			margin-bottom: 10px;
			justify-content: flex-start;
			font-weight: 600;
		}
	}

	.new-order-information-contain-action {
		width: 100%;
		display: grid;
		grid-template-columns: 128px 128px;
		justify-content: center;
		gap: 20px;
	}
}
