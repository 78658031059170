.main-install-and-search {
  padding: 20px 10px 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);
  gap: 20px;

  .header {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      width: 190px
    }

  }

  .content {
    width: 100%;
    height: 100%;
  }

}