.device-mobile .transfer-list-item {
	border: none;
	padding: 20px;
	padding: 20px;
	margin: 0px -20px;
	position: relative;
	flex-direction: row;

	&:nth-child(even) {
		background-color: #e5e5e5;
	}

	.options {
		position: absolute;
		top: 20px;
		right: 0px;
	}

	.transfer-info {
		width: 100%;
		flex-direction: column;
		align-items: stretch;
		row-gap: 1px;

		.transfer-info-first-line {
			width: 100%;
			padding: 0px;
			display: grid;
			grid-template-columns: 1fr 1.2fr;

			text-overflow: initial;
			overflow: initial;
			white-space: nowrap;
		}

		.transfer-info-item {
			display: flex;
			padding: 0px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			.transfer-info-title {
				font-size: 12px;
				font-weight: 700;
				margin-right: 5px;
				overflow: initial;
				white-space: nowrap;
				text-overflow: initial;
			}

			.transfer-info {
				font-size: 12px;
				padding: 0px;
				font-weight: 400;
				align-items: self-start;
				width: calc(100vw - 40px);
				flex-direction: column !important;

				div {
					text-align: left;
					text-overflow: initial;
					overflow: initial;
					white-space: nowrap;
					padding: 0px;
				}

				.transfer-info-first-line {
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 1fr;

					.options {
						position: absolute;
					}
				}
			}
		}
	}
}
