.tech1 {
	padding-left: 30px;
}

.page-os-header {
	height: 30px;
	margin-bottom: 15px;
}

.page-os-details {
	width: 100%;
	margin-bottom: 8px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
}

.page-os-details-info {
	display: flex;
	width: 74%;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	margin-right: 20px;
}

.page-os-client-info,
.page-os-history-info {
	width: 100%;
	display: grid;
	grid-template-columns: 33% 33% 33%;
	align-items: center;
	justify-content: flex-start;
}

.page-os-history-info {
	margin-top: 10px;
}

.page-os-client-info-item,
.page-os-history-info-item {
	padding-right: 10px;
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
	font-size: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.page-os-client-info-item p,
.page-os-history-info-item p {
	margin: 0px;
	font-weight: 600;
}

.page-os-client-info-item span,
.page-os-history-info-item span {
	margin-left: 5px;
	font-weight: 400;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	align-items: center;
}

.page-os-warning-box {
	background-color: rgba(194, 22, 24, 0.1);
	border-radius: 5px;
	width: 300px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.page-os-warning-box-header {
	background-color: rgba(194, 22, 24, 1);
	border-radius: 5px 5px 0 0;
	height: 35px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.page-os-warning-box-header p {
	margin: 0px;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
}

.page-os-warning-box-items {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	padding: 10px 20px;
}

.page-os-warning-box-items span {
	margin: 0px;
	color: #000;
	font-size: 14px;
	font-weight: 500;
	line-height: 35px;
}

.warning-item-icon {
	cursor: default;
}

.warning-item-icon svg {
	margin-left: 10px;
	margin-top: -5px;
}

.MuiTooltip-tooltip {
	font-size: 14px !important;
}

@media screen and (min-width: 1500px) and (max-width: 1650px) {
	.page-os-client-info {
		grid-template-columns: 50% 50%;
	}

	.page-os-warning-box {
		width: 280px;
	}

	.page-os-warning-box-items {
		padding: 10px;
	}

	.page-os-warning-box-items span {
		font-size: 13px;
	}
}

@media screen and (max-width: 1500px) {
	.page-os-client-info {
		grid-template-columns: 50% 50%;

	}

	.page-os-warning-box {
		width: 280px;
	}
}

@media screen and (max-width: 1290px) {
	.page-os-client-info {
		grid-template-columns: 100%;
	}
}

@media screen and (max-width: 1090px) {
	.page-os-details {
		width: 780px;
	}
	.page-os-warning-box {
		margin-top: 35px;
	}

	.page-os-history-info {
		grid-template-columns: 100%;
	}
}
