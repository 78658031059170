.block-filter {
	margin-bottom: 86px;
	.block-filter-header {
		width: 100%;
		margin-bottom: 5px;

		span {
			font-size: 12px;
			font-weight: bold;
		}
	}

	.block-filter-fields {
		height: 30px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		label {
			width: 210px;

			span {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
