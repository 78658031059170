.service-order-os-authorization {
	width: 600px;
	min-height: 400px;
	height: 100%;
	position: relative;
}

.device-mobile .service-order-os-authorization {
	width: 100%;
	height: 100%;
}
