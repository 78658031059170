.side-bar-content {
	background-color: #004687;
	width: calc(50% - 80px);
	height: 100%;
	position: absolute;
	z-index: 10;

	box-shadow: 5px 0px 10px #00000080;
	border-radius: 0px 35px 35px 0px;
	padding: 40px;

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;

	.logo-pratica {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;

		img {
			max-width: 70%;
			height: auto;
		}
	}
}

@media screen and (max-width: 480px) {
	.side-bar-content {
		width: 100%;
		height: 100%;
		position: relative;

		box-shadow: none;
		border-radius: 0px 0px;
		padding: 40px;

		.logo-pratica {
			img {
				width: 100%;
				max-width: 200px;
				height: auto;
			}
		}
	}
}

@media only screen and (min-width: 481px) and (max-width: 980px) and (orientation: portrait) {
	/* For portrait layouts only */
	.side-bar-content {
		width: 100%;
		height: 100%;
		position: relative;

		box-shadow: none;
		border-radius: 0px 0px;
		padding: 40px;

		.logo-pratica {
			img {
				width: 90%;
				max-width: 300px;
				height: auto;
			}
		}
	}
}

@media only screen and (min-width: 481px) and (max-width: 980px) and (orientation: landscape) {
	/* For portrait layouts only */
	.side-bar-content {
		width: 100%;
		height: 100%;
		position: relative;

		box-shadow: none;
		border-radius: 0px 0px;
		padding: 40px;

		.logo-pratica {
			width: 60%;
			img {
				max-width: 300px;
				height: auto;
			}
		}

		.login-form-options {
			height: auto;
			margin-right: 20px;
		}

		.login-form-actions {
			margin-top: 0;
			position: relative;
			width: 50%;
			bottom: 23px;
			margin: auto;
			.login-submit {
				margin: 0;
				width: 100%;
			}
		}
		.version-area-content {
			bottom: 10px;
		}
		.reset-pass-form .reset-pass-form-actions {
			margin-top: 0;
			position: relative;
			bottom: 23px;
		}
	}
}
