.equipment-header-information {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	.align-header {
		font-size: 14px;
		font-weight: 700;
		display: flex;
		align-items: center;
		gap: 10px;
	}
}

.serial-content {
	display: flex;
	font-size: 14px;
	height: 20px;
	span {
		font-size: 14px;
		font-weight: 700;
	}
}

.client-header-information {
	display: flex;
	margin-bottom: 15px;
	div {
		width: 50%;
		line-height: 14px;
		span {
			font-size: 14px;
			font-weight: 700;
		}
		:last-child {
			margin-left: 5px;
			font-weight: 300;
		}
	}
}

.device-mobile .client-header-information {
	display: flex;
	row-gap: 10px;
	margin-bottom: 0px;
	flex-direction: column;

	div {
		width: 100%;

		:last-child {
			margin-left: 0px;
		}
	}
}
