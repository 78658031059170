.device-mobile .orders {
	margin: 0px -20px;
	height: calc(100% - 42px);

	.orders-table {
		width: 100%;
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
		height: calc(100% - 100px);
	}

	.orders-list {
		display: flex;
		flex-direction: column;
		height: auto;
	}
}
