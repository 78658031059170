.order-history-list {
	height: 35px;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #e5e5e5;

	&:hover {
		background-color: #d0dff0 !important;
	}
	.order-history-list-item {
		margin: 0px 5px 0px 0px;
		padding: 0px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.order-history-list-order-status {
		width: 3%;
		display: flex;
		justify-content: center;
	}
	.order-history-list-order {
		width: 10%;
	}
	.order-history-list-order-category {
		width: 15%;
	}
	.order-history-list-order-issue-date {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-delivery-date {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-last-billing {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-salesperson {
		width: 25%;
	}
	.order-history-list-order-financial-status {
		width: 8%;
	}
	.order-history-list-order-more-options {
		width: 2%;
	}

	.order-history-list-order-empty-list {
		width: 100%;
		text-align: center !important;
	}
}

.order-history-list:nth-child(even) {
	background-color: #ffffff;
}
