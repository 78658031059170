.notification-page-container {
	width: 100% !important;
}

.notification-page-content {
	margin: 20px 0px;
}

.device-mobile .notification-page-content {
	height: calc(var(--app-height) - 150px);
	overflow-y: scroll;
}

.notification-page-header {
	width: 100%;
}
.device-mobile .notification-page-header {
	display: flex;
	align-items: center;
}

.notification-page-header h3 {
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	margin: 0 0 30px;
}
.device-mobile .notification-page-header h3 {
	margin: 0px;
	line-height: initial;
}

.header-content {
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
}

.header-content .config {
	display: block;
	margin-top: 20px;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
	line-height: 14px;
	text-decoration: underline;
	cursor: pointer;
}

.device-mobile .header-content .config {
	display: flex;
	margin-top: 0px;
	align-items: center;
}

.device-mobile .header-content .search {
	display: flex;
}

.search-field {
	width: 320px;
}

.device-mobile .search-field {
	width: initial;
}

.pagination-content {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.device-mobile .pagination-content {
	margin-top: 10px;
}

.modal.notifications-config {
	display: flex;
	justify-content: center;
	align-items: center;
}
