.transfers-tabs-content {
	width: 100%;
	height: 35px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	div {
		font-size: 16px;
		color: #222;
		font-weight: 500;
		width: 150px;
		cursor: pointer;
	}
	div.selected {
		text-decoration: underline;
		font-weight: bold;
	}
	div.disabled {
		opacity: 0.5;
		cursor: default;
	}
}
