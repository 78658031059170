.order-history-tab {
	margin: 0px -20px 0px -20px;
	height: calc(100vh - 217px);

	.order-history-table {
		overflow-y: scroll;
		height: calc(100% - 80px);

		ul {
			font-size: 13px;
			color: #333333;
			font-display: swap;
		}
	}

	.order-history-paginator {
		margin-top: 20px;
	}
}

.device-mobile .order-history-tab {
	height: calc(100vh - 256px);
	
	.order-history-table {
		height: calc(100% - 100px);
	}
}
