.device-mobile .order-history-list-mobile {
	width: 100%;
	display: flex;
	font-size: 12px;
	padding: 20px 20px;
	flex-direction: column;
	align-items: flex-start;
	background-color: #e5e5e5;

	.info-title {
		font-weight: 700;
		margin-right: 5px;
	}

	.info-value {
		font-weight: 500;
	}

	.order-history-list-item {
		margin: 0px;
		padding: 0px;
		white-space: normal;
		overflow: initial;
		text-overflow: initial;
	}

	.order-history-list-order-empty-list {
		width: 100%;
		text-align: center !important;
	}
}

.device-mobile .order-history-list-item-first-line {
	display: grid;
	column-gap: 10px;
	width: calc(100vw - 40px);
	justify-content: space-around;
	grid-template-columns: 1fr 1fr;

	img {
		width: 12px !important;
		height: 12px !important;
	}
}

.device-mobile .order-history-list-item-default {
	display: grid;
	column-gap: 10px;
	width: calc(100vw - 40px);
	grid-template-columns: 1fr;
	overflow: hidden !important;
	white-space: nowrap !important;
	text-overflow: ellipsis !important;
}

.device-mobile .order-history-list-item-second-line {
	display: grid;
	column-gap: 10px;
	width: calc(100vw - 40px);
	grid-template-columns: 1fr 1fr;
}

.order-history-list-mobile:nth-child(even) {
	background-color: #F8F9FA;
}
