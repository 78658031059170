.device-mobile .page-os-header {
	height: initial;
	display: flex;
	margin-bottom: 5px;
	align-items: center;

	#order_number {
		margin-bottom: 0px;
	}
}

.device-mobile .page-os-details {
	margin: 0px;
	display: flex;
	align-items: center;
	width: calc(100vw - 40px);
	flex-direction: column-reverse;

	.page-os-details-info {
		margin: 0px;
		width: 100%;

		.page-os-equip-name {
			margin: 0px;

			span {
				font-size: 16px;
				font-weight: 700;
			}
		}

		.page-os-equip-info {
			gap: 5px;
			margin: 0px;
			display: grid;
			margin-bottom: 5px;
			grid-template-columns: 1fr;

			.page-os-equip-info-item {
				margin: 0px;
				padding: 0px;
				font-size: 12px;
			}
		}

		.page-os-client-info {
			gap: 5px;
			margin: 0px;
			display: grid;
			margin-bottom: 5px;
			grid-template-columns: 1fr;

			.page-os-client-info-item {
				margin: 0px;
				padding: 0px;
				font-size: 12px;
			}
		}
	}

	.page-os-warnings {
		width: 100%;

		.page-os-warning-box {
			width: 100%;
			margin-top: 0px;
			margin-bottom: 10px;

			.page-os-warning-box-header {
				height: 30px;

				p {
					font-size: 13px;
					font-weight: 700;
				}
			}

			.page-os-warning-box-items {
				width: 100%;
				row-gap: 5px;

				span {
					font-size: 12px;
					font-weight: 400;
					line-height: initial;
				}
			}
		}
	}
}

.device-mobile .page-os-history {
	margin-bottom: 10px;

	.page-os-history-info {
		gap: 5px;
		margin: 0px;

		.page-os-history-info-item {
			margin: 0px;
			padding: 0px;
			font-size: 12px;
		}
	}
}

.page-os-client-info {
	.page-os-client-info-item.address {
		grid-column: 1 / -1;
		text-align: left;

		span {
			white-space: wrap;
		}
	}
}