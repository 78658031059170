.notification-area {
	position: absolute;
	top: 17.5px;
	right: 90px;
	color: #444;
	cursor: pointer;
	z-index: 35;
}

.device-mobile .notification-area {
	position: relative;
	cursor: pointer;
	top: initial;
	right: initial;
}

.notification-area svg {
	font-size: 3em;
	position: relative;
	cursor: pointer;
}

.notification-count {
	height: 8px;
	width: 8px;
	background-color: rgb(253, 51, 0);
	border-radius: 100%;
	position: absolute;
	top: 3px;
	right: 2px;
	cursor: pointer;
}
.device-mobile .notification-count {
	top: 1px;
	right: 8px;
}

.notification-count.hide {
	display: none;
}

.new-notifications-card {
	width: 285px;
	height: 68px;
	position: absolute;
	display: flex !important;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	top: 5px;
	right: 120px;
	border-radius: 3px;
	box-shadow: 3px 3px 10px 0px #0000008a;
	background-color: #004687;
	border-bottom: 5px solid #4e86d6;
	cursor: pointer;
	z-index: 101;
}

.device-mobile .new-notifications-card {
	top: 20px;
	right: 0px;
	width: 180px;
	height: 73px;
	position: fixed;
}

.new-notifications-card.hide {
	display: none !important;
}

.new-notifications-card-text {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-left: 15px;
}

.new-notifications-card-close {
	color: hsla(0, 0%, 100%, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 68px;
}

.new-notifications-card p {
	color: hsla(0, 0%, 100%, 1);
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
	margin: 0px;
}

.new-notifications-card span {
	color: hsla(0, 0%, 100%, 0.8);
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	margin: 0px;
}
