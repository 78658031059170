.device-web .container-item {
	background-color: #f9f8fa;

	details summary::-webkit-details-marker {
		display: none;
	}

	.items-list-details {
		&:focus-visible {
			outline: none;
		}

		.items-list {
			height: 50px;
			display: flex;
			align-items: center;
			font-size: 12px;

			.items-list-order-item {
				padding-left: 0px;
				margin: 0px 20px 0px 0px;

				header {
					font-size: 12px;
					font-weight: 700;
					margin: 0 0 10px 0;
				}
			}

			.items-list-order-options {
				width: 35px;
				min-width: 35px;
				align-items: center;
				margin-left: 5px;

				img {
					cursor: pointer;
				}
			}

			.arrow-icon {
				transform: rotate(0deg);
				width: 60px;
			}

			.items-list-order-product-code {
				min-width: 65px;
				width: 65px;

				@media screen and (min-width: 1398px) {
					width: 10%;
				}

				.order-product-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 40px;

					span {
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}

					div {
						width: 30px;

						svg {
							cursor: pointer;
							width: 25px;
						}
					}
				}
			}

			.items-list-order-description {
				min-width: 400px;
				width: 400px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				@media screen and (min-width: 1398px) {
					width: calc(55% - 100px);
				}
			}

			.items-list-order-accessories {
				width: 100px;
				min-width: 100px;

				.container-accessories {
					display: flex;
					gap: 5px;
				}

				header {
					font-size: 12px;
					font-weight: 700;
					margin: 0 0 10px 0;
				}

				span {
					.MuiSvgIcon-root {
						color: #5daeff;
						cursor: pointer;
						margin-left: 5px;
						margin-right: 5px;
					}
				}
			}

			.items-list-order-serial-number {
				min-width: 190px;
				width: 190px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				@media screen and (min-width: 1398px) {
					width: 20%;
				}
			}

			.items-list-order-amount {
				width: 90px;
				min-width: 90px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-table-price {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-unit-price {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-discount {
				width: 100px;
				min-width: 100px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-ipi {
				width: 90px;
				min-width: 90px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-tax-substitution {
				width: 75px;
				min-width: 75px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-operation-type {
				min-width: 185px;
				width: 185px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				@media screen and (min-width: 1398px) {
					width: 20%;
				}
			}

			.items-list-order-storage {
				min-width: 190px;
				width: 190px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				@media screen and (min-width: 1398px) {
					width: 25%;
				}
			}

			.items-list-order-difal {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-st {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-suframa {
				width: 100px;
				min-width: 100px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-delivery-date {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-reprogrammed {
				width: 120px;
				min-width: 120px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-total-net-price {
				width: 100px;
				min-width: 100px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-total-value {
				width: 150px;
				min-width: 150px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-invoice-order-item {
				width: 150px;
				min-width: 150px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				img {
					cursor: pointer;
					width: 32px;
					height: 32px;
				}

				@media screen and (min-width: 1398px) {
					width: 20%;
				}

				header {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.items-list-order-purchase-order-item {
				width: 145px;
				min-width: 145px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				@media screen and (min-width: 1398px) {
					width: 20%;
				}

				header {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.items-list-order-purchase-order-number {
				width: 145px;
				min-width: 145px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				@media screen and (min-width: 1398px) {
					width: 25%;
				}
			}

			.items-list-order-embedded-freight {
				width: 90px;
				min-width: 90px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}

				// @media screen and (min-width: 1398px) {
				// 	width: 20%;
				// }
			}

			.items-list-order-installation-value {
				width: 105px;
				min-width: 105px;

				span {
					display: flex;
					align-items: center;
					height: 40px;
				}
			}

			.items-list-order-more-options {
				width: 20px;
				min-width: 20px;
			}

			.items-list-order-empty-list {
				width: 1px;
				text-align: center !important;
			}

			&:last-child {
				margin-left: 60px;
				margin-right: 60px;
				height: 80px;
			}

			&:nth-child(even) {
				margin-left: 60px;
				margin-right: 60px;
				height: 80px;
			}
		}
	}

	&:nth-child(even) {
		background-color: #e5e5e5;
	}

	&:hover {
		background-color: #d0dff0 !important;
	}
}

details[open] .arrow-icon {
	transform: rotate(180deg) !important;
}
