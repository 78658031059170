.device-web .edit-preventive-contract-contain {
	width: 760px;
	height: 510px;
	position: relative;
}

.show {
	display: inline;
}

.hide {
	display: none;
}

.tab-item {
	padding: 9px 30px 9px 0px;
	font-weight: 500;
	color: #333333;
}

.tab-item.selected {
	font-weight: bold;
	text-decoration: underline;
}

.client-content {
	padding: 15px 0 0px;
	width: 100%;
}

.device-mobile .cols-2 {
	display: flex;
	justify-content: flex-start;
	align-self: flex-start;
	flex-direction: column !important;
	padding-right: 20px;
	margin-bottom: 10px;
}

.cols-2,
.cols-3 {
	display: flex;
	justify-content: flex-start;
	align-self: flex-start;
	flex-direction: row;
	padding-right: 20px;
	margin-bottom: 10px;
}

.client-content .cols-2 div {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.client-content .cols-3 div {
	width: calc(33.333333333% - 20px);
}

.client-content-item {
	margin-bottom: 10px;
}

.client-content-item label {
	font-weight: 600;
}

.client-content-item span {
	font-size: 15px;
	font-weight: 300;
}

.remove-contract {
	margin-top: 15px;
}

.remove-contract span {
	text-decoration: underline;
	cursor: pointer;
}

.contract-change-date {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	margin-top: 20px;
}

.contract-change-date label {
	margin-bottom: 10px;
}

.device-mobile .edit-preventive-contract-contain .client-content-footer {
	bottom: 70px;
	position: fixed;
	justify-content: center;
	width: calc(100vw - 40px);
}

.client-content-footer {
	display: flex;
	position: absolute;
	justify-content: center;
	gap: 20px;
	align-items: flex-end;
	flex-direction: row;
	width: 100%;
	bottom: 0px;
}

.modal.loading {
	height: 100vh;
	width: 100vw;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.success-message div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-content {
	border-radius: 3px;
	position: absolute;
	padding: 0px 40px 40px 40px;
	min-width: 35%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
}

.name-location {
	margin-bottom: 20px;
	header {
		line-height: 14px;
		font-size: 12px;
		margin-bottom: 10px;
		justify-content: flex-start;
		font-weight: 600;
	}
}
