.orders {
	height: calc(100dvh - 80px);
	margin: 0px -20px;
	.orders-table {
		display: flex;
		overflow-y: auto;
		flex-direction: column;
		width: calc(100% + 40px);
		height: calc(100% - 132px);

		ul {
			font-size: 13px;
			text-align: left;
			color: #333333;
			font-display: swap;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.orders-paginator {
		margin-top: 20px;
	}
}
