.new-accessories-body-list {
	height: 400px;
	margin: 0 -20px;
	overflow: overlay;

	.new-accessories-body-item {
		height: 50px;
		display: flex;
		align-items: center;
		background-color: #f9f8fa;

		.accessories-details-list-item {
			margin: 0px;
			padding: 0px;
			display: flex;
			padding-right: 10px;

			&:last-child {
				padding-right: 0;
			}

			span {
				font-weight: 400;
				font-size: 13px;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.accessories-details-list {
			padding: 0;
			width: 100%;
			display: flex;
			margin: 0 20px 0;
			align-items: center;

			.accessories-details-list-code {
				width: 9%;
				gap: 10px;
				align-items: center;
			}

			.accessories-details-list-description {
				width: 34%;

				label {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
	
			.accessories-details-list-amount {
				width: 10%;
			}
	
			.accessories-details-list-commission {
				width: 11%;
			}
	
			.accessories-details-list-unit-price {
				width: 12%;
			}
	
			.accessories-details-list-table-price {
				width: 12%;
			}
	
			.accessories-details-list-total-price {
				width: 12%;
			}
		}

		&:nth-child(odd) {
			background-color: #e5e5e5;
		}
	}
}

.new-accessories-body-action {
	bottom: 0;
	display: grid;
	gap: 20px;
	grid-template-columns: 118px 118px;
	justify-content: center;
	position: absolute;
	width: 100%;
}