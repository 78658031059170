.service-order-change-address {
	width: 760px;
	height: 100%;
	position: relative;
}

.device-mobile .service-order-change-address {
	width: 100%;
	height: 100%;

	.service-order-change-address-main-contain {
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100% - 60px);
	}

	.divider-component {
		margin-top: 10px;
	}
}

.address-form-header {
	text-align: left;
}

.service-order-change-address-action {
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;
}

.address-form-body {
	display: flex;
	margin-top: 15px;
}
