.update-notification-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    backdrop-filter: blur(5px);
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
    visibility: visible;

    .update-notification-content {
        background: #f8f9fa;
        color: #004687;
        z-index: 1000;
        margin: 150px auto;
        border-radius: 10px;
        height: 400px;
        width: 350px;
        text-align: center;
        box-shadow: 0px 0px 6px #3333334d;

        .update-notification-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
                margin: 20px auto;
                font-size: 21px;
            }

            p {
                padding: 0 20px;
                font-size: 14px;
                color: black;
            }

            button {
                margin-bottom: 5px;
                width: 120px;
            }

            .update-notification-click {
                cursor: pointer;
                text-decoration: underline;
                font-weight: 500;
                color: #004687;
            }

            .update-notification-action {
                margin-top: 30px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 20px;
            }
        }
    }
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 20px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 20px;
        opacity: 1;
    }
}

@media only screen and (max-device-width: 480px) {
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            top: 15px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            top: 0;
            opacity: 0;
        }

        to {
            top: 15px;
            opacity: 1;
        }
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: portrait) {
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            top: 20px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            top: 0;
            opacity: 0;
        }

        to {
            top: 20px;
            opacity: 1;
        }
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            top: 20px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            top: 0;
            opacity: 0;
        }

        to {
            top: 20px;
            opacity: 1;
        }
    }
}
