.input-install-and-search {
  display: block;
  width: 100%;
  border: none;
  border-radius: 1px;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  color: #6c757d;
  padding-left: 10px;
  background-color: #EDEEF0;
  outline: none;

  &:-webkit-autofill {
    -webkit-text-fill-color: #004687 !important;
    -webkit-box-shadow: none;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #004687 !important;
    -webkit-box-shadow: none;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  &:-webkit-autofill:hover {
    -webkit-text-fill-color: #004687 !important;
    -webkit-box-shadow: none;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
}

.icon {
  border-radius: 4px 0 0 4px;
  padding: 21px 20px;

  ::placeholder {
    color: #c7c7c7;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px white inset;
}