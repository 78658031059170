.items-information {
	display: flex;
	margin: 0px -20px;
	overflow-y: overlay;
	overflow-x: overlay;
	flex-direction: column;

	.items-information-contain:first-child {
		margin: 0px -20px 10px;
		position: relative;
	}

	.items-information-contain:last-child {
		align-items: center;

		.items-information-contain-information:first-child {
			margin: 0 auto 0 0;
		}
	}

	.items-information-contain-action {
		z-index: 3;
		right: 20px;
		margin: 0px;
		padding: 0px;
		display: flex;
		position: absolute;
		left: 20px;
		justify-content: space-between;

		.items-information-contain-filter {
			display: flex;
			align-items: center;

			label {
				margin-right: 5px;
				margin-bottom: 0;
			}

			span {
				width: calc(100% - 51px);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		button {
			width: 52px;
		}
	}
}

.items-information-contain {
	display: flex;

	.items-table {
		max-height: 300px;

		@media screen and (min-width: 1398px) {
			width: 100%;
		}

		.items-body-container {
			position: relative;
			max-height: 265px;
			overflow: overlay;
			min-height: 165px;
			overflow-x: hidden;
		}
	}

	.items-information-contain-information {
		display: flex;
		overflow: hidden;
		margin-right: 30px;
		flex-direction: row;
		white-space: nowrap;
		text-overflow: ellipsis;

		header {
			width: auto;
			font-size: 12px;
			font-weight: 600;
			overflow: hidden;
			margin-right: 10px;
			white-space: nowrap;
			text-overflow: ellipsis;
			justify-content: flex-start;
		}

		span {
			width: auto;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.items-information-contain-information:last-child {
		margin-left: 0px;
	}
}

.device-mobile .items-information {
	overflow-y: overlay;
	overflow-x: overlay;
	margin: -20px -20px 0px;

	.items-information-contain:first-child {
		margin: 0;
	}

	.items-information-contain {
		display: flex;
		flex-direction: row;

		.items-table {
			width: 100%;
			height: 380px;
			max-height: 380px;

			.items-body-container {
				height: 370px;
				max-height: 370px;
				overflow: overlay;
			}
		}
	}

	.items-information-contain-information {
		display: flex;
		overflow: initial;
		margin-right: 0px;
		white-space: initial;
		text-overflow: initial;

		flex-direction: column;

		header {
			width: auto;
			font-size: 12px;
			font-weight: 600;
			overflow: hidden;
			margin-right: 10px;
			white-space: nowrap;
			text-overflow: ellipsis;
			justify-content: flex-start;
		}

		span {
			font-size: 12px;
			overflow: initial;
			white-space: initial;
			text-overflow: initial;
		}
	}
}

.new-order-select-item-action {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;

	label {
		padding: 0px;
		cursor: pointer;
		margin-bottom: 0px;
	}
}
