.employee-modal {
	width: 660px;
	margin-bottom: 60px;

	.employee-form {
		row-gap: 20px;
		display: flex;
		flex-direction: column;

		.employee-form-rows {
			display: flex;
			column-gap: 20px;

			.form-field-info {
				width: 100%;

				.search-action {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					svg {
						cursor: pointer;
						fill: var(--primary-pure) !important;
					}
				}
			}
		}
	}
}

.employee-modal-actions {
	left: 50%;
	bottom: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	transform: translateX(-50%);

	button {
		width: 120px;

		&:last-child {
			margin-left: 20px;
		}
	}
}

.device-mobile {
	.employee-modal {
		width: 100%;
		overflow-y: auto;

		.employee-form {
			.employee-form-rows {
				row-gap: 20px;
				flex-direction: column;

				&:first-child {
					flex-direction: row;
				}
			}
		}
	}
}