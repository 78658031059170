.orders-list {
	display: flex;
	flex-direction: column;
	height: 100%;

	.orders-list-row {
		width: 100%;
		height: 35px;
		min-height: 35px;
		display: flex;
		background-color: #e5e5e5;
		&:hover {
			background-color: #d0dff0 !important;
		}
	}
	.orders-list-row:nth-child(even) {
		background-color: #f8f9fa;
	}

	.orders-list-item {
		margin: 0px 10px 0px 0px;
		padding: 0px;
		display: flex;
		align-items: center;
	}

	.orders-list-legend {
		width: 3%;
		display: flex;
		justify-content: center;
	}
	.orders-list-orders {
		width: 6%;
	}
	.orders-list-company-name {
		width: 23%;
	}
	.orders-list-category {
		width: 20%;
	}
	.orders-list-state {
		width: 3%;
	}
	.orders-list-issue-date {
		width: 6%;
	}
	.orders-list-delivery-date {
		width: 6%;
	}
	.orders-list-salesperson {
		width: 15%;
	}
	.orders-list-status {
		width: 8%;
	}
	.orders-list-follow-up {
		width: 9%;
	}
	.orders-list-more-options {
		width: 2%;
	}
	.orders-list-empty-list {
		width: 100%;
		text-align: center;
	}
}
