.recognition-card-title {
	display: flex;
	justify-content: space-between;

	h4 {
		font-size: 20px;
		width: 80%;
		color: #004687;
	}
	.recognition-card-title-icon {
		width: 50px;
		height: 50px;
	}
}

.recognition-card-main-contain {
	height: 100%;
}

.recognition-card-actions {
	display: flex;

	button {
		width: 245px;
		font-size: 12px;
		text-transform: capitalize;
	}
	button:first-child {
		margin-right: 20px;
	}
}
