.timeline-service {
	display: flex;
	height: auto;
	min-height: 130px;
	border: none;

	@media screen and (max-width: 479px) {
		position: relative;
		margin-bottom: 0px;
	}

	.timeline-service-template {
		margin-bottom: 40px;
		margin-left: 10px;

		.template-title {
			margin-top: 2px;
			margin-bottom: 1px;
			font-size: 16px;
			font-weight: 700;
			text-align: left;
			text-transform: none;
		}

		.template-body {
			padding-bottom: 50px;
			color: rgba(51, 51, 51, 0.8);
			text-align: left;
		}
	}
}

.device-mobile .timeline-service {
	display: flex;
	height: auto;
	min-height: 130px;
	border: none;

	.timeline-service-template {
		margin-left: 10px;
		margin-bottom: 40px;

		.template-title {
			font-size: 12px;
			margin-top: 2px;
			margin-bottom: 0px;
		}

		.template-date-of-creation {
			font-size: 12px;
			div {
				font-size: 12px;
			}
		}

		.template-body {
			font-size: 12px;
			padding-bottom: 20px;
		}
	}
}
