.device-mobile .schedule-field {
	margin-top: 5px;
	button {
		width: auto;
	}
}

.device-mobile .contracts-table {
	height: calc(100vh - 272px);

	.table {
		height: calc(100vh - 360px);
		margin-bottom: 10px;

		.table-list {
			height: 100%;

			.schedules-table-item-client,
			.schedules-table-item-equip,
			.schedules-table-item-serial,
			.schedules-table-item-prev,
			.schedules-table-item-location,
			.contracts-table-item-equip,
			.contracts-table-item-end-contract,
			.contracts-table-item-cnpj,
			.contracts-table-item-razao {
				width: 100%;
			}

			.table-content {
				border: none;
			}

			.table-item {
				border: 1px solid rgba(0, 0, 0, 0.2);
			}
		}
	}

	.MuiPagination-ul {
		flex-wrap: nowrap !important;
		transform: scale(0.8) !important;
	}
}

.contractContent {
	.search-light {
		width: 400px;
	}
}
.device-mobile .contractContent {
	.search-light {
		width: 100%;
	}
}
