.device-mobile .all-balance-storage-list-mobile {
	display: flex;
	height: auto;
	flex-direction: column;
	align-items: stretch;
	padding: 20px;

	div {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
	.storage-list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.all-balance-storage-list-item {
		margin: 0px 10px 0px 0px;
		padding: 0px;
		width: 100%;

		span {
			font-weight: bold;
			margin-right: 5px;
		}

		label {
			margin: 0;
		}
	}

	.all-balance-storage-list-ul {
		display: none;
	}

	.all-balance-storage-list-product {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.all-balance-storage-list-description {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.all-balance-storage-list-empty-list {
		width: 100%;
		text-align: center !important;
	}
	&:hover {
		background-color: #d0dff0 !important;
	}
}

.all-balance-storage-list-mobile:nth-child(even) {
	background-color: #e5e5e5;
}
