.modal-not-found-numserie {
  width: 700px;

  .title-modal-no-numserie {
    font-weight: 300;
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .container-address-confirm-actions {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;

    button {
      width: 190px;
      border-radius: 1px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  .modal-not-found-numserie {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: 651px) and (max-width: 800px) {
  .title-modal-no-numserie {
    font-size: 23px;
    padding: 20px 30px;

    div {
      font-size: 19px;
      max-width: 534px;
    }
  }

}

@media only screen and (min-width: 651px) and (max-width: 800px) {
  .title-modal-no-numserie {
    font-size: 23px;
    padding: 20px 30px;

    div {
      font-size: 19px;
      max-width: 534px;
    }
  }
}

@media only screen and (min-width: 801px) {
  .title-modal-no-numserie {
    font-size: 30px;
    line-height: 28px;

    div {
      font-size: 20px;
      max-width: 534px;
    }
  }
}