.new-service-order {
	width: 800px;
	height: 480px;
	position: relative;
	.new-service-order-main-contain {
		height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;

		@media screen and (max-height: 768px) {
			height: 480px;
		}

		.new-service-order-form-field-title {
			font-size: 12px;
			font-weight: 700;
			align-items: center;
		}

		.new-order-contain-header-request {
			.header-request-equipment {
				.header-request-equipment-description {
					display: grid;
					column-gap: 40px;
					grid-template-columns: 2fr 1fr;

					.equipment-description-name {
						display: flex;
						align-items: center;
						gap: 10px;
						font-size: 14px;
						font-weight: 700;
					}

					.equipment-family-product-field-error {
						background-color: #ffe5e5;
						border: solid 1px #c21618;
						.ProductFamily {
							padding: 0px 0px 0px 12px;
						}
					}

					.equipment-family-product-field {
						height: 30px;
						display: flex;
						border-radius: 6px;
						position: relative;
						flex-direction: column;
						font-size: 12px !important;

						.MuiInput-underline.Mui-disabled:before {
							border: none !important;
						}

						.ProductFamily {
							height: 100%;
							font-size: 12px !important;
							justify-content: center;
							padding: 0px;
							outline: none !important;
							.MuiInput-underline:after {
								border: none !important;
							}

							.MuiNativeSelect-select:focus {
								background-color: transparent !important;
							}
							.MuiInput-underline:before {
								content: none;
								border: none;
							}
							.MuiNativeSelect-root {
								height: 100%;
								padding: 0px;
								font-size: 12px;
								line-height: initial;
							}
							.MuiNativeSelect-root ::before {
								content: none;
								border: none;
							}
						}
					}
				}
				.equipment-complement-information {
					width: 100%;
					row-gap: 10px;
					display: grid;
					margin-top: 10px;
					column-gap: 20px;
					grid-template-columns: 1fr 1fr 1fr;

					div {
						display: flex;
						column-gap: 5px;

						.complement-information-value {
							font-size: 12px;
							font-weight: 400;
							align-items: center;
						}
					}
				}
			}

			.new-service-order-alerts {
				display: grid;
				font-size: 12px;
				column-gap: 5px;
				margin-top: 10px;
				color: #c21618;
				padding: 8px 20px;
				border-radius: 6px;
				background-color: #ffe5e5;
				grid-template-columns: auto 1fr 1fr 1fr;
			}

			.new-service-order-alerts span {
				font-weight: 700;
			}
		}

		.new-service-order-contact-form {
			display: grid;
			gap: 20px;
			grid-template-columns: 1fr 1fr 1fr;

			.new-service-order-form-field {
				font-size: 12px;
				font-weight: 700;
				position: relative;
			}
		}

		.new-service-order-visit-information {
			position: relative;

			.visit-date-field {
				display: flex;
				position: relative;
				flex-direction: column;

				.MuiFormControl-root {
					width: 100%;
					height: 40px;
					padding: 10px;
					background-color: #f2f2f2;
					.MuiInputBase-input {
						padding: 0px;
						outline: none;
					}
					.MuiInput-underline:after,
					.MuiInput-underline:before {
						border: none;
					}
				}
			}

			.new-service-order-visit-address-information {
				padding: 8px 20px;
				border-radius: 6px;

				.new-address-form {
					row-gap: 20px;
					display: flex;
					margin: 20px 0px;
					flex-direction: column;

					.new-address-form-first-line {
						gap: 20px;
						display: grid;
						grid-template-columns: 1fr 1fr 0.5fr 1fr;
					}

					.new-address-form-last-line {
						gap: 20px;
						display: grid;
						grid-template-columns: 2fr 0.3fr 0.5fr;
					}
				}
			}

			.new-service-order-visit-address-information-error {
				background-color: #ffe5e5;
			}

			.new-service-order-visit-technician-information {
				display: grid;
				margin-top: 20px;
				column-gap: 20px;
				align-items: end;
				grid-template-columns: 2fr 3fr;

				.new-service-order-select-technician-action {
					display: flex;
					cursor: pointer;
					align-items: center;
					label {
						padding: 0px;
						cursor: pointer;
						margin-bottom: 0px;
					}
				}

				.new-service-order-select-technician {
					width: 100%;
					height: 100%;
					display: flex;
					padding: 10px;
					position: relative;
					border-radius: 3px;
					border: solid 1px #025cb7;

					.selected-technician-information {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.selected-technician-information-name {
							font-size: 13px;
							font-weight: 700;
						}

						.selected-technician-information-contact {
							display: grid;
							column-gap: 20px;
							font-size: 12px !important;
							font-weight: 400 !important;
							grid-template-columns: 0.6fr 1fr 1fr;
						}
					}
				}
			}
		}

		.new-service-order-complement-details {
			gap: 20px;
			.complement-details {
				width: 100%;
				display: grid;
				gap: 20px;
				grid-template-columns: 1fr 1fr;

				.complement-details-type-defect {
					display: flex;
					position: relative;
					flex-direction: column;
					.form-area {
						margin: 0px;
					}
				}
				.complement-details-defect-error,
				.complement-details-type-error {
					input,
					button {
						background-color: #ffe5e5 !important ;
					}
				}
			}

			.complement-details-observation-field {
				display: flex;
				margin-top: 20px;
				flex-direction: column;

				textarea {
					width: 100%;
					height: 80px;
					border: none;
					padding: 10px;
					color: #333333;
					font-size: 12px;
					border-radius: 6px;
					background: #f2f2f2;

					&:focus {
						outline: none;
					}
				}
			}
		}

		.divider-component {
			margin-block: 15px;
		}
	}
}

.new-service-order-footer-actions {
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;

	button {
		width: 126px !important;
	}
}

.device-mobile .new-service-order {
	width: calc(100vw - 40px);
	height: 100%;

	.new-order-contain-header-request {
		.header-request-equipment {
			.header-request-equipment-description {
				grid-template-columns: 1fr;
			}
			.equipment-complement-information {
				row-gap: 0px;
				grid-template-columns: 1fr;
			}
		}
		.new-service-order-alerts {
			grid-template-columns: 1fr;
		}
	}

	.new-service-order-main-contain {
		height: calc(100% - 60px);
		.complement-details,
		.new-service-order-contact-form {
			display: flex;
			flex-direction: column;
		}

		.new-service-order-visit-information {
			.new-service-order-visit-address-information {
				padding: 0px;
				.new-address-form {
					.new-address-form-first-line {
						grid-template-columns: 1fr;
					}
					.new-address-form-last-line {
						grid-template-columns: 3fr 1fr;
					}
				}
			}

			.new-service-order-visit-technician-information {
				row-gap: 20px;
				grid-template-columns: 1fr;

				.new-service-order-select-technician {
					flex-direction: column-reverse;

					.selected-technician-information {
						.selected-technician-information-contact {
							grid-template-columns: 1fr;
						}
						.selected-technician-information-name {
							max-width: calc(100% - 87px);
						}
					}
					.change-selected-technician-button {
						top: 10px;
						right: 10px;
						position: absolute;
					}
				}
			}
		}
	}
}
