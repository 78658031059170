.all-balance-storage-tab {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 20px;
	margin: 0px -20px;
	max-height: calc(100% - 74px);
	overflow: auto;

	.all-balance-storage-table {
		width: calc(100% + 40px);
		overflow-x: auto;
		margin: 0px -20px;

		ul {
			font-size: 13px;
			text-align: left;
			color: #333333;
			font-display: swap;
		}
	}
}

@media screen and (max-width: 1200px) {
	.all-balance-storage-table {
		width: calc(100% + 40px);
		overflow-x: scroll;
	}
}
