.transfer-list-content {
	margin-top: 5px;

	.transfer-list-header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		height: 30px;
		text-align: left;
		padding: 5px 10px;
		border-style: solid;
		border-width: 1px;
		border-color: rgba(0, 0, 0, 0.2);
		border-radius: 3px 3px 0px 0px;
		background-color: #f8f9fa;

		.list-header {
			position: relative;
			float: left;
			min-height: 1px;
			padding: 0px 10px;

			span {
				line-height: 20px;
				font-size: 13px;
				text-align: left;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.has-error-content,
	.empty-main-content,
	.loading-main-content {
		height: calc(100% - 160px);
		top: 160px;
		background-color: #fff;
	}
}

.transfer-list-content {
	.transfer-list-header .date-solicitation,
	.transfer-list-header .date-transfer,
	.transfer-list-header .serial,
	.transfer-list-header .origin,
	.transfer-list-header .status,
	.transfer-list-header .user {
		width: calc((50% / 6));
	}

	.transfer-list-header .client-name,
	.transfer-list-header .equip-name {
		width: calc(25%);
	}

	.transfer-list-header .options {
		width: 30px;
	}
}

.transfer-list-item {
	.transfer-info .date-solicitation,
	.transfer-info .date-transfer,
	.transfer-info .serial,
	.transfer-info .origin,
	.transfer-info .status,
	.transfer-info .user {
		width: calc((50% / 6));
	}

	.transfer-info .client-name,
	.transfer-info .equip-name {
		width: calc(25%);
	}

	.transfer-info-options {
		width: 30px;
	}
}
