.new-accessories-container {
	width: 960px;
	height: 570px;
	position: relative;

	.accessories-search-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;

		.order-item-name {
			margin: 0 0 20px 0;

			span {
				font-weight: bold;
				font-size: 12px;
			}

			.order-item-name-value {
				margin: 0;
				opacity: 1;
				font-size: 12px;
				font-weight: 400;
				text-align: left;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}

.device-mobile .new-accessories-container {
	width: 100%;
	max-height: 100%;
	height: 100%;

	.accessories-search-container {
		display: grid;
		grid-template-columns: 100%;
		gap: 10px;

		.order-item-name {
			height: 40px;
			margin: 0 0 10px 0;

			span {
				font-weight: bold;
				font-size: 12px;
			}

			.order-item-name-value {
				margin: 0;
				opacity: 1;
				font-size: 12px;
				font-weight: 400;
				text-align: left;
				color: #333333;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}