.order-checklist {
	width: 100%;
	
	.order-checklist-header {
		width: 100%;
		display: flex;
		justify-content: space-between;

		button {
			width: 85px;
			font-size: 17px;
			font-weight: 400;
			align-items: right;
			white-space: nowrap;
		}

		label {
			margin-bottom: 1%;
			font-weight: 200;
		}
	}

	.order-checklist-dropdown {
		display: flex;
		width: 100%;
		margin-top: 5px;
		margin-bottom: 20px;
		gap: 10px;

		.order-checklist-dropdown-size {
			width: 200px;
		}

		.order-checklist-dropdown-payment-form {
			font-weight: bold;
		}

		label {
			font-weight: 200;
			width: 300px;
		}
	}

	.order-checklist-checkbox {
		display: grid;
		grid-template-columns: auto auto;
		height: 52vh;
		overflow: hidden;
		
		.order-checklist-checkbox-position {
			display: flex !important;
			align-items: center;
			margin-bottom: 1.5%;
	
			.order-checklist-edit-on {
				color: #333333;
			}
			.order-checklist-edit-off {
				color: rgb(154, 152, 152);
			}

			label {
				margin-left: 5px;
				text-align: center !important;
				font-weight: 200;
				margin-bottom: 0px;
			}
		}
	}

	.order-checklist-solicitation {
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			width: 170px;
			height: 35px !important;
			white-space: nowrap;
			font-size: 13px;
		}
	}
}

@media only screen and (max-width: 400px) {
	.order-checklist {
		font-size: 12px !important;

		.order-checklist-header {
			width: 100%;
			display: block;
			justify-content: space-between;
	
			button {
				width: 50px;
				height: 10px;
			}

			.order-checklist-dropdown-payment-form {
				font-weight: 700;
				margin-right: 5px;
			}
		}

		.order-checklist-dropdown {
			width: 100%;
			justify-content: space-between;

			.order-checklist-button {
				width: 70px;
				font-size: 15px;					
				font-weight: 400;
				align-items: right;
				white-space: nowrap;
			}	
			
			.order-checklist-dropdown-size {
				width: 190px;
			}

			.order-checklist-dropdown-payment-form {
				display: block;
			}
		}
		.order-checklist-checkbox {
			grid-template-columns: auto;
			overflow-y: auto;
			height: 270px;
			white-space: nowrap;

			.order-checklist-checkbox-position {
				margin-bottom: 20px;
			}
		}

		.order-checklist-solicitation {
			padding-top: 20px;
		}
	}
}

@media only screen and (max-width: 300px) {
	.order-checklist {
		.order-checklist-checkbox {
			height: 230px;
		}
	}
}