.main-mobile-menu-options {
	position: absolute;
	width: 100vw;
	height: calc(var(--app-height) - 48px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	padding: 45px 18px 25px;
	z-index: 50;

	.mobile-menu-logo {
		height: auto;
		display: flex;
		justify-content: center;
		width: calc(100vw - 40px);

		img {
			width: 350px;
		}
	}

	.menu-option-list {
		height: auto;

		.menu-option-item {
			width: 100%;
			height: 45px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-direction: row;

			img {
				margin-right: 15px;
			}
		}

		.menu-option-item.logout {
			margin-top: 35px;
		}
	}
}
