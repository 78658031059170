.order-follow-up-tab-attachments-guide {
	height: 100%;
	position: relative;
	padding-bottom: 20px;
	margin: 0 -20px;

	.order-follow-up-tab-attachments-guide-action {
		display: flex;
		justify-content: space-between;
		padding: 20px 20px 10px 20px;

		button {
			width: 163px;
		}

		.order-follow-up-tab-attachments-guide-search {
			width: 385px;
			height: 40px;
		}
	}

	.order-follow-up-tab-attachments-table-results {
		height: calc(100% - 250px);
		position: relative;
	}

	.generic-table-body-row,
	.generic-table-header {
		padding-right: 10px;
	}

	.moreOptions {
		padding: 0px;
		margin-right: 0px;

		.attachment-more-option {
			width: 10px;
			position: relative;
			bottom: 0px;
		}
	}
}

.order-follow-up-import-attachment {
	width: 800px;

	.order-follow-up-import-attachment-table {
		margin: 0 -20px;

		.generic-table-body {
			overflow-y: auto;
			max-height: 460px;

			.MuiSvgIcon-root {
				color: #5daeff;
				cursor: pointer;
			}
		}
	}
}

.order-follow-up-import-attachment-action {
	margin-top: 20px;
	display: flex;
	gap: 20px;
	justify-content: center;

	button {
		width: 165px;
	}
}

.device-mobile .order-follow-up-tab-attachments-guide {
	height: calc(100% - 74px);

	.order-follow-up-tab-attachments-guide-action {
		button {
			width: 125px;
			font-size: 12px;
		}
		.order-follow-up-tab-attachments-guide-search {
			width: 195px;
		}
	}

	.order-follow-up-import-attachment {
		width: 327px;

		.order-follow-up-import-attachment-table {
			width: 375px;
			height: 420px;
		}
	}

	.order-follow-up-tab-attachments-table-results {
		height: 352px;
	}
}