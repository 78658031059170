.has-error-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;

	.has-error-main {
		width: 750px;
		max-width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-direction: row;

		img {
			max-width: calc(50% - 40px);
		}

		.has-error-message {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			text-align: left;

			h4 {
				margin-bottom: 20px;
				font-size: 20px;
				font-weight: 500;
				opacity: 0.6;
				text-transform: uppercase;
			}

			span {
				font-size: 15px;
				margin-bottom: 15px;
				opacity: 0.6;
			}

			.refresh-button {
				width: auto;
				height: auto;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 25px;
				border: 1px solid;
				cursor: pointer;
				text-transform: uppercase;
			}
		}
	}

	@media screen and (max-width: 900px) {
		.has-error-main {
			width: 600px;
		}
	}

	@media screen and (max-width: 750px) {
		.has-error-main {
			width: 550px;
		}
	}

	@media screen and (max-width: 650px) {
		.has-error-main {
			height: auto;
			justify-content: center;
			flex-direction: column;

			img {
				max-width: calc(55% - 40px);
				height: auto;
				margin-bottom: 30px;
			}
		}
	}
}
