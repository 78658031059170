.all-pendency-list-body {
	width: 100%;
	height: 60px;
	display: flex;
	min-width: 1070px;
	align-items: center;
	background-color: #ffffff;

	&:hover {
		cursor: pointer;
		background-color: #d0dff0 !important;
		.all-pendency-list-item-options {
			.options {
				display: flex;
			}
		}
	}

	.all-pendency-list-item {
		padding: 0px;
		column-gap: 5px;
		margin: 0px 20px 0px 0px;

		span {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.all-pendency-list-pendency {
		width: 30%;
		height: 100%;
		margin-left: 20px;
		align-items: center;
		min-width: 206px;

		label {
			height: 100%;
			margin: 0;
			display: flex;
			align-items: center;
			gap: 5px;
			span {
				font-size: 12px;
				font-weight: 400;
				text-transform: lowercase;
				&::first-letter {
					text-transform: capitalize;
				}
			}
		}
	}
	.all-pendency-list-inclusion-date {
		width: 102px;
		min-width: 102px;
	}
	.all-pendency-list-origin {
		width: 180px;
		min-width: 120px;
	}
	.all-pendency-list-pendency-type {
		width: 180px;
		min-width: 120px;
		display: flex;
	}
	.all-pendency-list-resp-solution {
		width: 180px;
		min-width: 120px;
		display: flex;
	}
	.all-pendency-list-expected-date {
		width: 180px;
		min-width: 110px;

		.MuiCheckbox-root {
			margin-bottom: 0px !important;
		}
		.MuiIconButton-label {
			margin-bottom: 0px !important;
		}

		svg {
			color: #025cb7;
			width: 1em !important;
			height: 1em !important;
		}

		&:focus {
			outline: none;
		}
		&:hover {
			background-color: transparent;
		}

		.MuiFormControl-root.MuiTextField-root {
			.MuiInputBase-input {
				padding-left: 10px;
				color: #a6a6a6;
			}

			.date-picker-custom {
				padding-left: 10px;
			}
		}

		.MuiFormLabel-root {
			transform: translate(0, 1.5px) scale(1);
			font-weight: 400;
			font-size: 12px;
			opacity: 1;
		}

		.MuiInputBase-root {
			height: 40px;
			font-size: 12px;
			padding-right: 5px;
			border-radius: 6px;
			background-color: #f2f2f2;
		}

		.MuiInput-underline:hover,
		.MuiInput-underline:hover:not(.Mui-disabled):before,
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
		.MuiInput-underline::before,
		.MuiInput-underline::after {
			border: none;
			outline: none !important;
			content: '' !important;
			box-shadow: none !important;
		}

		.MuiIconButton-root {
			padding: 8px;
		}

		.MuiIconButton-root:focus {
			outline: none;
		}

		.MuiFormHelperText-root.Mui-error {
			display: none;
		}
	}

	.all-pendency-list-status {
		width: 110px;
		min-width: 80px;
	}

	.all-pendency-list-item-actions {
		margin: 0px 5px 0px;
		width: 35px;
		padding: 0px;
		img {
			cursor: pointer;
			height: 40px;
			width: 35px;
			max-width: none;
		}
	}

	.all-pendency-list-item-options {
		.options {
			.ppsa-more-option-area {
				.ppsa-more-options-area-selector {
					left: calc(100dvw - 110px) !important;
					margin-top: 75px !important;
				}
			}
		}
	}

	.all-pendency-list-empty-list {
		width: 100%;
		text-align: center;
	}
}

.all-pendency-list-body:nth-child(even) {
	background-color: #e5e5e5;
}

.delete-pendency-contain {
	width: 336px;
	display: flex;
	flex-direction: column;

	.delete-pendency-contain-texts {
		display: flex;
		align-items: center;
		flex-direction: column;

		span {
			font-size: 13px;
			margin-bottom: 10px;
		}

		span:last-child {
			margin-bottom: 30px;
		}
	}

	.delete-pendency-contain-actions {
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			width: 68px;
		}

		button:first-child {
			margin-right: 20px;
		}
	}
}

@media only screen and (max-width: 480px) {
	.all-pendency-list-body {
		display: grid;
		height: auto;
		min-width: none;
		.all-pendency-list-item {
			margin: 0px 20px 0px;
			span {
				font-size: 12px;
			}
		}
		.all-pendency-list-item-options {
			div {
				.options {
					display: flex;
				}
			}
		}
	}
}
