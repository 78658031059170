.clients-list-header {
	height: 35px;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #ffffff;

	span {
		font-weight: bold;
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-display: swap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.clients-list-item {
		margin: 0px 5px 0px 0px;
		padding: 0px;
	}

	.clients-list-legend {
		width: 3%;
	}
	.clients-list-client-code {
		width: 4%;
	}
	.clients-list-company-name {
		width: 40%;
	}
	.clients-list-company-register {
		width: 13%;
	}
	.clients-list-last-order {
		width: 7%;
	}
	.clients-list-issue-date {
		width: 6%;
	}
	.clients-list-salesperson {
		width: 13%;
	}
	.clients-list-more-options {
		width: 2%;
	}
}
