.client-list {
	margin-top: 10px;
	padding-right: 10px;
	padding-left: 0px;
	height: 360px !important;
	overflow-y: overlay;
	margin: 0 -20px;
}

.client-info {
	font-size: 13px;
	font-weight: 400;
	text-align: left;
	gap: 10px;
}

.client-item:hover {
	background-color: #d0dff0 !important;
}
