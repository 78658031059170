.device-mobile .clients-list-mobile {
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.clients-list-row {
		width: 100vw;
		height: auto;
		display: flex;
		padding: 20px;
		font-size: 12px;
		overflow: hidden;
		text-align: left;
		white-space: nowrap;
		flex-direction: column;
		text-overflow: ellipsis;
		background-color: #e5e5e5;
	}
	.clients-list-row:nth-child(even) {
		background-color: #F8F9FA;
	}

	.clients-list-first-line {
		display: grid;
		column-gap: 20px;
		grid-template-columns: auto auto;

		.info-title {
			font-weight: 700;
			margin-right: 5px;
		}
		.info-value {
			font-weight: 400;

			img {
				width: 12px !important;
				height: 12px !important;
			}
		}
	}

	.clients-list-item {
		width: 100%;
		display: flex;
		flex-direction: row;

		.info-title {
			display: flex;
			font-weight: 700;
			margin-right: 5px;
			align-items: center;

			img {
				margin-right: 5px;
				width: 12px !important;
				height: 12px !important;
			}
		}
		.info-value {
			font-weight: 400;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.clients-list-empty-list {
		width: 100%;
		text-align: center;
	}
}
