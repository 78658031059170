.features-home {
	display: inline-block;
	width: 100vw;
	background: #ffffff !important;
	position: fixed;

	.features-home-header {
		width: 100%;
		display: flex;
		height: 100px;
		align-items: center;
		background-color: #004687;
		justify-content: space-between;

		.pratica-logo-icon {
			padding-left: 20px;
			float: left;
			width: 250px;
		}

		.user-area {
			top: auto;
			img {
				background-color: white;
				border-radius: 50%;
			}
		}
		.user-area-selector {
			top: 70px;
		}
	}

	.features-home-main-contain {
		display: flex;
		justify-content: center;
		height: calc(100vh - 100px);

		.cards-group {
			display: grid;
			gap: 30px 50px;
			overflow-y: auto;
			justify-items: start;
			padding: 30px 20px 20px;
			grid-template-rows: 295px;
			grid-template-columns: 1fr 1fr 1fr;

			@media screen and (min-width: 1200px) and (max-width: 1800px) {
				grid-template-columns: 1fr 1fr;
				overflow-x: hidden;
			}

			@media screen and (max-width: 1200px) {
				grid-template-columns: 1fr;
			}

			@media screen and (max-width: 600px) {
				width: 100%;
				position: absolute;
				display: flex;
				flex-direction: column;

				.feature-card {
					width: 100%;
				}
			}

			.feature-card {
				width: 550px;
				display: flex;
				padding: 20px;
				font-size: 16px;
				min-height: 290px;
				font-weight: 500;
				line-height: 20px;
				position: relative;
				height: max-content;
				border-radius: 10px;
				flex-direction: column;
				justify-content: space-between;
				box-shadow: 0 1px 4px 2px #a6a6a6;
				text-decoration: none !important;
				-moz-box-shadow: 0 1px 4px 2px #a6a6a6;
				-webkit-box-shadow: 0 1px 5px 2px #a6a6a6;
				background-image: linear-gradient(to top, #d0dff0 0%, #f8f9fa 100%);

				.msgCard {
					font-size: 14px;
					margin-top: 8%;
					margin-bottom: 8%;
				}

				@media screen and (max-width: 600px) {
					width: 100%;
				}
			}

			.feature-card:hover {
				-webkit-animation: wobble 0.8s both;
				animation: wobble 0.8s both;
			}

			@-webkit-keyframes wobble {
				0%,
				100% {
					-webkit-transform: translateX(0);
					transform: translateX(0);
					-webkit-transform-origin: 20% 20%;
					transform-origin: 20% 20%;
				}
				15% {
					-webkit-transform: translateX(-3px) rotate(-0.6deg);
					transform: translateX(-3px) rotate(-0.6deg);
				}
				30% {
					-webkit-transform: translateX(4.5px) rotate(0.6deg);
					transform: translateX(4.5px) rotate(0.6deg);
				}
				45% {
					-webkit-transform: translateX(-3.8px) rotate(-1.8deg);
					transform: translateX(-3.8px) rotate(-1.8deg);
				}
				60% {
					-webkit-transform: translateX(4.5px) rotate(0.6deg);
					transform: translateX(4.5px) rotate(0.6deg);
				}
				75% {
					-webkit-transform: translateX(-3px) rotate(-0.6deg);
					transform: translateX(-3px) rotate(-0.6deg);
				}
			}

			@keyframes wobble {
				0%,
				100% {
					-webkit-transform: translateX(0);
					transform: translateX(0);
					-webkit-transform-origin: 20% 20%;
					transform-origin: 20% 20%;
				}
				15% {
					-webkit-transform: translateX(-3px) rotate(-0.6deg);
					transform: translateX(-3px) rotate(-0.6deg);
				}
				30% {
					-webkit-transform: translateX(4.5px) rotate(0.6deg);
					transform: translateX(4.5px) rotate(0.6deg);
				}
				45% {
					-webkit-transform: translateX(-3.8px) rotate(-1.8deg);
					transform: translateX(-3.8px) rotate(-1.8deg);
				}
				60% {
					-webkit-transform: translateX(4.5px) rotate(0.6deg);
					transform: translateX(4.5px) rotate(0.6deg);
				}
				75% {
					-webkit-transform: translateX(-3px) rotate(-0.6deg);
					transform: translateX(-3px) rotate(-0.6deg);
				}
			}

			.info-card {
				margin-bottom: 14px;
				font-size: 14px;
			}

			.subtext {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: bold;
				color: #025cb7;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.features-home {
		.features-home-header {
			.pratica-logo-icon {
				width: 90px;
			}
		}
		
		.features-home-main-contain {
			height: calc(100dvh - 200px);
			
			.cards-group {
				overflow-y: auto;
				height: 100%;
			}
		}
	} 
}

.device-mobile .feature-card {
	.recognition-card-title,
	.human-resources-card-title,
	.service-management-card-title,
	.order-management-card-title {
		display: flex;
		align-items: center;
		svg {
			transform: scale(0.8);
		}
	}
}
