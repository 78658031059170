progress {
  width: 60px;
  height: 60px;
  border-radius: 6px;
}

progress::-webkit-progress-bar {
  background: #f8f9fa40;
  filter: brightness(10%);
  border-radius: 6px;
}

progress::-webkit-progress-value {
  background: #f8f9fa80 ;
  filter: brightness(10%);
  border-radius: 6px;
}

progress::-moz-progress-bar {
  background: #D0DFF0;
  border-radius: 6px;

}

progress::-moz-progress-value {
  background: #004687;
  border-radius: 6px;
}
