.order-history-list-header {
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	background-color: #ffffff;

	span {
		font-weight: bold;
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-display: swap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.order-history-list-item {
		padding: 0px;
		margin: 0px 5px 0px 0px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.order-history-list-order-status {
		width: 3%;
		display: flex;
		justify-content: flex-end;
	}
	.order-history-list-order {
		width: 10%;
	}
	.order-history-list-order-category {
		width: 15%;
	}
	.order-history-list-order-issue-date {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-delivery-date {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-last-billing {
		width: 11%;
		display: flex;
	}
	.order-history-list-order-salesperson {
		width: 25%;
	}
	.order-history-list-order-financial-status {
		width: 8%;
	}
	.order-history-list-order-more-options {
		width: 2%;
	}
}
