.device-mobile .pendency-filter {
	.pendency-filter-header {
		width: 100%;
		margin-bottom: 5px;

		span {
			font-size: 12px;
			font-weight: bold;
		}
	}

	.pendency-filter-fields {
		display: flex;
		column-gap: 20px;
		align-items: flex-start;
		justify-content: space-between;

		div {
			margin: 0px;
			width: 100%;

			span {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
