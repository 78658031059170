.table-header {
	border-bottom: 1px solid rgba(219, 219, 219);
	font-size: 16px;
	line-height: 40px;
	font-weight: 500;
	text-align: center;
	display: flex;
}

.tab-item {
	padding: 9px 30px 9px 0px !important;
	margin: 0px !important;
}

.tab-item.selected {
	font-weight: bold;
	text-decoration: underline;
}

@media screen and (max-width: 991px) {
	.w-container {
		max-width: 728px;
	}

	.w-row,
	.w-container .w-row {
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (max-width: 479px) {
	.w-container {
		max-width: none;
	}

	.w-col {
		width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.w-col {
		width: 100%;
		left: auto;
		right: auto;
	}

	.table-header {
		display: none;
	}
}

.createPreventive {
	padding: 35px 75px;
}

@media screen and (max-width: 1200px) {
	.createPreventive {
		padding: 40px;
	}
}

@media screen and (max-width: 1024px) {
	.createPreventive {
		padding: 30px;
	}
}

.table-content {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.table-header {
	height: 30px;
	text-align: left;
	padding: 5px 25px 5px 10px !important;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 3px 3px 0px 0px;
	background-color: #f8f9fa;
}

.table.constracts .table-list {
	overflow-y: scroll;
	height: 434px;
}

.table.schedules .table-list {
	overflow-y: scroll;
	height: 440px;
}

.table-item {
	padding: 5px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.schedules-table-header-item-client,
.schedules-table-header-item-equip,
.schedules-table-header-item-serial,
.schedules-table-header-item-prev,
.schedules-table-header-item-location,
.contracts-table-header-item-razao,
.contracts-table-header-item-cnpj,
.contracts-table-header-item-end-contract,
.contracts-table-header-item-equip {
	font-weight: 500 !important;
	text-transform: unset !important;
}

.schedules-table-item-client,
.schedules-table-item-equip,
.schedules-table-item-serial,
.schedules-table-item-prev,
.schedules-table-item-location,
.schedules-table-header-item-client,
.schedules-table-header-item-equip,
.schedules-table-header-item-serial,
.schedules-table-header-item-prev,
.schedules-table-header-item-location,
.contracts-table-item-razao,
.contracts-table-item-cnpj,
.contracts-table-item-end-contract,
.contracts-table-item-equip,
.contracts-table-header-item-razao,
.contracts-table-header-item-cnpj,
.contracts-table-header-item-end-contract,
.contracts-table-header-item-equip {
	position: relative;
	float: left;
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	font-size: 12px;
	font-weight: 400;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-transform: uppercase;
}

.contracts-table-item-razao,
.contracts-table-header-item-razao {
	width: 60%;
	text-align: left;
}

.contracts-table-item-cnpj,
.contracts-table-header-item-cnpj {
	width: 20%;
	text-align: left;
}

.contracts-table-item-end-contract,
.contracts-table-header-item-end-contract {
	width: calc(20% - 100px);
	text-align: left;
}

.contracts-table-item-equip,
.contracts-table-header-item-equip {
	width: 100px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.schedules-table-item-client,
.schedules-table-header-item-client {
	width: 15%;
	text-align: left;
}

.schedules-table-item-client span:first-child {
	margin-right: 5px;
	cursor: pointer;
}

.schedules-table-item-equip,
.schedules-table-header-item-equip {
	width: 32%;
	text-align: left;
}

.schedules-table-item-serial,
.schedules-table-header-item-serial {
	width: 8%;
	text-align: left;
}

.schedules-table-item-prev,
.schedules-table-header-item-prev {
	width: 15%;
	text-align: left;
}

.schedules-table-item-location,
.schedules-table-header-item-location {
	width: 30%;
	text-align: left;
}

.schedules-table-header-item-client .MuiButtonBase-root,
.schedules-table-header-item-location .MuiButtonBase-root {
	margin-top: -5px;
}

.MuiIconButton-root {
	padding: 0px !important;
}

.MuiIconButton-colorPrimary {
	color: #000 !important;
}

.MuiIconButton-colorPrimary:hover {
	background-color: rgba(0, 123, 255, 0.05) !important;
}

.MuiSvgIcon-root {
	width: 0.8em !important;
	height: 0.8em !important;
}

.loading-list {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}

.loading-list div {
	margin-bottom: 10px;
}

.add-preventive-contract {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #333;
	font-size: 13px;
	font-weight: 500;
	text-align: left;
	text-decoration: underline;
	cursor: pointer;
}

.search-area {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 15px;
}

.search-contract-field {
	width: 320px;
}

.search-area .MuiInputBase-input {
	display: block;
	width: 100%;
	padding: 8px 12px !important;
	margin-bottom: 10px !important;
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
	background-color: #ffffff !important;
	border: 1px solid #cccccc !important;
	max-height: 30px !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
}

.search-area .MuiInputBase-input:focus {
	border-color: #3898ec !important;
}

.search-area .MuiInput-underline:after,
.search-area .MuiInput-underline:before {
	position: absolute;
	transform: none !important;
	transition: none !important;
	border-bottom: none !important;
	pointer-events: none !important;
}

.search-area .MuiInputBase-input,
.search-contract-field .icon-button,
.search-area .MuiIconButton-colorPrimary {
	padding-right: 5px;
	padding-bottom: 14px;
	padding-left: 5px;
	background-color: transparent;
	color: #333 !important;
	font-size: 30px;
}

.schedule-field {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.schedule-field button {
	width: 120px;
}

.schedule-search-area {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
