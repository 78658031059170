.products-card-title {
	display: flex;
	justify-content: space-between;

	h4 {
		font-size: 20px;
		width: 80%;
		color: #004687;
	}
}
