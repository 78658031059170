.dropzone {
	border: 1px dashed #6c757d;
	border-radius: 4px;
	cursor: pointer;
	padding: 20px;
	margin: 0px;

	transition: height 0.2s ease;
}

.dropzone:hover {
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.65) inset;
}

.dragReject {
	border-color: red;
}

.dragActive {
	border-color: green;
}

.file-list {
	width: 100%;
	padding: 0 20px 0 20px;
}
