.header-container {
	display: flex !important;
	justify-content: center;
	z-index: 2;
	width: 100%;
	flex-direction: row;
}

.header-container > .content {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	color: #fff;
	z-index: 0;
}

.nav-menu-container {
	padding: 0px 0;
	margin-top: 59px;
}

.advance {
	visibility: visible;
	opacity: 1;
	transition: width 0.3s ease-in-out;
}

.menu-logo-pratica {
	width: 135px;
}

.back-off-span {
	visibility: hidden;
	opacity: 0;
}

.back-off-logo {
	width: 100px !important;
}

.back-off-logo .menu-logo-pratica {
	width: 65px !important;
}

.nav-actions-content,
.nav-confg-content {
	width: 100%;
	max-width: 250px;
	display: flex;
	align-self: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.nav-menu-container a {
	color: #f8f9fa;
	font-size: 15px;
	font-weight: 400;
	text-align: left;
	line-height: 30px;
	border-left: 3px solid transparent;
	text-decoration: none !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 70px;
	padding-left: 30px;
}

.nav-menu-container a:visited,
.nav-menu-container a:link {
	text-decoration: none !important;
}

.nav-menu-container a span {
	padding-left: 15px;
}

.nav-menu-container a:hover {
	background-color: #025cb7;
}

.link-active {
	background-color: #025cb7;
}

.nav-confg-content a {
	font-size: 14px;
}

.nav-content {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.nav-brand-content {
	width: 250px;
	height: 40px;
	margin-top: 66px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nav-title-content {
	padding: 40px 20px;
	width: 100%;
}

.nav-title-content .title {
	margin-top: 50px;
	font-size: 25px;
	line-height: 25px;
	font-weight: 700;
	text-align: left;
}

.nav-menu-container {
	display: flex;
	align-self: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.icons {
	width: 30px;
	height: auto;
}
