.filter-modal {
	width: 300px;
	height: 240px;
	margin-top: 5px;
	padding: 16px 0 20px 16px;

	text-transform: none;
	box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.46);
}

.device-mobile .filter-modal {
	width: 100%;
	padding: 20px;
}

.filter-title {
	width: 100%;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 10px;
}

.filter-list {
	width: 100%;
	height: calc(100% - 60px);
	overflow-y: scroll;
	margin-bottom: 20px;
}

.device-mobile .filter-list {
	margin-bottom: 0px;
}

.filter-item {
	padding: 8px 0;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

.filter-item label {
	margin-left: 5px;
	margin-bottom: 0px;
	font-weight: 400;
	cursor: pointer;
}

.filter-footer {
	padding-right: 20px;
	display: flex;
	justify-content: space-between;
	align-self: center;
}

.device-mobile .filter-footer {
	padding: 0px;
}

.filter-footer div {
	cursor: pointer;
}
