.order-client-tab {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 0 20px;
	margin: 0px -20px;
	overflow-y: overlay;
	height: calc(100vh - 154px);

	form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.section-container-action-edit {
		gap: 20px;
		width: 100%;
		display: flex;
		max-width: 152px;
		flex-direction: row;
		margin: 0 20px 0 auto;

		.edit-button {
			gap: 5px;
			display: flex;
			align-items: center;
			color: var(--hight-pure);
		}
	}
}

.device-mobile .order-client-tab {
	margin: 0px -20px;
	overflow-y: overlay;
	padding-bottom: 20px;
	height: calc(100vh - 201px);

	.section-container-action-edit {
		top: 20px;
		margin: 0px;
		right: 70px;
		max-width: 40px;
		position: absolute;
	}

	.section-component {
		margin: 20px 0px 0px;

		.section-component-body,
		.section-component-title {
			border-radius: 0px;
		}
	}
}