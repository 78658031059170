.device-mobile .list-view {
	height: calc(100vh - 192px);
	overflow-y: auto;
	margin: 0px -20px 10px;
	padding: 0px 20px;

}

.transfers-paginator {
	margin-top: 20px;
}