.client-update-log-timeline-guide-main-contain {
	padding-top: 20px;
	overflow-y: overlay;
	height: calc(100% - 100px);

	.client-update-log-header {
		color: #004992;

		.client-update-log-header-name {
			font-size: 25px;
			margin-bottom: -5px;
		}

		.client-update-log-header-code {
			font-weight: 500;
			margin-bottom: 20px;
		}
	}

	.event {
		display: flex;
		position: relative;
		cursor: pointer;
		margin-left: 10px;

		.map {
			width: 10px;
			margin-left: -10px;

			.line {
				top: 30px;
				margin-left: 4px;
				height: 100%;
				position: absolute;
				border-left: 2px solid #4E8DE8;
			}

			.ball {
				top: 25px;
				width: 10px;
				height: 10px;
				position: absolute;
				border-radius: 50%;
				background: #4E8DE8;
			}
		}


		.info {
			width: 100%;
			display: flex;
			max-width: 95%;
			margin-left: -5px;
			padding-left: 15px;
			margin-bottom: 30px;
			flex-direction: column;
			font-size: 13px;
			font-weight: 400;

			.event-title {
				font-weight: 700;
			}

			.event-content,
			.event-user {
				margin-top: 10px;
			}
		}

		&:hover .info{
			background: transparent linear-gradient(90deg, rgba(203, 218, 235, 0.2509803922) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
			cursor: pointer;
		}
	}

	.log-compare {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		column-gap: 20px;
		height: 30px;
		justify-content: space-between;
	
	
		span {
			display: flex;
			align-items: center;
			background: #D0DFF0;
			color: #025CB7;
			padding-left: 10px;
			border-radius: 6px 6px 0 0 ;
			label {
				margin-bottom: 0;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
		.log-compare-title-old {
			min-width: 50%;
		}
	
		.log-compare-title-new {
			width: 100%;
		}
	}
}

.device-mobile .client-update-log-timeline-guide-main-contain {
	height: calc(100% - 130px);
}