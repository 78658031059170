.order-follow-up-tab-timeline-guide {
	margin: 20px 0px;
	position: relative;
	height: calc(100% - 20px);

	.order-follow-up-tab-timeline-guide-header {
		gap: 20px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.order-follow-up-tab-timeline-guide-header-filters {
			width: 100%;
			height: 40px;
			display: flex;
			column-gap: 20px;
			align-items: center;

			.timeline-guide-filter {
				display: flex;
				column-gap: 5px;
				align-items: center;

				label {
					margin: 0px;
					font-size: 12px;
					font-weight: 500;
				}
			}
		}

		.order-follow-up-tab-timeline-guide-header-actions {
			width: 194px;
		}
	}

	.order-follow-up-tab-timeline-guide-main-contain {
		margin: 20px 0px;
		overflow-y: overlay;
		height: calc(100% - 182px);

		.event {
			display: flex;
			position: relative;
			margin-left: 10px;

			.map {
				width: 10px;
				margin-left: -10px;

				.line {
					top: 30px;
					margin-left: 4px;
					height: 100%;
					position: absolute;
					border-left: 2px solid #4E8DE8;
				}

				.ball {
					top: 25px;
					width: 10px;
					height: 10px;
					position: absolute;
					border-radius: 50%;
					background: #4E8DE8;
				}
			}


			.info {
				width: 100%;
				display: flex;
				max-width: 95%;
				margin-left: -5px;
				padding-left: 15px;
				margin-bottom: 30px;
				flex-direction: column;
				font-size: 13px;
				font-weight: 400;

				.event-title {
					font-weight: 700;
				}

				code {
					color: #4E8DE8;
				}

				.event-content,
				.show-attachments,
				.event-user {
					margin-top: 10px;
				}

				.show-attachments {
					display: flex;
					gap: 10px;

					.deleted {
						background: #dbdbdb !important;
						color: #b9b9b9 !important;
						cursor: not-allowed;
					}

					.attachment-button {
						width: 72px;
						display: flex;
						outline: none;
						font-size: 12px;
						color: #004687;
						border-radius: 5px;
						white-space: nowrap;
						background: #d0dff0;
						justify-content: center;
					}
				}
			}

			&:hover .info {
				background: transparent linear-gradient(90deg, rgba(203, 218, 235, 0.2509803922) 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
				cursor: pointer;
			}
		}
	}
}

.device-mobile .order-follow-up-tab-timeline-guide {
	position: relative;
	height: calc(100% - 74px);

	.order-follow-up-tab-timeline-guide-header {
		display: flex;
		margin-bottom: 20px;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.order-follow-up-tab-timeline-guide-header-filters {
			height: 40px;
			padding-left: 10px;
			overflow-x: overlay;
			white-space: nowrap;
		}

		.order-follow-up-tab-timeline-guide-header-actions {
			width: 126px;

			button {
				font-size: 12px;
			}
		}
	}

	.order-follow-up-tab-timeline-guide-main-contain {
		display: flex;
		overflow-y: overlay;
		padding-right: 20px;
		flex-direction: column;
		height: calc(100% - 130px);

		.event {
			.info {
				.event-content {
					padding-right: 20px;
				}

				.show-attachments {
					display: grid;
					gap: 10px;
					grid-template-columns: auto auto auto auto;
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {
	.order-follow-up-tab-timeline-guide {
		.order-follow-up-tab-timeline-guide-main-contain {
			.event {
				.info {
					.show-attachments {
						grid-template-columns: auto auto auto !important;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 300px) {
	.order-follow-up-tab-timeline-guide {
		.order-follow-up-tab-timeline-guide-main-contain {
			.event {
				.info {
					.show-attachments {
						grid-template-columns: auto auto !important;
					}
				}
			}
		}
	}
}