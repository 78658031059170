.service-management-card-title {
	display: flex;
	justify-content: space-between;

	h4 {
		font-size: 20px;
		width: 80%;
		color: #004687;
	}
}

.service-management-card-main-contain {
	height: calc(70% - 30px);

	.subtext {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: bold;
		color: #025cb7;
	}

	.summary2 {
		height: 83px;
		padding: 10px;
		font-size: 14px;
		border: 1px solid;
		border-radius: 6px;
		margin-bottom: 20px;
		border-color: #d0dff0;
		background-color: #f8f9fa;

		.last-service {
			font-size: 13px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.service-management-card-actions {
	display: flex;
	justify-content: center;

	button {
		width: 245px;
		font-size: 12px;
		text-transform: capitalize;
	}
}
