.loading-main-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;

	.loading-main {
		width: 420px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		span {
			margin-top: 40px;
			text-transform: uppercase;
			font-size: 15px;
			font-weight: 500;
			opacity: 0.6;
		}
	}
	.MuiCircularProgress-colorPrimary {
		color: #004687;
	}
}

@media screen and (max-width: 900px) {
	.loading-main-content {
		.loading-main {
			width: 100%;

			span {
				margin-top: 35px;
				font-size: 12px;
			}
		}
	}
}
