.service-order-equipment-info {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
}

.device-mobile .service-order-equipment-info {
	flex-direction: column;
}

.device-mobile .service-order-equipment-info div {
	width: 100%;
}

.service-order-equipment-info div {
	width: calc(50% - 10px);
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
}

.service-order-equipment-info div span {
	font-size: 14px;
	font-weight: 400 !important;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-right: 10px;
}

.service-order-client-status-info {
	margin: 10px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
}

.device-mobile .service-order-client-status-info {
	margin-bottom: 20px;
}

.service-order-client-status-info div {
	color: red;
	font-size: 12px;
	border: 1px solid;
	border-radius: 3px;
	padding: 0px 4px;
	font-weight: 300 !important;
	margin-right: 10px;
}
