.all-recognition-card-contain {
	width: 650px;
	height: 100%;

	.all-recognition-card-title {
		font-size: 13px;
		font-weight: 400;
	}

	.all-recognition-card-main-contain {
		gap: 20px;
		height: 100%;
		display: flex;
		margin-top: 10px;

		.all-received-recognition-card,
		.all-sent-received-recognition-card {
			width: 50%;
			height: 450px;
			margin-bottom: 10px;
		}
	}
}

.device-mobile .all-recognition-card-contain {
	width: 100%;

	.all-recognition-card-title {
		font-size: 12px;
	}

	.all-recognition-card-main-contain {
		display: flex;
		flex-direction: column;

		.all-received-recognition-card,
		.all-sent-received-recognition-card {
			width: 100%;
			height: 50%;
		}
	}
}

.all-recognition-table-container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.header {
		display: flex;
		padding: 10px;
		font-size: 12px;
		color: var(--primary-highlight);
		font-weight: 500;
		margin-top: 10px;
		border-radius: 6px 6px 0 0;
		background-color: #D0DFF0;
		border: 1px solid #D0DFF0;
		justify-content: space-between;

		span {
			gap: 5px;
			display: flex;
			align-items: center;
		}
	}

	.table-body {
		height: 100%;
		overflow-y: auto;

		.table-row {
			display: flex;
			padding: 10px;
			font-size: 12px;
			justify-content: space-between;

			&:nth-child(odd) {
				background-color: #E5E5E5;
			}

			&:hover {
				background-color: #D0DFF0;
			}
		}
	}
}