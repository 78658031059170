.service-order-internal-obs {
	width: 600px;
	height: 450px;
	position: relative;

	.service-order-internal-obs-main-contain {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.device-mobile .service-order-internal-obs {
	width: 100%;
	height: 100%;
}
