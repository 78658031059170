.recover-account-main-content {
	width: 100vw;
	height: 100vh;
	position: relative;
	color: #f8f9fa;
}

@media only screen and (max-device-width: 1080px) and (orientation: portrait) {
	.recover-account-main-content {
		height: var(--app-height);
	}
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1080px) and (orientation: landscape) {
	.recover-account-main-content {
		height: var(--app-height);
	}
}
