.main-mobile-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	color: #f8f9fa;
	position: fixed;
	bottom: 0;
	width: 100vw;

	-webkit-box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.15);
	box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.15);

	.user-client-options-item,
	.user-select-options {
		width: 100vw;
		height: 70px;
		display: flex;
		align-items: center;
	}

	.user-select-options {
		flex-direction: row;
		justify-content: space-evenly;

		.user-select-options-item {
			width: 60px;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;

			img.menu-icon {
				width: 35px;
				height: 34px;
			}
		}

		.user-select-options-item:last-child {
			margin-right: 0px;
		}

		.user-select-options-item.notify {
			position: relative;

			.user-notifications-badge {
				height: 13px;
				width: 13px;
				border-radius: 100%;
				position: absolute;
				top: 10px;
				right: 15px;
				cursor: pointer;
			}

			.user-notifications-badge.hide {
				display: none;
			}
		}
	}

	.user-client-options-item {
		width: auto;
		max-width: 180px;
		box-shadow: -3px 0px 6px #00000029;
		border-top-left-radius: 10px;
		padding: 0px 20px;

		.dropdown-area {
			max-width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.user-client-options-item.locked {
		opacity: 0.5;
	}

	@media screen and (max-width: 370px) {
		.user-client-options-item {
			width: 160px;
		}
	}

	@media screen and (max-width: 360px) {
		.user-select-options {
			padding-right: 4px;

			.user-select-options-item:last-child {
				margin-right: 0px;
			}
		}
	}

	.user-name,
	.client-name {
		text-transform: uppercase;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-o-user-select: none;
	}
}
