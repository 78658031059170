.pratica-image {
	width: 60%;
	height: 100%;
	margin-left: 40%;
	background-image: url('../../../../assets/image/pratica-fab.webp');
	background-position: 100% 0%;
	background-size: cover;
	background-repeat: no-repeat;
}

@media screen and (max-width: 1440px) {
	.pratica-image {
		background-position: 15% 0%;
	}
}

@media screen and (max-width: 1280px) {
	.pratica-image {
		width: 70%;
		background-position: -30% 0%;
		margin-left: 30%;
	}
}

@media screen and (max-width: 900px) {
	.pratica-image {
		display: none;
	}
}
