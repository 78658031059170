.transfer-equip-info {
	display: flex;
	flex-direction: row;
	position: relative;
	div {
		width: 50%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		span {
			width: calc(100% - 15px);
			font-size: 13px;
			color: #222;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			b {
				margin-right: 5px;
			}
		}
	}
}

.device-mobile .transfer-equip-info {
	flex-direction: column;
	div {
		width: 100%;
	}
}
