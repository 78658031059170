.prp-user-modal {
	width: 660px;
	margin-bottom: 30px;

	.prp-user-form {
		row-gap: 15px;
		display: flex;
		flex-direction: column;
		max-height: 500px;
		overflow-y: auto;

		.prp-user-form-rows {
			display: flex;
			column-gap: 20px;

			.form-field-info {
				width: 100%;

				.search-action {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					svg {
						cursor: pointer;
						fill: var(--primary-pure) !important;
					}
				}

				label {
					font-size: 12px;
				}

			}
		}

		.form-field-info {
			.form-field-checkbox-items {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 5px;
				
				.MuiFormControlLabel-root {
					gap: 5px !important;
					margin-left: 0px !important;

					.MuiTypography-body1 {
						font-size: 12px !important;
					}
				}
			}

			.prp-user-field-control-integration {
				display: flex;
				column-gap: 10px;

				button {
					width: 120px;
				}
			}

			label{
				font-size: 12px;
			}
		}
	}
}

.prp-user-modal-actions {
	left: 50%;
	bottom: 20px;
	display: flex;
	position: absolute;
	justify-content: center;
	transform: translateX(-50%);

	button {
		width: 120px;

		&:last-child {
			margin-left: 20px;
		}
	}
}

.device-mobile {
	.prp-user-modal {
		width: 100%;
		overflow-y: auto;

		.prp-user-form {
			.prp-user-form-rows {
				row-gap: 20px;
				flex-direction: column;

				&:first-child {
					flex-direction: row;
				}
			}

			.form-field-info {
				.form-field-checkbox-items {
					grid-template-columns: 1fr;
				}
			}
		}
	}
}