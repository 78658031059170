.page-os-equip-info {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.page-os-equip-name {
	display: flex;
	gap: 10px;
	align-items: center;
	width: 100%;
	text-align: left;
	margin-bottom: 10px;
}

.page-os-equip-name span {
	font-size: 28px;
	font-weight: 600;
	text-align: left !important;
}

.page-os-equip-info-item {
	width: 34%;
	padding-right: 10px;
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
	font-size: 15px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.page-os-equip-info-item p {
	margin: 0px;
	font-weight: 600;
}

.page-os-equip-info-item span {
	margin-left: 5px;
	font-weight: 400;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

@media screen and (max-width: 1650px) {
	.page-os-equip-info-item {
		width: 50%;
	}
}

@media screen and (max-width: 1220px) {
	.page-os-equip-info-item {
		width: 100%;
	}
}
