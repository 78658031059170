.device-mobile .orders-list {
	display: flex;
	flex-direction: column;

	.orders-list-row {
		width: 100vw;
		height: auto;
		display: flex;
		padding: 10px;
		font-size: 12px;
		overflow: hidden;
		text-align: left;
		white-space: nowrap;
		flex-direction: column;
		text-overflow: ellipsis;
		background-color: #e5e5e5;
	}
	.orders-list-row:nth-child(even) {
		background-color: #F8F9FA;
	}

	.orders-list-first-line {
		display: grid;
		column-gap: 20px;
		grid-template-columns: 1fr 1fr;

		div {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&.three-lines {
			grid-template-columns: 1fr 1fr 1fr;
			
		}

		.info-title {
			font-weight: 700;
			margin-right: 5px;
		}
		.info-value {
			font-weight: 400;
		}
	}

	.orders-list-item {
		width: 100%;
		margin: 0px;

		.info-title {
			display: flex;
			font-weight: 700;
			margin-right: 5px;
			align-items: center;

			img {
				margin-right: 5px;
				width: 12px !important;
				height: 12px !important;
			}
		}
		.info-value {
			font-weight: 400;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.orders-list-fifth-line {
		display: grid;
		grid-template-columns: 2fr 2fr 1fr;
	}

	.orders-list-empty-list {
		width: 100%;
		text-align: center;
	}
}
