.modal-content {
	overflow: none;
	height: auto;
	width: 1600px;
	margin-top: 30px;
	margin-right: auto;
	margin-left: auto;
	padding: 20px 0 20px 20px;
	border-radius: 3px;
	background-color: #fff;
	text-align: justify;
}

.new-preventive-contract {
	width: 860px;
	height: 510px;
}

.modal-header {
	border: none !important;
	padding: 0px !important;
}

.modal-header h3 {
	margin-top: 10px;
	margin-bottom: 20px;
	font-size: 28px;
	line-height: 30px;
	font-weight: 500;
	text-align: left;
}

.modal-close {
	display: flex;
	flex-direction: row-reverse;
	padding: 15px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}

.search-area {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.search-field {
	width: 320px;
}

.search-area .MuiInputBase-input {
	display: block;
	width: 100%;
	padding: 8px 12px !important;
	margin-bottom: 10px !important;
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
	background-color: #ffffff !important;
	border: 1px solid #cccccc !important;
	max-height: 30px !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
}

.search-area .MuiInputBase-input:focus {
	border-color: #3898ec !important;
}

.search-area .MuiInput-underline:after,
.search-area .MuiInput-underline:before {
	position: absolute;
	transform: none !important;
	transition: none !important;
	border-bottom: none !important;
	pointer-events: none !important;
}

.search-area .MuiInputBase-input,
.search-field .icon-button,
.search-area .MuiIconButton-colorPrimary {
	padding-right: 5px;
	padding-bottom: 14px;
	padding-left: 5px;
	background-color: transparent;
	color: #333 !important;
	font-size: 30px;
}

.search-area.equips {
	padding: 10px 26px 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiRadio-colorSecondary.Mui-checked {
	color: #007bff !important;
}

.MuiIconButton-colorSecondary:hover {
	background-color: rgba(0, 123, 255, 0.05) !important;
}

.MuiSvgIcon-root {
	width: 0.8em !important;
	height: 0.8em !important;
}

.tab-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	padding: 9px 30px;
	text-align: left;
	cursor: pointer;
	color: #222222;
}

.tab-item.selected {
	font-weight: bold;
}

.client-item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 30px;
	gap: 10px;
	padding: 0 20px;
	background-color: transparent;
	&:nth-child(even) {
		background-color: #e5e5e5;
	}
}

.table.location {
	height: 450px;
	overflow-y: scroll;
	padding-right: 10px;
}

.table-content {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.table-header {
	height: 30px;
	text-align: left;
	padding: 5px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 3px 3px 0px 0px;
	background-color: #f8f9fa;
}

.table-item {
	padding: 5px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.equips-table-item-name,
.equips-table-item-sn,
.equips-table-item-prev,
.equips-table-item-address,
.equips-table-header-item-name,
.equips-table-header-item-sn,
.equips-table-header-item-prev,
.equips-table-header-item-address,
.location-table-item-name,
.location-table-item-zip,
.location-table-item-street,
.location-table-item-neigh,
.location-table-item-city,
.location-table-item-state,
.location-table-header-item-name,
.location-table-header-item-zip,
.location-table-header-item-street,
.location-table-header-item-neigh,
.location-table-header-item-city,
.location-table-header-item-state {
	position: relative;
	float: left;
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	font-size: 13px;
	font-weight: 500;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.location-table-item-name,
.location-table-header-item-name {
	width: 25%;
}

.location-table-item-zip,
.location-table-header-item-zip {
	width: 16.66666667%;
}

.location-table-item-street,
.location-table-header-item-street {
	width: 25%;
}

.location-table-item-neigh,
.location-table-header-item-neigh {
	width: 16.66666667%;
}

.location-table-item-city,
.location-table-header-item-city {
	width: 16.66666667%;
}

.location-table-item-state,
.location-table-header-item-state {
	width: 8.33333333%;
}

.equips-table-header-item-name,
.equips-table-item-name {
	width: 62%;
}

.equips-table-item-name .MuiIconButton-root,
.equips-table-item-address .MuiIconButton-root {
	padding: 0px 10px 0px 0px !important;
}

.equips-table-header-item-sn,
.equips-table-item-sn {
	width: 8%;
}

.equips-table-header-item-prev,
.equips-table-item-prev {
	width: 8%;
}

.equips-table-header-item-address,
.equips-table-item-address {
	width: 30%;
	display: flex;
	justify-content: space-between;
}

.equips-table-item-address .MuiIconButton-colorPrimary {
	color: #222222 !important;
}

.client-radio {
	width: 20px;
}

.client-info {
	width: calc(100% - 50px);
	display: flex;
	flex-direction: row;
	text-align: left;
}

.client-name {
	width: 20%;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.client-cnpj {
	width: 16%;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.table-header-address,
.client-address {
	width: 48%;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.client-equips {
	width: 16%;
	text-align: right;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.loading-list {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}

.loading-list div {
	margin-bottom: 10px;
}

.modal-list {
	height: 360px;
	margin: 15px 0 0;
	padding-right: 0px;
	position: relative;
}

.modal-list.locations {
	height: 533px !important;
}

.modal-list.equips {
	height: 287px !important;
	margin: 15px 0 10px;
	overflow-y: scroll;
	padding-right: 10px;
}

.modal-list.equips.selected-items {
	height: 339px !important;
}

.modal-footer {
	position: absolute;
	border: none;
	bottom: 0px;
	right: 0px;
	padding: 0;
	margin: 0;
}
.btn-confirm {
	height: 38px;
	border-radius: 3px;
	background-color: #004687;
	color: white;
	border: 0;
	margin: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	padding: 9px 15px;
}

.btn-confirm:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.equips-selected,
.add-location-link {
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #333;
	font-size: 13px;
	font-weight: 500;
	text-align: left;
	text-decoration: underline;
	cursor: pointer;
}

.add-location {
	position: relative;
	transition: left 500ms ease;
	height: 456px;
}

.add-location input:focus {
	border-color: #3898ec !important;
}

.add-location-footer button {
	cursor: pointer;
}

.add-location-footer {
	display: flex;
	position: absolute;
	bottom: 0;
	width: 100%;
	justify-content: center;
	gap: 20px;
}

.empty-list {
	min-height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #333;
	font-size: 13px;
	font-weight: 500;
}

.date-area {
	width: 100%;
	height: 116px;
	padding: 20px 0;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	align-items: flex-start;
}

.date-area span {
	margin-bottom: 10px;
}

.date-area div {
	margin-left: 10px;
}

.message div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@media screen and (max-width: 1600px) {
	.modal-content {
		height: calc(100vh - 60px);
		width: 100% !important;
		margin: 0px !important;
	}

	.modal-list {
		max-height: 360px !important;
	}

	.modal-list.equips {
		height: 223px !important;
		padding-right: 25px;
	}

	.modal-list.equips.selected-items {
		height: 275px !important;
	}

	.client-list {
		height: 355px !important;
		max-height: 355px !important;
		padding: 0 !important;
	}

	.table.location {
		height: 402px !important;
		padding-right: 25px;
	}
}

@media screen and (max-width: 1024px) {
	.client-cnpj {
		width: 15%;
		padding-right: 10px;
	}

	.table-header-address,
	.client-address {
		width: 55%;
		padding-right: 10px;
	}

	.client-equips {
		width: 10%;
		padding-right: 10px;
	}

	.equips-table-header-item-name,
	.equips-table-item-name {
		width: 55%;
	}

	.equips-table-header-item-sn,
	.equips-table-item-sn {
		width: 15%;
	}

	.equips-table-header-item-address,
	.equips-table-item-address {
		width: 30%;
	}
}
