.upload-single-file {
  .preview {
    position: relative;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 6px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 160px;
      max-height: 160px;
    }

    .delete-image {
      display: flex;
      align-items: center;
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      justify-content: center;
      cursor: pointer;
    }
  }

  .empty {
    width: 160px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    cursor: pointer;
    border-radius: 6px;
    border: 1px dashed #707070;
    .plus {
      font-size: 50px;
      color: #707070;
      font-weight: 300;
    }
  }
}
