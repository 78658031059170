.all-service-orders {
	height: calc(100dvh - 80px);
	margin: 0px -20px;

	.all-service-orders-table {
		display: flex;
		flex-direction: column;
		height: calc(100% - 170px);
		overflow-y: auto;
	}

	.all-service-orders-paginator {
		margin-top: 20px;
	}

	.all-service-orders-pagination {
		bottom: 0;
		width: 100%;
		display: flex;
		margin: 20px;
		position: relative;
		align-items: center;
		justify-content: center;
	}
}

.device-mobile .all-service-orders {
	.all-service-orders-table {
		margin: 0px 0px 10px;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(var(--app-height) - 237px);
	}

	.all-service-orders-paginator {
		margin-top: 5px;
	}

	.all-service-orders-filter-manager-contain {
		width: calc(100% - 20px);
		transform: translate3d(10px, 65px, 0px) !important;
	}

	.all-service-orders-pagination {
		margin: 0px;
		padding: 0px;
		bottom: 62px;
		position: fixed;
		width: calc(100vw - 40px);

		.MuiPagination-ul {
			flex-wrap: nowrap;
			transform: scale(0.8);
		}
	}
}

// Antigos
div.closedOS.hidden {
	display: none;
}

.DropImage.shown {
	content: url('../../../../assets/image/up.png');
	padding-top: 13px;
}

.orderImage {
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.up {
	content: url('../../../../assets/image/arrow_upward-24px.svg');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.down {
	content: url('../../../../assets/image/arrow_downward-24px.svg');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.disable {
	content: url('../../../../assets/image/arrow_downward-24px.svg ');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(70%);
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #000;
	font-size: 17px;
	text-decoration: none;
}

.text-block {
	margin-top: 0px;
	margin-right: 5px;
	text-align: center;
	align-self: center;
	display: block;
	margin-top: 20px;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	text-align: left;
	line-height: 14px;
	text-decoration: underline;
	cursor: pointer;
}

.image-icon {
	margin-right: 5px;
}

.loading-content {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}

.loading-content div {
	margin-bottom: 10px;
}

.empty-list {
	text-align: center;
	margin: 20px 0;
}
