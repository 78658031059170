.service-orders-list {
	display: flex;
	flex-direction: column;

	.service-orders-list-row {
		width: 100%;
		display: flex;
		align-items: flex-start;
		background-color: #e5e5e5;

		a {
			width: 100%;
			height: 55px;
			display: flex;
			padding: 10px 20px;
			color: #333333 !important;

			&:hover {
				text-decoration: none !important;
			}
		}

		&:hover {
			background-color: #d0dff0 !important;
			.service-orders-list-more-options {
				visibility: initial;
			}
		}
	}
	.service-orders-list-row:nth-child(even) {
		background-color: #F8F9FA;
	}

	.service-orders-list-item {
		margin: 0px 10px 0px 0px;
		padding: 0px;
		display: flex;
		align-items: center;
		font-size: 13px;
		text-align: left;
		font-weight: 400;
		color: #333333;
	}

	.service-orders-list-created_at {
		width: 7%;
	}
	.service-orders-list-order {
		width: 8%;
		overflow: hidden;
		white-space: nowrap;
		flex-direction: column;
		align-items: flex-start;
	}
	.service-orders-list-state {
		width: 6%;
	}
	.service-orders-list-company-name {
		width: 15%;
	}
	.service-orders-list-equipment-name {
		width: 20%;
		span {
			max-height: 30px;
			overflow: hidden;
			line-height: 15px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
		}
	}
	.service-orders-list-equipment-serie {
		width: 7%;
	}
	.service-orders-list-status {
		width: 11%;
	}
	.service-orders-list-type {
		width: 15%;
	}
	.service-orders-list-follow-up {
		width: 9%;
	}
	.service-orders-list-more-options {
		width: 2%;
		height: 100%;
		display: flex;
		max-width: 10px;
		visibility: hidden;
		align-items: center;
	}
	.service-orders-list-empty-list {
		width: 100%;
		text-align: center;
	}
}

.device-mobile .service-orders-list {
	display: flex;
	flex-direction: column;

	.service-orders-list-row {
		width: 100%;
		display: flex;
		align-items: flex-start;
		background-color: #e5e5e5;

		a {
			height: initial;
			flex-direction: column;

			&:hover {
				text-decoration: none !important;
			}
		}

		.service-orders-list-more-options {
			visibility: initial;
			margin-right: 5px;
		}

		.service-order-item-atribute {
			display: grid;
			position: relative;
			justify-content: start;
			grid-template-columns: auto auto;

			.service-order-item-atribute-info {
				margin-right: 5px;
				font-size: 12px;
				font-weight: 700;
			}

			.service-order-item-atribute-value {
				font-size: 12px;
				font-weight: 400;
				overflow: hidden;
				position: relative;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.service-order-item-first-line {
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
		.service-order-item-second-line {
			display: grid;
			grid-template-columns: 2fr 2fr 1fr;
		}
		.service-order-item-third-line {
			grid-template-columns: 1fr;
			display: grid;

			.service-order-item-atribute .service-order-item-atribute-value {
				max-height: 36px;
				overflow: hidden;
				white-space: nowrap;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
			}
		}
	}
	.service-orders-list-row:nth-child(even) {
		background-color: #F8F9FA;
	}
}
