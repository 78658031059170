.all-service-orders-actions {
	display: flex;
	margin: 0 20px 20px;
	justify-content: space-between;

	.service-orders-search-and-filter {
		display: flex;

		.search-light {
			width: 400px;
		}

		button {
			width: auto;
			background-color: transparent;
		}
	}

	button {
		width: auto;
	}
}

.device-mobile .all-service-orders-actions {
	.service-orders-search-and-filter {
		.search-light {
			width: 100%;
		}
	}
}
