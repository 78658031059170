.orders-list-header {
	height: 35px;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #ffffff;

	span {
		font-weight: bold;
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-display: swap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.orders-list-item {
		margin: 0px 10px 0px 0px;
		padding: 0px;
	}

	.orders-list-legend {
		width: 3%;
	}
	.orders-list-orders {
		width: 6%;
	}
	.orders-list-company-name {
		width: 23%;
	}
	.orders-list-category {
		width: 20%;
	}
	.orders-list-state {
		width: 3%;
	}
	.orders-list-issue-date {
		width: 6%;
	}
	.orders-list-delivery-date {
		width: 6%;
	}
	.orders-list-salesperson {
		width: 15%;
	}
	.orders-list-status {
		width: 8%;
	}
	.orders-list-follow-up {
		width: 9%;
	}
	.orders-list-more-options {
		width: 2%;
	}
}
