.header-section {
  gap: 20px;
  display: flex;

  .search-component {
    max-width: 408px;
  }
  button {
    margin-left: auto;
    max-width: 170px;
  }

  .legend-header {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    align-items: center;
    gap: 20px;
    .legend {
      display: flex;
      height: 12px;
      gap: 5px;
      text-wrap: nowrap;

      cursor: pointer;

      .active {
          font-weight: 600;
          text-decoration: underline;
      }
    }
  }

}