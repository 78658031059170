.transfers-details-finished-content {
	width: 550px;
	.transfers-client-info-content,
	.transfers-equip-info-content {
		margin-bottom: 25px;
		.transfers-info-header {
			margin-bottom: 15px;
			h6 {
				font-size: 20px;
				font-weight: 300;
				color: #222;
				margin: 0px;
			}
		}
	}

	.transfer-info-content-columns {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 25px;
		div {
			width: 50%;
			span {
				b {
					margin-right: 5px;
				}
			}
		}
	}

	.transfer-info-content-row {
		margin-bottom: 25px;
		span {
			text-align: left;
			b {
				margin-right: 5px;
			}
		}
	}
}

.device-mobile .transfers-details-finished-content {
	width: calc(100vw - 40px);
	.transfer-info-content-columns {
		flex-direction: column;
		div {
			width: 100%;
		}
	}
}
