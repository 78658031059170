div.closedOS.hidden {
	display: none;
}

.DropImage.shown {
	content: url('assets/image/up.png');
	padding-top: 13px;
}

.orderImage {
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.up {
	content: url('assets/image/arrow_upward-24px.svg');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.down {
	content: url('assets/image/arrow_downward-24px.svg');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(1%) !important;
}

.orderImage.disable {
	content: url('assets/image/arrow_downward-24px.svg');
	height: 20px;
	width: 20px;
	padding-left: 5px;
	filter: invert(70%);
}

.link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #000;
	font-size: 17px;
	text-decoration: none;
}

.text-block {
	color: #2f72cf;
	font-size: 14px;
	font-weight: 500;
	text-decoration: underline;
}

.device-mobile .text-block {
	margin-top: 0px;
	margin-right: 5px;
	text-align: center;
	align-self: center;
	display: block;
	color: #333;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	text-decoration: underline;
	cursor: pointer;
}

.image-icon {
	margin-right: 5px;
}
