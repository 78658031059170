.new-payment-section {
	display: grid;
	gap: 20px;

	label {
		margin: 0;
	}

	.new-order-payment-contain-edit-mode,
	.new-order-payment-contain-view-mode {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		.new-order-payment-contain-information {
			display: grid;

			.new-order-payment-contain-action {
				display: grid;
				grid-template-columns: 1.3fr 0.5fr;
				align-items: end;

				.new-order-installments {
					cursor: pointer;
					display: flex;
					align-items: center;

					label {
						cursor: pointer;
					}
				}
			}
		}
	}

	.new-order-payment-contain {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr 1fr;

		.new-order-payment-contain-information {
			display: grid;

			.new-order-payment-contain-taxes {
				padding: 10px;
				background: #f2f2f2 0% 0% no-repeat padding-box;
				box-shadow: 1px 1px 6px #00000080;
				border-radius: 10px;
				opacity: 1;
				height: 220px;

				span {
					font-size: 14px !important;
				}

				.taxes {
					display: grid;
					gap: 10px;
				}
			}

			.new-order-payment-contain-discount {
				padding: 10px;
				background: #f2f2f2 0% 0% no-repeat padding-box;
				box-shadow: 1px 1px 6px #00000080;
				border-radius: 10px;
				opacity: 1;
				height: 220px;
				position: relative;

				span {
					font-size: 14px !important;
				}

				.discounts {
					display: grid;
					gap: 10px;
					grid-template-columns: calc(100% - 10px);

					div {
						display: flex;
						flex-direction: column;
						gap: 10px;

						span {
							font-weight: 300;
						}
					}
				}

				a {
					cursor: pointer;
					font-size: 12px;
					display: block;
					text-decoration: underline;
					font-weight: bold;
					color: #004992;
					position: absolute;
					bottom: 10px;
				}
			}

			.new-order-payment-contain-total-and-freight {
				gap: 20px;
				display: flex;
				height: 220px;
				flex-direction: column;

				span {
					font-size: 14px !important;
					font-weight: 300;
				}

				.new-order-payment-contain-freight {
					padding: 10px;
					background: #f2f2f2 0% 0% no-repeat padding-box;
					box-shadow: 1px 1px 6px #00000080;
					border-radius: 10px;
					opacity: 1;
					height: 100%;
					max-height: 80px;
				}

				.new-order-payment-contain-total {
					opacity: 1;
					height: 100%;
					border-radius: 10px;
					box-shadow: 1px 1px 6px #00000080;
					background: #f2f2f2 0% 0% no-repeat padding-box;

					display: flex;
					color: #004687;
					align-items: center;
					flex-direction: column;
					align-content: space-evenly;
					justify-content: space-around;

					label {
						line-height: 17px;
					}

					h1 {
						font-size: 45px;
						margin: 0;
						line-height: 52px;

						span {
							font-size: 14px;
						}
					}

					span {
						line-height: 17px;
					}
				}
			}
		}
	}
}

.device-mobile .new-payment-section {
	.new-order-payment-contain-edit-mode,
	.new-order-payment-contain-view-mode {
		grid-template-columns: 1fr 1fr;
	}

	.new-order-payment-contain {
		grid-template-columns: 1fr;
	}
}
