.client-address-information {
	gap: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

	@media screen and (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media screen and (max-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 980px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 700px) {
		grid-template-columns: 1fr;
	}

	.client-address-information-contain-information {
		width: 100%;
		display: flex;
		max-width: 250px;
		white-space: nowrap;
		flex-direction: column;
		text-overflow: ellipsis;

		@media screen and (max-width: 700px) {
			max-width: 200px;
		}

		header {
			font-size: 12px;
			margin-bottom: 10px;
			justify-content: flex-start;
			font-weight: 600;
		}

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.client-address-information-contact-number {
		display: flex;
		flex-direction: row;

		.contact-number-ddd {
			width: 40px;
			margin-right: 10px;
		}
	}
}

.client-address-information-contain-action {
	top: 20px;
	width: 52px;
	right: 20px;
	display: flex;
	position: absolute;
	z-index: 2;
}

.client-address-billing-information {
	margin-top: 30px;

	.client-address-billing-header {
		display: flex;
		align-items: center;
		height: 17px;

		header {
			font-size: 14px;
			width: 210px;
			color: #025cb7;
			font-weight: 500;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		hr {
			width: calc(100% - 215px);
			border: 0px;
			border-top: 1px solid #025cb7;
			margin: 0;
			margin-left: 7px;
		}
	}

	.client-address-billing-information-container {
		margin-top: 21px;

		gap: 20px;
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

		@media screen and (max-width: 1500px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@media screen and (max-width: 1280px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media screen and (max-width: 980px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 700px) {
			grid-template-columns: 1fr;
		}

		.client-address-billing-information-contain-information {
			width: 100%;
			display: flex;
			margin: 0px 10px 0px 0px;
			flex-direction: column;
			white-space: nowrap;
			text-overflow: ellipsis;

			header {
				font-size: 12px;
				margin-bottom: 10px;
				justify-content: flex-start;
				font-weight: 600;
			}

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.client-address-billing-information-contain-action {
			width: 52px;
		}

		.client-address-billing-information-contact-number {
			display: flex;
			flex-direction: row;

			.contact-billing-number-ddd {
				width: 40px;
				margin-right: 10px;
			}
		}
	}
}

.device-mobile .client-address-information {
	.client-address-information-contact-number,
	.client-address-information-contain-information {
		max-width: 100%;

		.contact-number {
			width: 100%;
		}
	}
}
