.transfer-edit-form-content {
	.transfer-edit-equip-content,
	.transfer-edit-client-content {
		width: 500px;
		position: relative;
		.transfer-client-form {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
		}
		.edit-equip-info,
		.edit-client-info {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			span {
				width: 100%;
				font-size: 13px;
				color: #222;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				b {
					margin-right: 5px;
				}
			}
		}
		.edit-equip-info-warning,
		.edit-client-info-warning {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 100px;
			.MuiSvgIcon-root {
				width: 40px;
				height: 40px;
				margin: 10px 0;
				opacity: 0.7;
			}
			h5 {
				font-size: 14px;
				color: #222;
				margin: 0px;
			}
		}
		.edit-equip-footer,
		.edit-client-footer {
			margin-top: 15px;
			display: flex;
			justify-content: space-between;
			button {
				margin: 10px 0 0 !important;
			}
			.custom-button-secondary {
				width: 100px;
				text-transform: capitalize;
				padding: 0px;
			}
		}

		.edit-equip-footer {
			justify-content: flex-end !important;
		}
		.loading-main-content {
			width: 100%;
			height: 100px;
			position: relative;
			.loading-main span {
				margin-top: 20px;
			}
		}
	}
	.transfer-edit-equip-content {
		.transfer-equip-form {
			margin-bottom: 15px;
			div {
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
				.form-item-content {
					height: auto;
					width: calc(100% - 60px);
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
				}
			}
		}
		.loading-main-content {
			height: 120px;
		}
	}
	.transfer-contact-form {
		.main-form-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: flex-end;
			.form-item-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				div {
					width: calc(50% - 10px) !important;
				}
			}
			.form-item-content {
				width: 100%;
			}
		}

		button {
			margin: 10px 0 0 !important;
		}
	}
}

.device-mobile .transfer-edit-form-content {
	.transfer-edit-equip-content,
	.transfer-edit-client-content {
		width: calc(100vw - 40px);

		.edit-equip-footer,
		.edit-client-footer {
			bottom: 70px;
			display: flex;
			margin-top: 0px;
			position: fixed;
			column-gap: 20px;
			justify-content: center;
			width: calc(100vw - 40px);

			.custom-button-secondary {
				width: 120px;
			}
		}
	}
}
