.list-filter-item {
	display: flex;
	overflow-x: hidden;
	column-gap: 5px;
	align-items: center;
	justify-content: flex-start;

	.list-filter-item-checkbox {
		margin-right: 5px;
	}

	.list-filter-item-label {
		margin: 0px;
		padding: 0px;
		font-size: 12px;
		font-weight: 400;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-transform: lowercase;
		&::first-letter {
			text-transform: uppercase;
		}
	}
}
