.modal.user-config {
	display: flex;
	justify-content: center;
	align-items: center;
}

.nav-user-info {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
	font-size: 16px;
	color: #025CB7;
	font-weight: bold;
	max-width: 120px;
}

.user-area {
	display: flex;
	position: absolute;
	top: 17.5px;
	right: 20px;
	cursor: pointer;
	z-index: 35;
	align-items: center;
}

.user-area > .MuiSvgIcon-root {
	width: 24px !important;
	height: 24px !important;
	fill: #025CB7 !important
}
