.textarea-install-and-search {
  display: block;
  width: 100%;
  height: 120px;
  resize: none;
  border: none;
  border-radius: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #6c757d;
  background-color: #EDEEF0;
}