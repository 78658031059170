.transfers-create-content {
	width: 600px;
	height: auto;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	.transfer-contact-content,
	.transfer-client-content,
	.transfer-equip-content {
		width: 100%;
		.transfer-contact-header,
		.transfer-client-header,
		.transfer-equip-header {
			h4 {
				font-size: 20px;
				font-weight: 400;
				color: #222;
				margin: 0 px;
			}
			p {
				margin: 10px 0 15px;
				font-size: 14px;
				font-weight: 300;
				color: #222;
			}
		}
		.main-form-content {
			width: 100%;
			display: flex;
			flex-direction: column;
			.transfer-equip-form {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				flex-direction: row;
				height: 34px;
				margin-bottom: 15px;
				align-items: center;
				column-gap: 20px;

				.form-item-content {
					width: 100%;
					height: 34px;
				}

				button {
					width: 120px;
				}
			}
			.transfer-contact-form {
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				.form-item-content {
					width: 100%;
					height: 85px;
				}
				button {
					margin: 0px;
				}
			}
			.form-item-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				div {
					width: calc(50% - 10px) !important;
				}
			}
		}
		.transfer-client-info-warning,
		.transfer-equip-info-warning {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			width: 100%;
		}
		.transfer-client-info,
		.transfer-client-info-warning,
		.transfer-equip-info,
		.transfer-equip-info-warning,
		.loading-main-content {
			height: 121px;
		}
		.loading-main-content {
			width: 100%;
			position: relative;
			.loading-main span {
				margin-top: 20px;
			}
		}
		.transfer-client-footer,
		.transfer-equip-footer {
			display: flex;
			justify-content: flex-end;
			button {
				margin: 0px;
			}
		}
	}
	.transfer-summary-content {
		.transfer-summary-client {
			margin-bottom: 15px;
		}
		.transfer-summary-client,
		.transfer-summary-equipment {
			padding: 10px 0;
			.transfer-summary-header {
				h6 {
					font-size: 20px;
					font-weight: 300;
					color: #222;
					margin: 0px 0px 15px;
				}
			}
		}
		.transfer-summary-footer {
			margin-top: 8px;
			display: flex;
			justify-content: flex-end;
			button {
				margin: 0px;
			}
		}
	}
	.transfer-summary-content-error {
		height: 285px;
		display: flex;
		align-items: center;
		justify-content: center;
		.transfer-summary-content-error-message {
			text-align: center;
			padding: 15px;
			h3 {
				font-size: 26px;
				font-weight: 400;
				color: #222;
				margin: 0px 0px 25px;
			}
			p {
				font-size: 14px;
				font-weight: 300;
				color: #222;
				margin: 0px 0px 15px;
			}
		}
	}

	.loading-main-content {
		width: 350px;
		height: 100px;
		background-color: #fff;
		.loading-main span {
			margin-top: 20px;
		}
	}
}

.device-mobile .transfers-create-content {
	width: calc(100vw - 40px);
}
