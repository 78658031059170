.order-menu-tabs-switch {
	top: 20px;
	z-index: 1;
	display: flex;
	position: absolute;
	align-items: center;

	.action-bt-back {
		margin-right: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		span {
			font-size: 12px;
			text-align: left;
			color: #025cb7;
			line-height: 20px;
			text-transform: uppercase;
		}
	}

	.order-menu-tabs {
		width: 126px;
		margin-right: 10px;
	}
	.order-menu-tabs-larger {
		width: 170px;
		margin-right: 10px;
	}

	.order-menu-tabs-block {
		button {
			cursor: not-allowed;
		}
	}
}
