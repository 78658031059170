.address-form-container {
	.address-content {
		width: 100%;
		display: flex;
		.address-itens-1 {
			display: grid;
			grid-template-columns: 190px 190px 130px 190px;
			gap: 20px;
			margin-bottom: 20px;
			.address-information-content {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin: 0px 10px 0px 0px;
				header {
					line-height: 14px;
					font-size: 12px;
					margin-bottom: 10px;
					justify-content: flex-start;
					font-weight: 600;
				}
			}
		}
		.address-itens-2 {
			display: grid;
			gap: 20px;
			grid-template-columns: 530px 60px 130px;
			.address-information-content {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin: 0px 10px 0px 0px;
				header {
					line-height: 14px;
					font-size: 12px;
					margin-bottom: 10px;
					justify-content: flex-start;
					font-weight: 600;
				}
			}
		}
	}

	.address-default {
		margin-top: 12px;
		span {
			font-size: 12px;
			padding: 0;
		}
	}
}

.device-mobile .address-form-container {
	width: 100%;

	.address-content {
		.address-itens-1,
		.address-itens-2 {
			width: 100%;
			grid-template-columns: 1fr;
		}
	}
}
