@font-face {
    font-family: 'MontserratLight';
    src: url('../../Fonts/Montserrat-Light.ttf');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../Fonts/Montserrat-Bold.ttf');
}
@font-face {
    font-family: 'MontserratMedium';
    src: url('../../Fonts/Montserrat-Medium.ttf');
}

.template-card-background {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);

    .template-card {
        width: 670px;
        display: flex;
        height: 454px;
        border: 15px solid #004687;
        background: #f8f9fa;
        position: fixed;
        z-index: 100;
        color: #4E4E4E;
        margin: 0 -10px;
        label {
            font-family: 'MontserratLight' !important;
        }
        span {
            font-family: 'MontserratMedium' !important;
        }

        .template-card-content {
            height: 425px;
            width: 70%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .template-card-header {
                width: 90%;
                label {
                    font-size: 22px;
                    margin-bottom: 0 !important;
                    font-family: 'MontserratBold' !important;
                    font-weight: bold;
                    line-height: 24px;
                }
            }

            .template-card-text {
                label{
                    font-size: 10px;
                    font-weight: 700;
                    margin-bottom: 0 !important;
                }

                .template-card-span-name {
                    display: inline-flex;
                    border-bottom: 0.5px solid #4E4E4E;
                    width: 200px;
                    justify-content: center;
                    font-size: 11px;
                    line-height: 12px;
                }

                .template-card-name-bottom {
                    border-bottom: 0.5px solid #4E4E4E;
                    display: inline-flex;
                    width: 200px;
                    justify-content: center;
                    font-size: 11px;
                    line-height: 12px;
                }

                .template-card-date {
                    border-bottom: 0.5px solid #4E4E4E;
                    display: inline-flex;
                    justify-content: center;
                    width: 25px;
                    line-height: 12px;
                }
            }

            .template-card-text-content {
                position: relative;
                label{
                    font-size: 10px;
                }
                span {
                    font-size: 10px !important;
                }

                .template-card-content-text-line {
                    padding-left: 10px;
                    position: absolute;
                    line-height: 19px;
                    top: 0;
                    width: 410px;
                    overflow-wrap: break-word;
                    font-family: 'MontserratBold' !important;
                }

                .template-card-content-base-lines {
                    height: 16px;
                    top: 0;
                    display: inline-block;
                    width: 100%;
                    border-bottom: 1px solid #4E4E4E;
                    word-wrap: break-word;
                }
            }

            .template-card-checkboxes {
                justify-content: space-between;
                display: grid;
                grid-template-columns: auto auto;
                gap: 5px;
                padding-right: 60px;

                .template-card-checkbox {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    label {
                        font-size: 9px;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .template-card-icons {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .template-card-icon-qsl {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }

            .template-card-icon-pratica {
                margin-top: auto;
                display: flex;
                align-items: end;
                justify-content: center;
                margin-bottom: 20px;
            }
        }
    }
}