.order-management-card-title {
	display: flex;
	justify-content: space-between;

	h4 {
		font-size: 20px;
		width: 80%;
		color: #004687;
	}
}

.order-management-card-main-contain {
	height: 100%;
	margin-bottom: 20px;

	.summary2 {
		height: 83px;
		padding: 10px;
		font-size: 14px;
		border: 1px solid;
		border-radius: 6px;
		margin-bottom: 20px;
		border-color: #d0dff0;
		background-color: #f8f9fa;

		.last-service {
			font-size: 13px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.order-management-card-actions {
	display: none;
	// display: flex; será ativado quando as funçõe de novo cliente e novo pedido estiverem implementadas
	justify-content: center;

	button {
		width: 245px;
		font-size: 12px;
		margin-right: 20px;
		text-transform: capitalize;
	}
	button:last-child {
		margin-right: 0px;
	}
}
