.orders-actions {
	display: flex;
	margin: 0px 20px 20px;
	justify-content: space-between;

	.orders-search-and-filter {
		width: 460px;
		display: flex;

		img {
			width: 50px;
			cursor: pointer;
			margin-left: 10px;
		}
	}

	.orders-legends {
		display: flex;
		align-items: center;
		justify-content: space-between;

		span {
			overflow: hidden;
			white-space: nowrap;
			padding-right: 20px;
			text-overflow: ellipsis;

			img {
				height: 30px;
				width: 20px;
			}
		}
		span:last-child {
			padding-right: 0px;
		}
	}

	@media only screen and (max-device-width: 580px) {
		.orders-legends {
			display: flex;
			flex-direction: column;

			span {
				font-size: 10px;
				padding-right: 0px;

				img {
					height: 20px;
					width: 14px;
				}
			}
		}
	}

	@media only screen and (max-device-width: 440px) {
		.orders-search-and-filter {
			width: 240px;

			img {
				width: 30px;
				margin-left: 0px;
			}
		}
	}

	@media only screen and (max-device-width: 320px) {
		.orders-legends {
			display: none;
		}
	}

	.orders-bt-new-order {
		width: auto;
	}
}
