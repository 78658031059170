.product-family-list {
    height: 100dvh;

    .product-family-list-table {
        margin: 0 -20px;
        height: calc(100dvh - 200px);
    }

    .product-family-paginator {
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {
    .product-family-list {
        .product-family-list-table {
            margin-top: 20px !important;
            height: calc((100dvh - 230px));
        }
    }
}