.m-tooltip {
  max-width: 288px;
  border-radius: 5px;
  background-color: #fafafa;
  position: absolute;
  z-index: 1;
  top: -50px;
  right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 6px 6px 3px -2px rgba(0, 0, 0, 0.2);

  span {
    position: absolute;
    right: 7px;
    opacity: 0.3;
    cursor: pointer;
  }

  p {
    font-size: 12px;
    line-height: normal;
    text-align: center;
    color: #7d7d7d;
    padding: 20px;
  }
}