.history-filter-fields {
	display: flex;
	flex-direction: column;

	.filter-fields-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 20px;
		.filter-fields-section-option {
			width: 100%;
			margin-bottom: 20px;
			label {
				font-size: 13px;
				font-weight: bold;
				margin: 0;
			}
			.history-billing-select {
				margin-top: 5px;
				display: flex;
				justify-content: space-between;
				label {
					display: flex;
					span {
						font-weight: normal;
						margin: 0;
						margin-left: 4px;
						line-height: 14px;
					}
				}
				select {
					margin-top: 10px;
				}
			}
		}
	}
}
