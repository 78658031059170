.order-pendency-tab {
	height: 100%;
	.order-pendency-tab-header {
		display: flex;
		margin-bottom: 20px;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.order-pendency-tab-header-actions {
			width: 152px;
		}
	}

	.order-pendency-tab-main-contain {
		margin: 0px -20px;
		overflow-y: overlay;
		height: calc(100% - 290px);
	}

	.order-pendency-tab-footer {
		display: flex;
		flex-direction: column;
		span {
			margin-bottom: 8px;
		}

		.order-pendency-tab-footer-options {
			display: flex;
			flex-direction: row;
			margin-bottom: 30px;

			.order-pendency-tab-footer-options-option {
				height: 40px;
				display: flex;
				margin-right: 80px;
				align-items: center;

				input {
					margin-right: 5px;
					border-radius: 100%;
				}

				span {
					margin-bottom: 0px;
				}
			}

			.order-pendency-tab-footer-options-option:last-child {
				margin-right: 0px;
			}
		}
		.order-pendency-tab-footer-actions {
			width: 100%;
			display: flex;
			justify-content: center;

			.order-pendency-tab-footer-actions-button {
				width: 108px;
			}
		}
	}
}

.device-mobile .order-pendency-tab {
	height: calc(100% - 74px);

	.order-pendency-tab-header {
		display: flex;
		margin-bottom: 20px;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.order-pendency-tab-header-actions {
			width: 126px;
			button {
				font-size: 12px;
			}
		}
	}

	.order-pendency-tab-main-contain {
		display: flex;
		flex-direction: column;
		margin: 0px -20px;
		overflow-y: overlay;
		height: calc(100% - 60px);
	}

	.order-pendency-tab-footer {
		margin-top: 10px;
		.order-pendency-tab-footer-options {
			width: 100%;
			justify-content: space-between;

			.order-pendency-tab-footer-options-option {
				margin-right: 0px;
				height: 20px;
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	.order-pendency-tab {
		.order-pendency-tab-header {
			display: none;
		}
	}
}
