.upload-file-root {
    display: flex;
    flex-direction: column;

    .upload-file-main-content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;

        .upload-file-action {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            background: transparent;
            height: 14px;
            padding: 0px;
            color: var(--text-primary-pure);
            cursor: pointer;
            gap: 5px;

            svg {
                width: 9px;
                height: 14px;

                path {
                    fill: var(--text-primary-pure);
                }
            }

            span {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.18px;
            }
        }

        span {
            font-size: 10px;
            font-weight: 400;
        }

        .upload-file-action-input {
            display: none;
        }
    }
}

.modal-upload-files {
    width: 460px;
    height: 510px;
    display: flex;
    gap: 20px;
    flex-direction: column;

    header {
        font-size: 13px;
    }

    .upload-file-container {
        gap: 20px;
        height: 100%;
        display: flex;
        overflow: overlay;
        padding: 10px 0px;
        flex-direction: column;

        .preview-files-empty {
            display: flex;
            min-width: 60px;
            height: 60px;
            width: 60px;
            background-color: #f2f2f2;
            border: 1px #707070 dashed;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            div {
                font-size: 50px;
                font-weight: 100;
                color: #707070;
            }
        }

        .preview-file-container {
            gap: 20px;
            display: grid;
            align-items: center;

            grid-template-columns: 60px 1fr;
            &.has-category {
                grid-template-columns: 60px 1fr 120px;
            }

            .preview-files {
                display: flex;
                min-width: 60px;
                height: 60px;
                width: 60px;
                border-radius: 6px;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;

                #file-preview {
                    height: 60px;
                    width: 60px;
                    object-fit: cover;
                    border-radius: 6px;
                }

                .delete-file-action {
                    top: -10px;
                    right: -10px;
                    position: absolute;
                }

                progress {
                    position: absolute;
                }
            }
        }
    }
}

.upload-files-actions {
    display: flex;
    gap: 20px;
    justify-content: center;

    button {
        width: 108px;
    }
}

.upload-file-list {
    width: 240px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: overlay;

    .upload-file-list-item {
        background: var(--primary-lightest);
        cursor: pointer;
        min-width: 60px;
        height: 16px;
        margin-right: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        a {
            display: flex;
            justify-content: center;
            color: var(--primary-highlight);
            font-size: 10px;
            font-weight: 400;
            width: 100%;
        }

        .upload-file-list-item-remove {
            position: absolute;
            width: 10px;
            height: 10px;
            top: -3px;
            right: -3px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: white;

            svg {
                width: 10px;
                height: 10px;
            }

            .MuiSvgIcon-root {
                color: var(--text-high-pure);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .upload-file-main-content {
        flex-direction: column;
    }
}
