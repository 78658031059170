.transfers-form-content {
	width: 100%;
	.form-row-content {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		.MuiFormControl-root {
			width: calc(100% - 20px);
		}
		.MuiTextField-root {
			width: calc(50% - 10px);
		}
	}
	.transfer-confirm-action {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		justify-content: flex-start;
		.MuiFormControl-root {
			width: 100%;
		}
		.transfer-confirm-action-text {
			width: calc(100% - 50px);
			margin-left: 25px;
			display: flex;
			justify-content: flex-start;
			div {
				width: 50%;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				span {
					width: calc(100% - 15px);
					font-size: 13px;
					color: #222;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					b {
						margin-right: 5px;
					}
				}
			}
		}
	}
	.transfer-confirm-action:first-child {
		margin-bottom: 16px;
	}
	.transfers-info-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 30px;
		button {
			margin: 0px;
		}
	}
}

.device-mobile .transfers-form-content {
	.transfer-confirm-action {
		.transfer-confirm-action-text {
			flex-direction: column;
			width: calc(100vw - 40px);
			div {
				width: calc(100vw - 90px);
			}
		}
	}
	.transfers-info-footer {
		display: flex;
		justify-content: center;
		bottom: 70px;
		margin-top: 30px;
		position: fixed;
		width: calc(100vw - 40px);
	}
}
