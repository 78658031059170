.modal-open-os-body {
  margin: 0;

  .addressDefault {
    align-items: center;
    display: flex;
    padding-left: 10px;

    span {
      font-size: 12px;
      padding-left: 4px;
    }
  }

  .container-address-confirm-actions {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;

    button {
      width: 190px;
      border-radius: 1px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .obs-Characters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.form-label-title {
  margin: 0;
  color: #194883;

  .container-f {
    gap: 5px;
    width: 100%;
    padding: 20px;
    display: flex;
    margin: auto;
    padding: 20px;
    border-radius: 2px;
    border-style: solid;
    border-width: 0.5px;
    background: #ffffff;
    border-image-slice: 1;
    background-origin: border-box;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-image-source: linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04));
    background-image: #ffffff,
      linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04));

    .item-f {
      margin: 5px;
      color: #194883;
    }

    .text-align-l {
      text-align: left;
    }
  }

}

.InputMask {
  display: block;
  width: 100%;
  height: initial;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c757d;
  height: 40px;
  padding-left: 10px !important;
  background-color: #edeef0;
}

.title-modal-install {
  font-weight: 300;
  text-align: left;
  color: #194883;

  .equipName {
    font-size: 20px;
    line-height: 36px;
    font-weight: 700;
  }

  .serialNum {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
  }
}