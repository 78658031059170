.order-invoices-container {
  width: 500px;
  height: 300px;
  margin: 0px -20px;
}

.device-mobile .order-invoices-container {
  width: auto;
  height: 100%;
}
