.transfer-list-item {
	display: flex;
	cursor: pointer;
	padding: 5px 10px;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid rgba(0, 0, 0, 0.2);

	.transfer-info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;
		position: relative;
		float: left;
		min-height: 1px;

		line-height: 20px;

		div {
			text-align: left;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding: 0px 10px;

			span {
				line-height: 20px;
				font-size: 13px;
				text-align: left;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.options {
			padding: 0px !important;

			.MuiButtonBase-root.MuiButton-root {
				min-width: 30px !important;
				padding: 0px;
			}

			.menu-box-item {
				z-index: 100;
				top: 0;
			}

			.menu-item {
				margin: 0px;
				color: rgba(51, 51, 51, 0.84);
				font-size: 14px;
				line-height: 12px;
				text-align: center;
			}

			.menu-item:hover {
				background-color: rgba(0, 0, 0, 0.19);
			}
		}
	}
}
