.client-attachments-tab {
	margin: 0px -30px 0px -20px;
	height: calc(100vh - 162px);

	.client-attachments-header {
		display: flex;
		justify-content: space-between;
		padding: 0 20px;

		button {
			width: 150px;
			margin-right: 10px;
		}
	}

	.client-attachments-search {
		width: 385px;
		height: 40px;
		margin: 10px 20px 0 20px;
	}

	.client-attachments-table {
		height: calc(100% - 105px);
		position: relative;
	}

	.moreOptions {
		padding: 0px;

		.attachment-more-option {
			width: 10px;
			position: relative;
			bottom: 0px;
		}
	}

}

.device-mobile .client-attachments-tab {
	height: calc(100vh - 154px);

	.client-attachment-scope {
		display: flex;
		justify-content: space-between;
		height: 40px;
		margin: 20px;

		.client-attachments-search {
			width: 57%;
			margin: 0px;
		}

		button {
			width: 35%;
			margin-right: 10px;
			font-size: 12px !important;
		}
	}
}