.device-mobile .order-menu-tabs {
	display: flex;
	justify-content: flex-end;

	.order-menu-button {
		width: 100%;
		height: 40px;
		display: flex;
		margin-bottom: 10px;
		justify-content: space-between;
		transition: transform 0.3s ease;

		img {
			z-index: 3;
			width: 40px;
			height: 40px;
			padding: 5px;
			border-radius: 15%;
			background: var(--primary-pure);
		}
	}

	.order-menu-container {
		z-index: -1;
		display: flex;
		position: absolute;
		justify-content: flex-end;
	}

	.order-menu-sidebar {
		display: flex;
		padding: 10px;
		width: 200px;
		margin-top: 44px;
		height: fit-content;
		border-radius: 10px;
		flex-direction: column;
		transform: translateX(+300%);
		transition: transform 0.3s ease;
		background-color: var(--high-pure);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

		.order-menu-options {
			width: 100%;
			height: 40px;
			display: flex;
			cursor: pointer;
			align-items: center;
		}

		.order-menu-option-selected {
			font-weight: 600;
			text-decoration: underline;
			color: var(--primary-pure) !important;
		}
	}

	.order-menu-container.open .order-menu-sidebar {
		transform: translateX(0);
	}

	.order-menu-container.open {
		width: 100%;
		height: 100%;
		backdrop-filter: blur(5px);
		-webkit-backdrop-filter: blur(5px);
		z-index: 2;
	}
}