.products-list {
    height: 100dvh;

    .products-list-table {
        margin: 0 -20px;
        height: calc(100dvh - 200px);
    }

    .products-list-paginator {
        padding: 20px;
    }
}

@media screen and (max-width: 480px) {
    .products-list {
        .products-list-table {
            margin-top: 20px !important;
            height: calc((100dvh - 230px));
        }
    }
}