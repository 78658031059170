.client-accounting-information {
	gap: 20px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

	@media screen and (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	@media screen and (max-width: 1280px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 980px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 700px) {
		grid-template-columns: 1fr;
	}

	.client-accounting-information-contain-information {
		width: 100%;
		display: flex;
		max-width: 250px;
		margin: 0px 10px 0px 0px;
		flex-direction: column;

		header {
			font-size: 12px;
			margin-bottom: 10px;
			justify-content: flex-start;
			font-weight: 600;
		}

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.client-accounting-information-contain-action {
		display: flex;
		width: 52px;
		position: absolute;
		right: 20px;
		z-index: 2;
	}
}


.device-mobile .client-accounting-information {

	.client-accounting-information-contain-information {
		max-width: 100%;

		.contact-number {
			width: 100%;
		}
	}
}