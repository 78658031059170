.new-order-shipping-details-information {
	position: relative;
	gap: 20px;
	width: 100%;
	display: grid;

	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	.new-order-shipping-details-information-contain-action {
		grid-column-start: 1;
		grid-column-end: 6;
	}

	@media screen and (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		.new-order-shipping-details-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 5;
		}
	}

	@media screen and (max-width: 1100px) {
		grid-template-columns: 1fr 1fr 1fr;
		.new-order-shipping-details-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 4;
		}
	}

	@media screen and (max-width: 840px) {
		grid-template-columns: 1fr 1fr;
		.new-order-shipping-details-information-contain-action {
			grid-column-start: 1;
			grid-column-end: 3;
		}
	}

	@media screen and (max-width: 580px) {
		grid-template-columns: 1fr;
	}

	.new-order-shipping-details-information-contain-information {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0px 10px 0px 0px;

		@media screen and (min-width: 1000px) {
			span {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		header {
			font-size: 12px;
			margin-bottom: 10px;
			justify-content: flex-start;
			font-weight: 600;
		}
	}
	.new-order-shipping-details-information {
		position: relative;
		gap: 20px;
		width: 100%;
		display: grid;

		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

		@media screen and (max-width: 1500px) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@media screen and (max-width: 1100px) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media screen and (max-width: 840px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 580px) {
			grid-template-columns: 1fr;
		}

		.new-order-shipping-details-information-contain-information {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin: 0px 10px 0px 0px;

			@media screen and (min-width: 1000px) {
				span {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			header {
				font-size: 12px;
				margin-bottom: 10px;
				justify-content: flex-start;
				font-weight: 600;
			}
		}
	}
	.new-order-shipping-details-information-contain-action {
		width: 100%;
		display: grid;
		grid-template-columns: 128px 128px;
		justify-content: center;
		gap: 20px;
	}
}
