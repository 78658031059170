.device-mobile .clients {
	margin: 0px -20px;
	height: 100%;
	overflow: hidden;

	.clients-table {
		width: 100%;
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
		height: calc(100% - 168px);
		margin-bottom: 20px;
	}
}
