.modal-user-jac {
  width: 600px;
  height: 100%;

  .labels-row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  .labels-row-actions {
    margin-top: 20px;

    .form-field-checkbox-items {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.action {
  gap: 20px;
  display: grid;
  justify-content: center;
  grid-template-columns: 120px 120px;
}

.device-mobile {
  .modal-user-jac {
    width: 100%;

    .labels-row {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr;
    }

    .form-field-checkbox-items {
      display: grid;
      gap: 0px;
      grid-template-columns: 1fr;
    }
  }
}