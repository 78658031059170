.items-list-container {
	width: 1000px;
	margin: 0 -20px;
	height: 470px;
	position: relative;

	.items-input-container {
		margin: 0 20px 20px;

		div {
			width: 300px;
		}
	}

	.items-header-list {
		display: flex;

		.items-list-item {
			padding: 0;
			margin: 0 10px 0 0;

			&:last-child {
				margin: 0;
			}

			span {
				font-weight: bold;
				opacity: 1;
				font-size: 0.75rem;
				text-align: left;
				color: #333333;
				font-display: swap;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.items-list {
			display: flex;
			padding: 0;
			margin: 0 20px 0;
			width: 100%;

			.items-list-code {
				width: 115px;
				min-width: 115px;
			}

			.items-list-description {
				width: 100%;
			}

			.items-list-table {
				width: 50px;
				min-width: 50px;
			}

			.items-list-value {
				width: 130px;
				min-width: 130px;
			}
		}
	}

	.items-body-list {
		height: calc(100% - 70px);
		overflow: overlay;
		overflow-x: hidden;

		.items-body-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 30px;
			background-color: #f9f8fa;
			width: 1000px;

			.items-list-item {
				display: flex;
				padding: 0;
				margin: 0 10px 0 0;

				&:last-child {
					margin: 0;
				}

				span {
					font-weight: 400;
					opacity: 1;
					font-size: 0.75rem;
					text-align: left;
					color: #333333;
					font-display: swap;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.items-list {
				margin: 0 20px 0;
				display: flex;
				padding: 0;
				width: 100%;

				.items-list-code {
					width: 115px;
					min-width: 115px;
					display: grid;
					align-items: center;
					grid-template-columns: 20px 90px;

					.checkbox-zero-height {
						margin-right: 5px;
					}
				}

				.items-list-description {
					width: 100%;
				}

				.items-list-table {
					width: 50px;
					min-width: 50px;
				}

				.items-list-value {
					width: 130px;
					min-width: 130px;
				}
			}

			&:hover {
				background-color: #d0dff0;
			}
		}
	}
}

.items-search-action {
	display: grid;
	gap: 20px;
	grid-template-columns: 118px 118px;
	justify-content: center;
	width: 100%;
}

.device-mobile .items-list-container {
	display: grid;
	grid-template-rows: 50px 1fr 60px;
	width: 100%;
	margin: 0px;
	height: 100%;

	.items-input-container {
		margin: 0px 0px 10px;

		div {
			width: 100%;
		}
	}

	.items-body-list {
		margin: 0px -20px;
		max-height: initial;
		height: 100%;

		.items-body-item {
			height: auto;
			padding: 10px;
			gap: 10px;
			width: 100%;

			.container-checkbox {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: auto 0;
				width: 20px;
			}

			.items-list {
				width: 100%;
				margin: 0px;
				display: flex;
				overflow: hidden;
				flex-direction: column;

				.items-list-item {
					width: auto;
					font-size: 0.75rem;
					font-weight: 400;
					grid-template-columns: 1fr;


					span {
						display: flex;
						font-weight: 400;
						text-align: left;
						color: #333333;
						white-space: normal;
					}

					.items-details-list-title {
						display: flex;
						font-weight: 700;
						font-size: 0.75rem;

						span {
							margin-left: 5px;
						}

						p {
							font-weight: 400;
						}
					}
				}

				.items-list-item :first-child {
					font-weight: 700;
					margin-right: 5px;
				}

				.items-details-list-company-name {
					width: 100%;
					display: flex;
					overflow: hidden;
					flex-direction: column;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.items-body-item:nth-child(odd) {
		background-color: #e5e5e5;
	}
}