.billets-modal {
	width: 700px;

	.billets-position-section {
		gap: 20px;
		display: flex;
		flex-direction: column;

		.billets-position-section-row {
			gap: 20px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:first-child {
				width: 90%;
			}

			.billets-position-section-contain-information {
				header {
					font-size: 14px;
					font-weight: 600;
					margin-bottom: 10px;
					justify-content: flex-start;
				}

				span {
					font-size: 12px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.billets-position-section-contain-input {
				width: 80%;
			}

			.billets-position-section-contain-button {
				min-width: 16%;
				white-space: nowrap !important;

				button {
					display: flex;
					gap: 10px;

					&:disabled {
						cursor: not-allowed !important;
					}
				}
			}
		}

	}

	.legend-header {
		gap: 20px;
		display: flex;
		font-size: 12px;
		font-weight: 500;
		user-select: none;
		align-items: center;
		padding: 20px 0px 0px;
		justify-content: space-between;

		.legend {
			gap: 5px;
			height: 100%;
			display: flex;
			text-wrap: nowrap;
			align-items: center;

			.active {
				font-weight: 600;
				text-decoration: underline;
			}
		}
	}

	.billets-position-section-table {
		height: 300px;
		margin-top: 20px;
		margin-inline: -20px;
	}
}

.device-mobile .billets-modal {
	width: 100%;
	height: 100%;

	.billets-position-section .billets-position-section-row:first-child {
		width: 100%;
		flex-direction: column;

		.billets-position-section-contain-information {
			width: 100%;
			display: flex;
			flex-direction: column;

			span {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}

	.billets-position-section-table {
		height: calc(100% - 235px);
	}
}