.installments-container {
	width: 610px;
	height: 410px;
	position: relative;

	.installments-details-header {
		height: 64px;
		margin: 0 0 10px;

		.installments-header-contain {
			display: flex;
			flex-direction: column;
			gap: 10px;

			span {
				font-size: 13px;
				color: #333333;
				line-height: 14px;
			}

			.installments-details-header-contain {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				div {
					width: 150px;
				}

				.compare-price {
					display: flex;
					width: 450px;
					height: 20px;

					div {
						display: flex;
						justify-content: flex-end;
						width: 250px;

						label {
							margin: 0;
							font-size: 13px;
							margin-right: 5px;
						}

						img {
							width: 30px !important;
							height: 20px;
						}
					}
				}
			}
		}
	}

	.installments-details-body {
		gap: 10px;
		height: 280px;
		display: grid;
		overflow: overlay;
		grid-template-columns: 300px 300px;

		.installments-details-item {
			width: 300px;
			height: 118px;
			padding: 10px;
			border-radius: 5px;
			border: 1px solid #c8c8c8;

			h5 {
				font-size: 14px;
				font-weight: bold;
				color: #004992;
				margin: 0 0 16px 0;
			}

			.installments-details-item-values {
				display: grid;
				grid-template-columns: 135px 135px;
				gap: 10px;

				div {
					span {
						font-size: 12px;
						color: #333333;
						font-weight: bold;
					}
				}

				.date-container {
					.MuiFormControl-root.MuiTextField-root {
						.MuiInputBase-input {
							padding-left: 10px;
							color: #a6a6a6;
						}

						.date-picker-custom {
							padding-left: 10px;
						}
					}

					.MuiFormControl-root.MuiTextField-root:first-child {
						margin-right: 20px;
					}

					.MuiFormLabel-root {
						transform: translate(0, 1.5px) scale(1);
						font-weight: 400;
						font-size: 12px;
						opacity: 1;
					}

					.MuiInputBase-root {
						background-color: #f2f2f2;
						border-radius: 6px;
						font-size: 12px;
						height: 40px;
					}

					.MuiInput-underline:hover,
					.MuiInput-underline:hover:not(.Mui-disabled):before,
					.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
					.MuiInput-underline::before,
					.MuiInput-underline::after {
						border: none;
						outline: none !important;
						content: "" !important;
						box-shadow: none !important;
					}

					.MuiIconButton-root {
						padding: 8px;
					}

					.MuiFormHelperText-root.Mui-error {
						font-size: 11px;
					}

					.MuiSvgIcon-root {
						color: #025cb7;
						width: 1em !important;
						height: 1em !important;
					}
				}
			}
		}
	}
}

.installments-details-action {
	display: grid;
	grid-template-columns: 108px 108px;
	gap: 20px;
	justify-content: center;
	width: 100%;
}

.installments-container.viewing {
	.installments-details-body {
		height: 354px;
	}
}

.device-mobile .installments-container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 137.5px auto 40px;

	.installments-details-header {
		height: auto;

		.installments-details-header-contain {
			gap: 10px;
			flex-direction: column;
			align-items: normal;
			justify-content: normal;

			.compare-price {
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				height: auto;

				div {
					flex-direction: column;
					width: 100%;
				}
			}
		}
	}

	.installments-details-body.viewing,
	.installments-details-body {
		gap: 10px;
		display: flex;
		padding-bottom: 20px;
		flex-direction: column;
		height: calc(100% - 20px);

		.installments-details-item {
			width: 100%;

			.installments-details-item-values {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
}

.device-mobile .installments-details-action {
	position: relative;
}

.device-mobile .installments-container.viewing {
	grid-template-rows: auto;

	.installments-details-body {
		height: 100%;
	}
}