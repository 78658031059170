.order-titles {
	display: flex;
	flex-direction: column;

	.client-titles-div,
	.order-titles-div {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title-client {
			color: #1e4883;
			display: flex;
			line-height: 40px;
			font-size: 20px;
			overflow: hidden;
			text-align: left;
			font-weight: bold;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin: 0px 20px 0px 0px;
			text-transform: uppercase;
		}

		.title-block-info {
			width: auto;
			display: flex;
			gap: 5px;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 600;
			min-height: 40px;
			padding-inline: 10px;
			background: var(--warning) 0% 0% no-repeat padding-box;
			border-radius: 6px;
			min-width: 100px;
			color: #333333;
			text-transform: none;


			.status-block-order {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 5px;
			}
		}
	}

	.subtitle-client {
		font-weight: 500;
		letter-spacing: 0.21px;
		color: #1e4883;
	}

	.order-titles-div {
		.title-financial-release {
			height: auto;
			width: auto;
			display: flex;
			border-radius: 6px;
			justify-content: flex-end;
			gap: 20px;

			.financial-release-dropdown {
				align-items: flex-end;

				.MuiInput-underline {
					font-weight: bold;
				}
			}
		}
	}

	.title-order {
		opacity: 1;
		font-size: 14px;
		text-align: left;
		color: #1e4883;
		font-weight: normal;
		text-transform: uppercase;
	}
}

.device-mobile .order-titles {
	margin-top: 0px;

	
	.client-titles-div,
	.order-titles-div {
		.title-block-info {
			position: absolute;
			top: 20px;
			right: 120px;
			width: 40px;
			height: 40px;
			min-width: 40px;
			padding-inline: 5px;
			background-color: var(--error);
		}
	}
}
.title-info-order-modal {
	display: flex;
	gap: 5px;
	justify-content: center;
	align-items: center;
	// font-size: 12px;
	font-weight: 600;
	// min-height: 40px;
	// padding-inline: 10px;
	// background: var(--warning) 0% 0% no-repeat padding-box;
	// border-radius: 6px;
	// min-width: 100px;
}
