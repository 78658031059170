.service-order-follow-up {
	width: 600px;
	height: 100%;
	position: relative;

	.service-order-follow-up-main-contain {
		height: calc(100% - 60px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}
.device-mobile .service-order-follow-up {
	width: 100%;
	height: 100%;
}
