@import url("./theme/light/colors.scss");
@import url("./theme/light/fonts.scss");
@import url("./theme/dark/colors.scss");
@import url("./theme/dark/fonts.scss");
@import "react-toastify/dist/ReactToastify.css";

:root {
	// Text-colors
	--text-high-pure: #f8f9fa;
	--text-primary-pure: #004687;
	--text-low-pure: #333333;
	--text-high-dark: #a6a6a6;

	//Blue
	--primary-pure: #004687;
	--primary-highlight: #025cb7;
	--primary-medium: #4f8fe9;
	--primary-light: #5daeff;
	--primary-lightest: #d0dff0;
	--primary-purple: #5e50ae;

	//Dark
	--low-pure: #333333;
	--low-dark: #202020;
	--low-darkest: #000000;
	--low-darkest-shadow: #00000080;
	--low-medium: #515151;
	--low-light: #767676;

	//Light
	--high-pure: #f8f9fa;
	--high-light: #f2f2f2;
	--high-lightest: #ffffff;
	--high-medium: #e5e5e5;
	--high-dark: #a6a6a6;

	//Feedback colors
	--success: #048243;
	--error: #c21618;
	--warning: #ffe5e5;
	--error-dark: #2f2020;

	//Gradient
	--homecoming: linear-gradient(rgba(79, 143, 233, 1) 0%, rgba(57, 107, 201, 1) 45%, rgba(94, 80, 174, 1) 100%);
	--confirmation: linear-gradient(rgba(93, 174, 255, 1) 0%, rgba(47, 114, 207, 1) 100%);
	--cancellation: linear-gradient(rgba(229, 69, 41, 1) 0%, rgba(194, 22, 24, 1) 100%);
	--high-gradient: linear-gradient(rgba(208, 223, 240, 1) 0%, rgba(248, 249, 250, 1) 100%);
	--light-gradient: linear-gradient(#f8f9fa, #d0dff0);
	--low-gradient: linear-gradient(rgba(35, 35, 35, 1) 0%, rgba(15, 52, 81, 1) 100%);

	// Global
	body {
		margin: 0;
		padding: 0;
		color: #333;
		font-size: 14px;
		min-height: 100%;
		font-weight: 400;
		box-sizing: border-box;
		letter-spacing: 0.21px;
		font-family: roboto, sans-serif;
		background-color: #f8f9fa !important;

		a {
			color: #333333;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}
	}

	// Fonts
	.roboto-thin {
		font-family: "Roboto", sans-serif;
		font-weight: 100;
		font-style: normal;
	}

	.roboto-light {
		font-family: "Roboto", sans-serif;
		font-weight: 300;
		font-style: normal;
	}

	.roboto-regular {
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-style: normal;
	}

	.roboto-medium {
		font-family: "Roboto", sans-serif;
		font-weight: 500;
		font-style: normal;
	}

	.roboto-bold {
		font-family: "Roboto", sans-serif;
		font-weight: 700;
		font-style: normal;
	}

	.roboto-black {
		font-family: "Roboto", sans-serif;
		font-weight: 900;
		font-style: normal;
	}

	.roboto-thin-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 100;
		font-style: italic;
	}

	.roboto-light-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 300;
		font-style: italic;
	}

	.roboto-regular-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 400;
		font-style: italic;
	}

	.roboto-medium-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 500;
		font-style: italic;
	}

	.roboto-bold-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 700;
		font-style: italic;
	}

	.roboto-black-italic {
		font-family: "Roboto", sans-serif;
		font-weight: 900;
		font-style: italic;
	}
}

@media (width > 480px) {
	// Scrollbar
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #00000080;
		border-radius: 6px;
	}
}

.Toastify__toast-container {
	left: 50% !important;
	position: absolute !important;
	transform: translateX(-50%) !important;
}

.main-form-content {
	width: 100%;

	.form-item-content {
		height: 90px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		label {
			font-size: 12px;
			font-weight: 400;
			margin-bottom: 8px;
		}

		input,
		textarea {
			width: 100%;
			height: 45px;
			border: none;
			border-radius: 6px;
			padding: 10px;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			resize: none;
			font-weight: bold;
		}

		textarea {
			height: 120px;
		}

		input::placeholder,
		textarea::placeholder {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
		}

		input:focus-visible,
		textarea:focus-visible {
			outline: none;
			box-shadow: none;
		}

		input[type="password"] {
			font-size: 15px;
		}

		input.MuiOutlinedInput-input::placeholder {
			font-weight: 500;
		}

		.toggle-input {
			width: 100%;
			position: relative;

			div {
				position: absolute;
				top: 10px;
				right: 15px;
				color: #333333;
				opacity: 0.2;
				cursor: pointer;

				.MuiSvgIcon-root {
					width: 1em !important;
					height: 1em !important;
				}
			}
		}

		.dropdown-content .MuiInputBase-root {
			width: 100%;
		}

		.form-item-content-validator {
			text-align: left;

			span {
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}
		}

		.MuiFormControl-root {
			width: 100%;
			padding: 0px;

			.MuiIconButton-root,
			.PrivateSwitchBase-root-5 {
				padding: 0px;
			}
		}

		.MuiIconButton-label {
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				border: 1px solid;

				.MuiSvgIcon-root:first-child {
					width: 16px;
					height: 16px;
					padding: 0px;
					opacity: 0;
				}
			}
		}

		.Mui-checked {
			.MuiIconButton-label {
				div {
					.MuiSvgIcon-root:last-child {
						transform: scale(1.3);
						left: unset;
					}
				}
			}
		}

		.MuiFormControlLabel-root {
			height: 40px;
			margin: 0px;
		}

		.MuiTypography-body1 {
			text-align: left;
			font-size: 12px;
			text-transform: capitalize;
			font-weight: 400;
			margin-left: 5px;
		}

		.custom-form-item-content {
			.custom-form-item-input.input-has-error,
			.custom-form-item-input.input-has-error.email {
				input {
					-webkit-box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
					box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
					border-color: #c21618;
					background-color: #ffe5e5 !important;
				}
			}

			.custom-form-item-content-validator {
				background-color: #ffe5e5;
				border-color: #c21618;
				-webkit-box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);
				box-shadow: 0px 0px 3px 0px rgba(194, 22, 24, 0.5);

				span {
					color: #333333;
				}
			}
		}
	}

	.form-content-validator {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 15px 0;

		.form-item-content-validator {
			text-align: left;

			span {
				font-size: 10px;
				font-weight: 400;
			}
		}
	}
}

.auto-complete-form-content {
	.MuiAutocomplete-input {
		padding: 5px 10px !important;
		height: 24px;
		font-size: 14px;
	}

	input::placeholder,
	textarea::placeholder {
		font-size: 14px;
		font-weight: 400;
	}
}

.MuiPopover-root.menu-box-item {
	.MuiList-root {
		padding: 0px;

		li {
			font-weight: 400;
			font-size: 14px;
		}
	}
}

.MuiAutocomplete-popper {
	padding: 0px !important;
	border-radius: 6px !important;

	.MuiAutocomplete-listbox {
		padding: 0px;
		border-radius: 6px !important;
		box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15) !important;
	}

	.MuiAutocomplete-option {
		font-size: 14px !important;
		line-height: 20px !important;
		text-transform: capitalize;
	}

	.MuiAutocomplete-option:hover,
	.MuiAutocomplete-option.Mui-selected {
		background-color: #f1f5fd !important;
	}
}

.toast-main-content {
	text-align: center;

	.Toastify__toast {
		border-radius: 0px 0px 6px 6px;
		padding: 8px 20px;
	}

	.toast-body-content {
		font-size: 11px;
		color: #f2f2f2;
		font-weight: 700;
		letter-spacing: 0.2px;
		text-transform: uppercase;
	}
}

.login-form,
.reset-pass-form {
	.main-form-content {
		.form-item-content {
			input {
				color: #333333;
				font-weight: 400;
			}

			input::placeholder {
				color: #bebebe;
			}

			input:disabled {
				background-color: #c9c9c9;
				color: #969696;
				opacity: 1;
			}

			input:disabled::placeholder {
				color: #969696;
			}

			.toggle-input {
				div {
					color: #333333 !important;
				}
			}
		}
	}
}

.login-main-content,
.create-account-main-content,
.new-pass-main-content,
.reset-pass-main-content {
	.side-bar-content {
		background-color: #004687;
		color: #f8f9fa;

		::-webkit-scrollbar-track {
			background-color: #004687;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background-color: #d0dff0;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background-color: #5daeff;
		}
	}

	.login-form,
	.new-pass-form,
	.create-account-form,
	.reset-pass-form {
		.main-form-content {
			.form-item-content {
				input {
					color: #333333;
					font-weight: 400;
				}

				input::placeholder {
					color: #bebebe;
				}

				input:disabled {
					background-color: #c9c9c9;
					color: #969696;
					opacity: 1;
				}

				input:disabled::placeholder {
					color: #969696;
				}

				.toggle-input {
					div {
						color: #333333 !important;
					}
				}
			}
		}

		.loading-main-content {
			background-color: #004687;

			.loading-main,
			.loading-main svg {
				color: #f8f9fa !important;
			}
		}

		.login-form-options {
			a,
			a:hover,
			a:visited {
				color: #f8f9fa;
			}
		}

		.create-account-form-actions {
			button {
				background-color: #004687;
				color: #f8f9fa;
				border: 1px solid #f8f9fa;
			}
		}

		.login-form-actions,
		.new-pass-form-actions,
		.reset-pass-form-actions {
			.login-submit,
			.new-pass-submit,
			.create-account-submit,
			.reset-pass-submit {
				background-color: #f8f9fa;
				color: #004687;
				border: 1px solid #f8f9fa;
			}

			.reset-pass-cancel,
			.login-create-account {
				border: 1px solid #f8f9fa;
				background-color: #004687;
				color: #f8f9fa;
			}

			.login-submit:focus-visible,
			.create-account-submit:focus-visible,
			.new-pass-submit:focus-visible,
			.reset-pass-submit:focus-visible,
			.reset-pass-cancel:focus-visible,
			.login-create-account:focus-visible {
				outline: none;
				box-shadow: none;
			}
		}

		.create-account-options {
			.MuiCheckbox-root,
			.MuiCheckbox-colorSecondary.Mui-checked {
				color: #f8f9fa;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	p,
	b,
	span,
	span b,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	small,
	input,
	button,
	textarea,
	input::placeholder,
	textarea::placeholder,
	label {
		font-family: "Roboto", sans-serif !important;
	}

	.main-web-menu {
		display: none;
	}

	.main-mobile-menu {
		display: flex;
	}

	.main-page-selector {
		position: fixed;
		top: 0px;
		width: 100%;
		padding-right: 20px;
	}

	.go-back-component {
		justify-content: center;

		span {
			display: none;
		}
	}

	.modal-main-backdrop {
		height: calc(var(--app-height) - 48px);
		overflow-x: hidden;
	}

	.toast-main-content {
		max-width: 360px;
	}
}

.main-mobile-menu-options,
.mobile-help-main-content,
.mobile-settings-main-content,
.client-area-selector.mobile,
.notify-center-mobile-content,
.modal-main-backdrop,
.main-page-content {
	-webkit-transition: height 15ms ease-in-out;
	-moz-transition: height 15ms ease-in-out;
	-ms-transition: height 15ms ease-in-out;
	-o-transition: height 15ms ease-in-out;
	transition: height 15ms ease-in-out;
}

.Toastify__toast--info {
	background: #5daeff !important;
}
