.pendency-filter {
	.pendency-filter-header {
		width: 100%;
		margin-bottom: 5px;

		span {
			font-size: 12px;
			font-weight: bold;
		}
	}

	.pendency-filter-fields {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		div {
			width: 100%;

			span {
				font-size: 12px;
				font-weight: 400;
			}
		}
		div:first-child {
			margin-right: 20px;
		}
	}
}
