.search-component {
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--primary-pure);
  background-color: var(--high-pure);
  height: 40px;

  #search-icon {
    margin-left: 8px;
    width: 24px;
    height: 24px;

    path {
      fill: var(--primary-pure) !important;
    }

  }

  #search-input {
    width: 100%;
    font-size: 13px;
    height: 40px;
    border: none;
    background-color: transparent;
    padding: 0 8px;
    color: var(--primary-pure);
    outline: none;

    &::placeholder {
      color: var(--primary-pure);
      font-weight: 500;
    }

    &:focus {
      &::placeholder {
        font-style: italic;
        color: var(--high-dark);
      }
    }

  }
}