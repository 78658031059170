.all-balance-storage-list-body {
	max-height: 217px;
	overflow: overlay;
	position: relative;

	.all-balance-storage-list {
		height: 35px;
		display: flex;
		align-items: center;
		width: 100%;
		background-color: #ffffff;

		.all-balance-storage-list-item {
			margin: 0px 10px 0px 0px;
			padding: 0px;
		}

		.all-balance-storage-list-ul {
			width: 20px;
		}
		.all-balance-storage-list-product {
			width: 5%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.all-balance-storage-list-description {
			width: calc(32% - 20px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.all-balance-storage-list-amount {
			width: 10%;
		}
		.all-balance-storage-list-storage {
			width: 6%;
		}
		.all-balance-storage-list-origin {
			width: 10%;
		}
		.all-balance-storage-list-more-options {
			width: 2%;
		}

		.all-balance-storage-list-empty-list {
			width: 100%;
			text-align: center !important;
		}
		&:hover {
			background-color: #d0dff0 !important;
		}
	}

	.all-balance-storage-list:nth-child(even) {
		background-color: #e5e5e5;
	}
}
