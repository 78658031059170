.select-serial {
	width: 350px;
	position: relative;
	display: flex;
	flex-direction: column;

	.select-serial-main-contain {
		span {
			font-size: 12px;
			font-weight: 400;
		}

		.input-container {
			margin-top: 10px;
		}
	}

	.select-serial-equipment-header {
		margin-top: 20px;
		height: 35px;
		min-height: 35px;
		display: flex;
		align-items: center;
		width: 100%;
		gap: 20px;

		.list-item-equipment {
			padding: 0px !important;
			margin: 0px !important;
			width: 40%;
		}

		.list-item-client {
			padding: 0px !important;
			margin: 0px !important;
			width: 60%;
		}
	}

	.select-serial-equipment-list {
		max-height: 200px;
		overflow-y: auto !important;
		overflow-x: hidden;

		.select-serial-equipment-item {
			height: 35px;
			min-height: 35px;
			display: flex;
			align-items: center;
			width: 100%;
			background-color: #e5e5e5;
			gap: 20px;
			cursor: pointer;
			font-size: 13px;
			text-align: left;
			color: #333333;
			font-display: swap;
			white-space: nowrap;

			&:hover {
				background-color: #d0dff0 !important;
			}

			.list-item-equipment {
				padding: 0px 0px 0px 5px !important;
				margin: 0px !important;
				width: 40%;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.list-item-client {
				padding: 0px 0px 0px 5px !important;
				margin: 0px !important;
				width: 60%;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.select-serial-equipment-item:nth-child(even) {
			background-color: #ffffff;
		}
	}
}

.select-serial-actions {
	width: 100%;
	display: flex;
	column-gap: 20px;
	justify-content: center;
}

.device-mobile .select-serial {
	width: 100%;
	height: 100%;
}
