.device-mobile .new-accessories-body {
	display: grid;
	grid-template-rows: 1fr 80px;
	height: calc(100% - 80px);

	.new-accessories-body-list {
		height: 100%;
		max-height: 100%;
		overflow: overlay;
		margin: 0 -20px;


		.new-accessories-body-item {
			width: 100%;
			height: auto;
			align-items: flex-start;
			padding: 10px;
			gap: 10px;

			.container-checkbox {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: auto 0;
				width: 20px;
			}

			.accessories-details-list {
				display: grid;
				width: 100%;
				margin: 0px;
				grid-template-columns: 1fr 1fr;
				align-items: flex-start;
				gap: 5px;

				.accessories-details-list-item {
					width: 100%;
					font-size: 12px;

					.select-light-container,
					.input-container {
						height: 30px;
					}

					span {
						display: flex;
						font-weight: 400;
						text-align: left;
						color: #333333;
						white-space: normal;
					}


					.accessories-details-list-title {
						width: 100%;
						font-weight: 700;
						font-size: 0.75rem;

						span {
							margin-left: 5px;
						}

						p {
							font-weight: 400;
						}
					}
				}

				.accessories-details-list-description {
					grid-column: 1 / -1;

					label {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}


				.accessories-details-list-first-line {
					display: grid;
					column-gap: 10px;
					grid-template-columns: 1fr 1fr 1fr;
				}

				.accessories-details-list-line-double-item {
					display: grid;
					column-gap: 10px;
					grid-template-columns: 1fr 1fr;
				}
			}

			&:nth-child(odd) {
				background-color: #e5e5e5;
			}
		}
	}

	.new-accessories-body-action {
		margin: 20px 0;
		position: relative;
		bottom: 0px;
	}
}

.device-mobile .new-accessories-body.viewing {
	grid-template-rows: 1fr;
	height: calc(100% - 50px);

	.accessories-details-list {
		width: 100% !important;
	}
}