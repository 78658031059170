.all-balance-storage-list-header {
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	background-color: #ffffff;

	span {
		opacity: 1;
		font-size: 13px;
		text-align: left;
		color: #333333;
		font-display: swap;
	}

	.all-balance-storage-list-item {
		font-weight: bold;
		padding: 0px;
		margin: 0px 10px 0px 0px;
	}
	.all-balance-storage-list-ul {
		width: 20px;
	}
	.all-balance-storage-list-product {
		width: 5%;
	}
	.all-balance-storage-list-description {
		width: calc(32% - 20px);
	}
	.all-balance-storage-list-amount {
		width: 10%;
	}
	.all-balance-storage-list-storage {
		width: 6%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.all-balance-storage-list-origin {
		width: 10%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.all-balance-storage-list-more-options {
		width: 2%;
	}
}
