.email-contain {
	width: 420px;

	.email-contain-form {
		margin-bottom: 30px;
	}
}

.email-contain-actions {
	width: 100%;
	display: flex;
	justify-content: center;

	button {
		width: 108px;
	}
	button:first-child {
		margin-right: 20px;
	}
}

.device-mobile .email-contain {
	width: 100%;

	.email-contain-actions {
		bottom: 70px;
		display: flex;
		position: absolute;
		justify-content: center;
		width: calc(100% - 40px);
	}
}

@media screen and (max-width: 480px) {
	.email-contain {
		width: 100%;
	}

	.email-contain-actions {
		bottom: 70px;
		display: flex;
		position: absolute;
		justify-content: center;
		width: calc(100% - 40px);
	}
}
