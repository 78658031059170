.human-resources-card-title {
	display: flex;
	justify-content: space-between;

	h4 {
		font-size: 20px;
		width: 80%;
		color: #004687;
	}
}

.human-resources-card-main-contain {
	height: 100%;
}

.human-resources-card-actions {
	display: flex;
	justify-content: center;
	margin-bottom: 0px;
	button {
		width: 245px;
		font-size: 12px;
		text-transform: capitalize;
	}
}
