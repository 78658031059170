.user-area-selector {
	padding: 10px 10px 0px 10px;
	position: absolute;
	top: 50px;
	right: 30px;
	width: 200px;
	height: auto;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) !important;
	z-index: 35;
	background-color: #f8f9fa !important;
	.user-area-selector-item {
		height: 32px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		span {
			font-size: 13px;
			font-weight: 400;
		}
	}
	.user-area-selector-item:first-child {
		border-radius: 6px 6px 0 0;
	}
	.user-area-selector-item:last-child {
		border-radius: 0 0 6px 6px;
	}
	.user-area-selector-item:hover {
		background-color: #d0dff0;
	}
}

.main-page-content.dark-theme .main-form-content .form-item-content input,
textarea {
	font-weight: 400;
}

.main-page-content.light-theme .main-form-content .form-item-content input,
textarea {
	font-weight: 400;
}
