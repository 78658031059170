.new-follow-up {
	width: 610px;
	position: relative;

	.new-follow-up-form {
		gap: 20px;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.new-follow-up-form-input {
			display: flex;
			flex-direction: column;

			header {
				margin-bottom: 10px;
				font-weight: 500;
			}
		}

	}

	.new-follow-up-form-upload {
		margin-top: 20px;
			
		.new-follow-up-upload-action {
			cursor: pointer;
			color: #004992;
			font-size: 12px;
			font-weight: 500;

			svg {
				fill: #004992;
			}
			
		}
		
	}

	.new-follow-up-integrate-error {
		margin: 10px 0px;
	}

	.new-follow-up-form-textarea {
		margin-top: 20px;

		textarea {
			width: 100%;
			border: none;
			resize: none;

			padding: 10px;
			border-radius: 6px;
			height: 100px !important;
			background: #F2F2F2 0% 0% no-repeat padding-box;

			&::placeholder {
				color: #A6A6A6;
			}

			&:focus-visible {
				outline: none;
			}
		}
	}
}

.new-follow-up-actions {
	bottom: 0;
	width: 100%;
	display: flex;
	position: relative;
	justify-content: center;

	button {
		width: 108px;
		margin-right: 20px;
	}

	button:last-child {
		margin-right: 0px;
	}
}

.device-mobile {
	.new-follow-up {
		width: 100%;
		display: flex;
		row-gap: 20px;
		flex-direction: column;
		height: 100%;
	}

	.new-follow-up-actions {
		bottom: 0px;
		display: flex;
		position: relative;
		justify-content: center;
	}
}

@media only screen and (max-width: 700px) {
	.new-follow-up {
		width: 100%;
	}
}

@media only screen and (max-width: 430px) {
	.new-follow-up {
		width: 100%;
		display: flex;
		row-gap: 20px;
		flex-direction: column;

		.new-follow-up-category {
			margin-right: 0px;
		}
	}
}