.filter-os-list-fields {
	width: 650px;

	.filter-os-list-state {
		overflow-y: auto;
		max-height: 100px;
		overflow-x: hidden;
		margin-bottom: 20px;
		padding: 10px 0px 0px;
		display: grid;
		white-space: nowrap;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.filter-os-list-status {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
	}

	.filter-os-list-types,
	.filter-os-list-status {
		overflow-y: auto;
		max-height: 100px;
		overflow-x: hidden;
		margin-bottom: 20px;
		padding: 10px 0px 0px;
		display: grid;
		grid-template-columns: 50% 50%;
	}

	.filter-accounts-contain {
		padding: 10px 0px 0px;
	}

	.filter-os-list-types {
		white-space: nowrap;

		label {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.device-mobile .new-filter-os-contain {
	height: calc(100% - 60px);
	overflow-y: scroll;
	.filter-os-list-fields {
		width: 100%;

		.filter-os-list-state {
			display: grid;
			max-height: 120px;
			white-space: nowrap;
			grid-template-columns: 1fr 1fr 1fr;
		}

		.filter-os-list-types {
			overflow-x: scroll;
		}

		.filter-os-list-types,
		.filter-os-list-status {
			display: grid;
			max-height: 120px;
			white-space: nowrap;
			grid-template-columns: 1fr;
		}

		.filter-accounts-contain {
			display: flex;
			white-space: nowrap;
			flex-direction: column;
		}
	}
}
