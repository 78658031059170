.cpf-cnpj {
	margin-top: 20px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	@media screen and (max-width: 767px) {
		font-weight: 600;
	}
}

.cpf-cnpj input:focus {
	box-shadow: none;
	border: 2px solid rgba(51, 51, 51, 0.35) !important;
	color: rgba(51, 51, 51, 0.7);
}

.cpf-cnpj-field-label {
	color: rgba(51, 51, 51, 0.9);
	font-size: 12px;
	line-height: 16px;
	display: flex;
	margin-bottom: 0px;
	padding-right: 5px;
	text-align: left;
}

.cpf-cnpj-field {
	width: 100%;
	height: 30px;
	padding: 3px 5px;
	border: 1px solid rgba(51, 51, 51, 0.65);
	border-radius: 4px;
	color: rgba(51, 51, 51, 0.7);
	font-size: 14px;
	line-height: 20px;
}
