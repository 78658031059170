.row-install-and-search {
	display: flex;
	justify-content: flex-start;
	gap: 20px;

	@media screen and (max-width: 480px) {
		flex-flow: row wrap;
	}
}


.column-install-and-search {
	margin-top: 20px;
	width: 100%;

	@media screen and (max-width: 480px) {
		flex-basis: 100% !important;
	}
}