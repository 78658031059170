.body-modal-search-tech {
  .form-label-title {
    margin: 0;
    color: #194883;
  }

  .container-address-confirm-actions {
    display: flex;
    margin-top: 30px;
    column-gap: 20px;

    button {
      width: 190px;
      border-radius: 1px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}