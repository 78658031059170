.modal-installation {
  width: 760px;
  max-width: 100%;
  overflow: hidden;

  .row-install-and-search {
    .column-install-and-search {}
  }

  .title-modal-install {
    font-weight: 300;
    text-align: left;
    color: #194883;

    .equipName {
      font-size: 20px;
      line-height: 36px;
      font-weight: 700;
    }

    .serialNum {
      font-size: 16px;
      line-height: 20px;
      font-weight: 300;
    }
  }

  .body-modal-install {
    .form-label-title {
      margin: 0;
      color: #194883;
    }

    .InputMask {
      display: block;
      width: 100%;
      height: initial;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #6c757d;
      height: 40px;
      padding-left: 10px !important;
      background-color: #edeef0;
    }

    .container-file-upload {
      margin: 20px 0 0;
      width: 100%;
      height: 50px;
      display: flex;
      border-radius: 2px;
      justify-content: center;
      border: dashed 1px #194883;
      cursor: pointer;

      .btn-attachment {
        padding: 0;
        border: none;
        outline: none;
        display: flex;
        color: #194883;
        background: none;
        align-items: center;
      }

      .btn-delete {
        border: none;
        outline: none;
        background: none;
      }
    }

    .container-address-confirm-actions {
      display: flex;
      margin-top: 30px;
      column-gap: 20px;

      button {
        width: 190px;
        border-radius: 1px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

}

@media only screen and (max-width: 480px) {
  .modal-installation {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .modal-installation {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}