.client-log-compare {
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	column-gap: 20px;
	height: 30px;
	justify-content: space-between;


	span {
		display: flex;
		align-items: center;
		background: #D0DFF0;
		color: #025CB7;
		padding-left: 10px;
		border-radius: 6px 6px 0 0 ;
		label {
			margin-bottom: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.client-log-compare-title-old {
		min-width: 50%;
	}

	.client-log-compare-title-new {
		width: 100%;
	}
}