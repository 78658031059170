.file-list-container {
	margin-top: 20px;
	padding: 0;
	max-height: 100px;
	overflow: auto;

	li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #444;

		.file-info {
			display: flex;
			align-items: center;

			.preview {
				width: 36px;
				height: 36px;
				border-radius: 5px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: 50% 50%;
				margin-right: 10px;
			}

			div {
				display: flex;
				flex-direction: column;

				span {
					display: flex;
					font-size: 12px;
					color: #999;
					margin-top: 5px;

					button {
						border: 0;
						background: transparent;
						color: #e57878;
						margin-left: 5px;
						cursor: pointer;
					}
				}
			}
		}

		& + li {
			margin-top: 10px;
		}
	}
}
