.period-filter {
	margin-bottom: 20px;
	span {
		font-size: 12px;
		font-weight: bold;
	}

	.period-filter-header {
		width: 100%;
		margin-bottom: 10px;
	}

	.period-filter-fields {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.period-filter-fields-div {
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		.period-filter-fields-div:first-child {
			margin-right: 20px;
		}

		.MuiFormControl-root.MuiTextField-root {
			.MuiInputBase-input {
				padding-left: 10px;
				color: #a6a6a6;
			}

			.date-picker-custom {
				padding-left: 10px;
			}
		}

		.MuiFormControl-root.MuiTextField-root:first-child {
			margin-right: 20px;
		}

		.MuiFormLabel-root {
			transform: translate(0, 1.5px) scale(1);
			font-weight: 400;
			font-size: 12px;
			opacity: 1;
		}

		.MuiInputBase-root {
			background-color: #f2f2f2;
			border-radius: 6px;
			font-size: 12px;
			height: 40px;
		}

		.MuiInput-underline:hover,
		.MuiInput-underline:hover:not(.Mui-disabled):before,
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
		.MuiInput-underline::before,
		.MuiInput-underline::after {
			border: none;
			outline: none !important;
			content: '' !important;
			box-shadow: none !important;
		}

		.MuiIconButton-root {
			padding: 8px;
		}

		.MuiFormHelperText-root.Mui-error {
			font-size: 11px;
		}
	}
}

.MuiPickersToolbar-toolbar {
	background-color: #025cb7 !important;
}

.MuiPickersDay-daySelected {
	background-color: #025cb7 !important;
}

.MuiButton-textPrimary {
	color: #025cb7 !important;
}
