.response-container {
  padding: 20px 10px 0;

  .service-order-container.external {
    .service-order-options-container {
      width: 450px !important;
      max-width: 100%;
    }
  }

  .service-order-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .service-order-options-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 700px;
    }

    p {
      display: block;
      font-size: 26px;
      color: #194883;
      margin: 24px 0px;
    }
  }

  .service-order-email,
  .service-order-phone,
  .service-order-actions button {
    height: 40px;
    display: flex;
    min-width: 220px;
    padding: 5px 15px;
    border-radius: 1px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #194883;
  }

  .service-order-email a,
  .service-order-phone a,
  .service-order-phone p {
    color: #194883;
    font-size: 14px;
    margin: 0px;
  }

  .service-order-email,
  .service-order-phone,
  .service-order-actions {
    img {
      margin-right: 10px;

    }
  }

  .service-order-email,
  .service-order-phone {
    background-color: #fff;
    color: #194883;

  }

  .service-order-actions button {
    font-size: 14px;
  }

  .service-order-phone.hide-m {
    display: flex !important;
  }

  .service-order-phone.show-m {
    display: none !important;
  }

  @media only screen and (max-width: 800px) {
    .service-order-phone.hide-m {
      display: none !important;
    }

    .service-order-phone.show-m {
      display: flex !important;
    }
  }

  @media only screen and (max-width: 700px) {
    .service-order-container {
      .service-order-options-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
      }
    }


    .service-order-email,
    .service-order-phone,
    .service-order-actions {
      margin-top: 15px;
    }
  }


  .container-f {
    gap: 5px;
    width: 100%;
    padding: 20px;
    display: flex;
    margin: auto;
    padding: 20px;
    border-radius: 2px;
    border-style: solid;
    border-width: 0.5px;
    background: #ffffff;
    border-image-slice: 1;
    background-origin: border-box;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-image-source: linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04));
    background-image: #ffffff,
      linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04));

    .item-f {
      margin: 5px;
      color: #194883;
    }

    .text-align-l {
      text-align: left;
    }
  }
}