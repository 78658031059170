.itens-list-header {
	height: 35px;
	display: flex;
	align-items: center;
	position: relative;

	span {
		font-weight: bold;
		opacity: 1;
		font-size: 12px;
		text-align: left;
		color: #333333;
		font-display: swap;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.itens-list-order-item {
		padding-left: 0px;
		margin: 0px 20px 0px 0px;
	}

	.itens-list-order-options {
		width: 35px;
		min-width: 35px;
		align-items: center;
		margin-left: 5px;
	}

	.itens-list-order-product-code {
		width: 65px;
		min-width: 65px;

		@media screen and (min-width: 1398px) {
			width: 10%;
		}
	}

	.itens-list-order-description {
		width: 400px;
		min-width: 400px;

		@media screen and (min-width: 1398px) {
			width: calc(55% - 100px);
		}
	}

	.itens-list-order-accessories {
		width: 100px;
		min-width: 100px;
	}

	.itens-list-order-amount {
		width: 90px;
		min-width: 90px;
	}

	.itens-list-order-table-price {
		width: 105px;
		min-width: 105px;
	}

	.itens-list-order-unit-price {
		width: 105px;
		min-width: 105px;
	}

	.itens-list-order-total-net-price {
		width: 100px;
		min-width: 100px;
	}

	.itens-list-order-total-value {
		width: 150px;
		min-width: 150px;
	}

	.itens-list-order-more-options {
		width: 20px;
		min-width: 20px;

		@media screen and (min-width: 1398px) {
			min-width: 40px;
		}
	}
}
