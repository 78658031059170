.step-progress-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 50px;
    width: 80%;
    margin: 0px auto 20px;
    .step-progress-item-content {
        position: relative;
        .progress-line {
            height: 2px;
            background-color: #f1f5fd;
        }
        .progress-step {
            position: absolute;
            top: -5px;
            left: -5px;
            .progress-dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #f1f5fd;
            }
            .progress-label {
                position: absolute;
                top: 10px;
                width: 68px;
                left: -28px;
                text-align: center;
                span {
                    font-size: 10px;
                    opacity: .6;
                }
            }
        }
    }
    .step-progress-item-content,
    .step-progress-item-content.start-step-progress-item {
        width: calc(100% - 12px);
    }
    .step-progress-item-content.finish-step-progress-item {
        width: auto !important;
    }
    .step-progress-item-content.passed-step-progress-item {
        .progress-line {
            background-color: #004687;
        }
        .progress-step {
            .progress-dot {
                background-color: #004687;
            }
            .progress-label {
                span {
                    opacity: .9;
                }
            }
        }
    }
    .step-progress-item-content.stoped-step-progress-item {
        .progress-line {
            background-color: #f1f5fd;
        }
        .progress-step {
            .progress-dot {
                background-color: #004687;
            }
            .progress-label {
                span {
                    opacity: .9;
                }
            }
        }
    }
    .step-progress-item-content.return-step-progress-item {
        .progress-step .progress-label span {
            cursor: pointer;
        }
    }
}