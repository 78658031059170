.more-option-item-click {
    background-color: transparent !important;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiTouchRipple-root {
        display: none;
    }
}

.more-option-item-click:hover {
    background-color: transparent !important;

    .MuiTouchRipple-root {
        display: none;
    }
}