.client-filter-fields {
	display: flex;
	flex-direction: column;

	.filter-fields-section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	.filter-fields-section-option {
		width: 50%;
		label {
			font-size: 13px;
			font-weight: bold;
			select {
				margin-top: 10px;
			}
		}
	}
	.filter-fields-section-option:first-child {
		margin-right: 20px;
	}
}
