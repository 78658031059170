.discount-policy-contain {
	width: 650px;
	height: 600px;
	overflow-x: overlay;

	.discount-policy-section {
		width: 100%;
		display: flex;
		margin-bottom: 20px;
		.discount-policy-topic {
			span {
				text-align: left;
				font-size: 13px;
				color: #333333;
			}
		}
	}
	.discount-policy-combine-section {
		width: 100%;
		display: flex;
		flex-direction: row;
		.discount-policy-section {
			display: flex;
			flex-direction: column;
			.discount-policy-topic {
				margin-bottom: 20px;
				span {
					text-align: left;
					font-size: 13px;
					color: #333333;
				}
			}
		}
		.discount-policy-section:first-child {
			margin-right: 20px;
		}
	}
}

@media only screen and (max-width: 710px) {
	.discount-policy-contain {
		width: 100%;
	}
}

.device-mobile .discount-policy-contain {
	height: calc(100vh - 90px);
	width: calc(100vw - 40px);
}
