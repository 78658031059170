@import 'assets/styles/theme/colors/variables.scss';


.execauto-error-info {
  gap: 10px;
  width: 100%;
  display: flex;
  padding: 5px 10px;
  border-radius: 6px;
  align-items: center;
  background-color: $white-e5-color;

  .execauto-error-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    fill: $redC2-color !important;
  }

  .execauto-error-link {
    cursor: pointer;
    text-decoration: underline;
  }

  span {
    font-size: 12px  !important;
    font-weight: 500  !important;
    color: $redC2-color !important;
  }
}

.execauto-error-modal-content {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    outline: 'none';
    font-size: '13px';
    font-weight: '300';
    border-radius: 6px;
    color: $black00-color;
    background-color: $whiteF8-color;
  }
}
