.service-order-page-contain {
	position: absolute;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.service-order-page-contain-header {
		width: 100%;
		flex: 0 auto;
		align-self: flex-start;
	}

	.service-order-page-contain-body {
		width: 100%;
		height: 100%;
		flex: 0 auto;
		overflow: auto;
		position: relative;
		margin-bottom: 20px;
		padding: 20px 15px 16px 20px;
		background-color: rgba(0, 70, 134, 0.29);

		@media screen and (max-width: 767px) {
			padding-right: 15px;
		}

		@media screen and (max-width: 479px) {
			position: static;
		}
	}
}

.service-order-page-action-button {
	height: 50px;
	width: 50px;
	border-radius: 50px;
	font-size: 40px;
	font-weight: 500;
	color: white;
	background-color: hsla(215.05, 62.91%, 49.93%, 1);
	position: fixed;
	right: 20px;
	top: 74px;
	cursor: pointer;
	line-height: initial;
}

.service-order-page-action-button-meet {
	border-radius: 3px;
	font-size: 16px;
	font-weight: 500;
	height: 50px;
	color: white;
	background-color: hsla(215.05, 62.91%, 49.93%, 1);
	position: fixed;
	padding-right: 12px;
	padding-left: 12px;
	right: 20px;
	cursor: pointer;
	line-height: 50px;
}

.actionOpt {
	position: fixed;
	right: 20px;
	top: 120px;
	z-index: 10;
	display: block;
	width: 190px;
	margin-top: 5px;
	border-radius: 5px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.66) 1px 1px 11px 0px;
	overflow: auto;
	display: none;
}

.device-mobile .service-order-page-contain {
	display: block;
	overflow-y: auto;
	overflow-x: hidden;

	.service-order-page-contain-header {
		height: auto;
		padding-top: 0px;
	}

	.service-order-page-contain-body {
		height: 300px;
		overflow: auto;
	}
}

.device-mobile .service-order-page-action-button {
	top: 23px;
	right: 20px;
	width: 40px;
	height: 40px;
	font-size: 33px;
	font-weight: 700;
}

.device-mobile .actionOpt {
	top: 65px;
	right: 55px;
	width: 210px;

	.Opt {
		margin: 0px;
		width: 100%;
		height: 30px;
		font-size: 12px;
		font-weight: 400;
		align-self: center;
		align-items: center;
		display: flex !important;
	}
}

.device-mobile .service-order-page-action-button-meet {
	top: 26px;
	height: 35px;
	display: flex;
	font-size: 12px;
	font-weight: 700;
	align-items: center;
}

.os-detail-back-button {
	z-index: 1;
	height: 50px;
	top: 7.5px;
	display: flex;
	font-size: 14px;
	color: #2f72cf;
	line-height: 20px;
	position: absolute;
	align-items: center;
}