.jac-users-app-list {
    height: calc(100dvh - 105px);
    display: flex;
    margin: 0 -20px;
    padding: 0px 20px;
    flex-direction: column;

    .jac-users-app-table {
        gap: 10px;
        height: 100%;
        display: flex;
        overflow-y: auto;
        margin: 30px -20px 0px;
        flex-direction: column;
        max-height: calc(100% - 70px);
    }

    .jac-users-app-paginator {
        margin-top: 20px;
    }
}

.device-mobile .jac-users-app-list {
    .jac-users-app-table {
        max-height: calc(100% - 112px);
        margin: 20px -20px 10px;
    }
}