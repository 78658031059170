.list-items-filter {
    max-height: 350px;
    min-width: 50px;
    padding: 5px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
}

.list-items-filter label {
    margin-right: 5px !important;
}