.clients-list {
	height: 35px;
	min-height: 35px;
	display: flex;
	align-items: center;
	width: 100%;
	background-color: #e5e5e5;
	&:hover {
		background-color: #d0dff0 !important;
	}
	.clients-list-item {
		margin: 0px 5px 0px 0px;
		padding: 0px;
	}

	.clients-list-legend {
		width: 3%;
		display: flex;
		justify-content: center;
	}
	.clients-list-client-code {
		width: 4%;
	}
	.clients-list-company-name {
		width: 40%;
	}
	.clients-list-company-register {
		width: 13%;
	}
	.clients-list-last-order {
		width: 7%;
	}
	.clients-list-issue-date {
		width: 6%;
	}
	.clients-list-salesperson {
		width: 13%;
	}
	.clients-list-more-options {
		width: 2%;
	}
	.clients-list-empty-list {
		width: 100%;
		text-align: center;
	}
}

.clients-list:nth-child(even) {
	background-color: #ffffff;
}
