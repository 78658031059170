.pendency-to-send {
	width: 610px;
	span {
		font-size: 12px;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.selected-pendency-to-send {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		justify-content: space-between;
		margin-bottom: 30px;
		span {
			font-weight: 400;
		}
	}

	.text-pendency-to-send {
		display: flex;
		margin-bottom: 30px;
		flex-direction: column;

		textarea {
			height: 90px;
			width: 100%;
			padding: 10px;
			border: none;
			background: #f2f2f2 0% 0% no-repeat padding-box;
			border-radius: 6px;
			opacity: 1;
			resize: none;
		}
		textarea::placeholder {
			color: #a6a6a6;
			font-size: 12px;
		}
	}

	.send-pendency-actions {
		display: flex;
		flex-direction: row;
		justify-content: center;

		button {
			width: 108px;
			margin-right: 20px;
		}
		button:last-child {
			margin-right: 0px;
		}
	}
}

.device-mobile .pendency-to-send {
	width: 100%;

	.selected-pendency-to-send {
		grid-template-columns: auto;
	}

	.send-pendency-actions {
		bottom: 70px;
		position: fixed;
		width: calc(100% - 40px);
	}
}

@media only screen and (max-width: 700px) {
	.pendency-to-send {
		width: 100%;
		display: flex;
		row-gap: 20px;
		flex-direction: column;
	}
}
