.modal-success-open-os {
  width: 55%;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 0px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardTitle {
    font-size: 25px;
    line-height: 20px;
    margin: 10px 0px 12px;
    font-weight: 400;
    text-align: center;
  }

  .cardSub {
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
  }

  .action {
    display: block;
    margin: 60px auto 0;
    width: fit-content;
  }
}