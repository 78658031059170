.transfers-info-content {
	width: 100%;
	.transfers-client-info-content {
		margin-bottom: 40px;
	}
	.transfers-equip-info-content,
	.transfers-client-info-content {
		.equip-content,
		.found-client-content,
		.new-client-content {
			width: 100%;
			.transfer-info {
				h4 {
					font-size: 14px;
					font-weight: 300;
					color: #222;
					margin: 0px;
				}
			}
			.transfer-contact-info {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: row;
				div {
					width: 50%;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-direction: column;
					margin: 15px 0;
					span {
						font-size: 13px;
						color: #222;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						overflow: hidden;
						margin-right: 10px;
						b {
							margin-right: 5px;
						}
					}
				}
			}
		}
		.new-client-content {
			.new-client-warning {
				background-color: rgba(194, 22, 24, 0.1);
				border-radius: 5px;
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;
				margin-bottom: 15px;
			}
			.new-client-warning-header {
				background-color: rgba(194, 22, 24, 1);
				border-radius: 5px 5px 0 0;
				height: 35px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}
			.new-client-warning-header h6 {
				margin: 0px;
				color: #fff;
				font-size: 16px;
				font-weight: 600;
			}
			.new-client-warning-body {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				padding: 10px 20px;
			}
			.new-client-warning p {
				margin: 0px;
				color: #000;
				font-size: 14px;
				font-weight: 500;
				line-height: 25px;
			}
		}
	}
	.transfers-info-header {
		h6 {
			font-size: 20px;
			font-weight: 300;
			color: #222;
			margin: 0px;
		}
	}
	.transfer-options {
		display: block;
		color: #004687;
		font-size: 14px !important;
		font-weight: 500;
		text-align: left;
		line-height: 14px;
		text-decoration: underline;
		cursor: pointer;
		span {
			margin-right: 25px;
		}
	}
	.transfer-options.disabled {
		cursor: default;
		opacity: 0.5;
	}
	.transfers-info-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 30px;
		button {
			margin: 0px;
		}
	}
}

.device-mobile .transfers-info-content {
	.transfers-equip-info-content,
	.transfers-client-info-content {
		.equip-content,
		.found-client-content,
		.new-client-content {
			.transfer-contact-info {
				flex-direction: column;
				div {
					width: 100%;
				}
			}
		}
	}

	.transfers-info-footer {
		bottom: 90px;
		display: flex;
		position: fixed;
		justify-content: center;
		width: calc(100vw - 40px);
	}
}
