.new-accessories-header-list {
	display: flex;
	margin: 0 -20px;

	.accessories-details-list-item {
		margin: 0px;
		padding: 0px;
		display: flex;

		&:last-child {
			padding-right: 0;
		}

		span {
			font-size: 13px;
			text-align: left;
			color: #333333;
			overflow: hidden;
			font-weight: bold;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.accessories-details-list {
		width: 100%;
		padding: 0;
		display: flex;
		margin: 0 20px 0;
		align-items: center;

		.accessories-details-list-code {
			width: 9%;
		}

		.accessories-details-list-description {
			width: 34%;
		}

		.accessories-details-list-amount {
			width: 10%;
		}

		.accessories-details-list-commission {
			width: 11%;
		}

		.accessories-details-list-unit-price {
			width: 12%;
		}

		.accessories-details-list-table-price {
			width: 12%;
		}

		.accessories-details-list-total-price {
			width: 12%;
		}
	}
}