.log-modal {
	width: 660px;
	.log-modal-info {
		width: 100%;
		margin-top: 20px;
		
		textarea {
			width: 100%;
			resize: none;
			padding: 10px;
			outline: 'none';
			font-size: '13px';
			font-weight: '300';
			border-radius: 6px;
			color: var(black00-color);
			background-color: var(whiteF8-color);
		}
	}
}

.device-mobile .log-modal {
	width: 100%;
}