.order-history-actions {
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 20px 20px;

	.order-history-search-and-filter {
		display: flex;
		width: 460px;
		max-height: 40px;

		img {
			margin-left: 10px;
			width: 50px;
		}
		.filter-image {
			cursor: pointer;
		}
	}

	.order-history-legends {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			cursor: pointer;
			padding-right: 20px;

			img {
				margin-right: 7px;
			}
		}

		span:last-child {
			padding-right: 0px;
		}
	}

	.order-history-bt-new-order {
		width: 126px;
	}
}

.device-mobile .order-history-actions {
	margin: 20px;
	display: flex;
	flex-direction: column;

	.order-history-search-and-filter {
		width: calc(100vw - 40px);
	}

	.order-history-legends {
		gap: 5px;
		display: grid;
		margin-top: 10px;
		align-items: center;
		justify-content: center;
		width: calc(100% - 30px);
		grid-template-columns: 0.6fr 0.6fr 1fr;

		span {
			font-size: 12px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			img {
				width: 15px !important;
				height: 15px !important;
				margin-right: 5px;
			}
		}
	}

	.order-history-bt-new-order {
		width: 126px;
	}
}
