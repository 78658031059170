.client-finance-details-tab {
	gap: 20px;
	display: flex;
	margin: 0px -20px;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: overlay;
	flex-direction: column;
	height: calc(100% - 122px);

	.ppsa-section-component {
		margin: 0px;

		.divider-component {
			margin: 20px 0px;

			span {
				font-size: 13px !important;
			}
		}

		.client-finance-position-section {
			gap: 20px;
			display: flex;
			flex-wrap: wrap;

			.finance-position-section-contain-information {
				flex: 1 0 160px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				header {
					font-size: 13px;
					font-weight: 600;
					justify-content: flex-start;
				}

				span {
					font-size: 13px;
					margin-top: 10px;
				}

				.billet-action {
					height: 100%;
					display: flex;
					align-items: center;

					button {
						font-size: small;
						font-style: italic;
						text-decoration: underline;
						background-color: transparent;
						color: var(--primary-highlight);
					}
				}
			}
		}

		&:last-child {
			margin-bottom: 20px;
		}
	}

	.client-finance-details-tab-header-actions {
		gap: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.search-light {
			max-width: 400px !important;
		}

		button {
			width: 160px;
		}
	}

	.open-titles-table,
	.open-orders-table {
		margin: 0px -20px;
		height: calc(100% - 110px);
	}
}

.device-mobile .client-finance-details-tab {
	margin: 0px -20px;
	overflow-y: overlay;
	padding-bottom: 20px;
	height: calc(100vh - 260px);

	.client-finance-details-tab-header-actions{
		padding: 0px;
	}

	.open-titles-table,
	.open-orders-table {
		height: calc(100% - 60px);
	}
}