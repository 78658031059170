@import '../colors/variables.scss';

/*************************************/
/*************************************/
/*********** FONT COLORS *************/
/*************************************/
/*************************************/

.dark-theme {
	.equips-main-content .equips-header-content .options-list-area .equips-main-more-options .MuiSvgIcon-root {
		color: $whiteF8-color;
	}

	.input-search-field input.MuiInputBase-input {
		color: $blue5D-color;
	}

	.equip-add-main-content h4 {
		color: $whiteF8-color;
	}

	.main-form-content {
		color: $whiteF8-color;

		.edit-account-options-password {
			color: $blue5D-color;
		}

		.form-item-content {
			input,
			textarea {
				color: $whiteF8-color;
			}

			input::placeholder,
			textarea::placeholder {
				color: $grey6A-color;
			}

			input:disabled,
			textarea:disabled {
				color: $grey51-color;
				-webkit-text-fill-color: $grey51-color;
				opacity: 1;
			}

			.Mui-disabled {
				.MuiRadio-colorSecondary,
				.MuiRadio-colorSecondary.Mui-checked {
					color: $blue02-80-color !important;
				}
			}

			.form-item-content-validator {
				span {
					color: $redFF-color;
				}
			}
		}

		.form-item-content.form-disabled {
			color: $grey76-color;
		}

		.form-content-validator {
			.form-item-content-validator {
				span {
					color: $redFF-color;
				}
			}
		}
	}

	.main-web-menu {
		color: $whiteF8-color;
	}

	.main-mobile-menu {
		color: $whiteF8-color;
	}

	.main-mobile-menu-options .menu-option-item {
		color: $whiteF8-color;
	}

	.mobile-page-header-content {
		.page-title-content {
			color: $grey33-color;
		}

		.mobile-navigator-component {
			color: $blue5D-color;
		}
	}

	.mobile-page-header-content .page-title-content h4,
	.page-title-content h5 {
		color: $blue5D-color;
	}

	.user-area-selector span {
		color: $blue5D-color;
	}

	.user-area-selector .user-area-selector-item:hover span {
		color: $blue5D-color;
	}

	.client-area-selector .client-area-selector-header {
		color: $blue5D-color;
	}

	.client-area-selector .client-configs {
		color: $whiteF8-color;
	}

	.client-area-selector-header span {
		color: $whiteF8-color;
	}

	.service-order-address-content .MuiRadio-colorSecondary,
	.service-order-address-content .MuiRadio-colorSecondary.Mui-checked,
	.service-order-feedback-content .MuiRadio-colorSecondary,
	.service-order-feedback-content .MuiRadio-colorSecondary.Mui-checked,
	.client-selector-list .MuiRadio-colorSecondary,
	.client-selector-list .MuiRadio-colorSecondary.Mui-checked {
		color: $whiteF8-color;
	}

	.open-service-order-content .service-order-address-content .service-order-equip-name {
		color: $whiteF8-color;
	}
	.service-order-address-selector .MuiTypography-body1 {
		color: $whiteF8-color;
	}

	.client-selector-list .MuiFormControlLabel-root {
		color: $whiteF8-color;
	}

	.client-area-selector.mobile .client-area-selector-header {
		color: $whiteF8-color;
	}

	.client-area-selector.mobile .client-configs {
		color: $whiteF8-color;
	}

	.client-area-selector.mobile .client-selector-list .MuiTypography-root,
	.client-area-selector.mobile .client-selector-list .MuiRadio-colorSecondary,
	.client-area-selector.mobile .client-selector-list .MuiRadio-colorSecondary.Mui-checked,
	.client-area-selector.mobile .input-search-field input.MuiInputBase-input,
	.client-area-selector.mobile .input-search-field input.MuiOutlinedInput-input::placeholder,
	.client-area-selector.mobile .input-search-field .MuiSvgIcon-root {
		color: $whiteF8-color;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus {
		-webkit-text-fill-color: $whiteF8-color !important;
		-webkit-box-shadow: 0 0 0px 1000px $blue46-color inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	@media screen and (min-width: 901px) {
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus {
			-webkit-text-fill-color: $blue5D-color !important;
			-webkit-box-shadow: 0 0 0px 1000px $grey20-color inset;
			transition: background-color 5000s ease-in-out 0s;
		}
	}

	.client-area-selector.mobile .client-area-list .loading-main-content span {
		color: $whiteF8-color;
	}

	.client-area-selector.mobile .client-area-list .loading-main-content .MuiCircularProgress-colorPrimary {
		color: $whiteF8-color;
	}

	.input-search-field .MuiSvgIcon-root {
		color: $blue5D-color;
	}

	.input-search-field input.MuiOutlinedInput-input::placeholder {
		color: $blue5D-color;
	}

	.input-search-field div.MuiOutlinedInput-adornedStart:hover {
		color: $blue5D-color;
	}

	.item-selector {
		color: $grey70-color;
		border-color: $grey70-color;
		transition: 500ms;
	}

	.item-selector.active {
		color: $blue5D-color;
		border-color: $blue5D-color;
		box-shadow: 0px 0px 6px $blue5D-color;
	}

	.item-selector:hover {
		box-shadow: 0px 0px 6px $grey70-color;
	}

	.dropdown-content .MuiInputBase-root {
		color: $blue5D-color;
	}

	.dropdown-content .MuiSelect-icon {
		color: $blue5D-color;
	}

	.briefcase-icon {
		fill: $grey75-color;
	}

	.addEquip-icon {
		fill: $blue5D-color;
	}

	.loading-main-content span {
		color: $grey76-color;
	}

	.loading-main-content .MuiCircularProgress-colorPrimary {
		color: $blue5D-color;
	}

	.empty-main-content span {
		color: $grey76-color;
	}

	.has-error-content span,
	.has-error-content h4 {
		color: $grey76-color;
	}

	.has-error-content .refresh-button {
		color: $blue5D-color;
		border-color: $blue5D-color;
	}

	.paginator-main-content .MuiPaginationItem-page {
		color: $grey20-color;
	}

	.paginator-main-content .MuiPaginationItem-ellipsis {
		color: $grey76-color;
	}

	.paginator-main-content .MuiPaginationItem-page.Mui-selected {
		color: $grey20-color;
	}

	.paginator-main-content .MuiPaginationItem-page:hover {
		color: $blue5D-color;
	}

	.status-circle svg {
		color: $whiteF8-color;
		fill: $whiteF8-color;
	}

	.orders-header-content .filter-option {
		color: $blue5D-color;
		transition: 500ms;
	}

	.orders-header-content .filter-option:hover {
		opacity: 0.5;
	}

	.orders-filter-content .clear-filter span {
		color: $blue5D-color;
		transition: 500ms;
	}

	.orders-filter-content .clear-filter:hover span {
		opacity: 0.5;
	}

	.orders-filter-content .period-filter-header span,
	.orders-filter-content .status-filter span,
	.orders-filter-content .defect-filter span {
		color: $whiteF8-color;
	}

	.orders-filter-content .defect-filter .input-search-field input.MuiOutlinedInput-input {
		color: $whiteF8-color;
	}

	.orders-filter-content .period-filter .period-filter-fields .MuiFormLabel-root,
	.orders-filter-content .period-filter .period-filter-fields .MuiFormLabel-root.Mui-focused {
		color: $whiteF8-color;
	}

	.orders-filter-content .period-filter .period-filter-fields .MuiInputBase-root {
		color: $grey76-color;
	}

	.orders-filter-content .period-filter .period-filter-fields .MuiIconButton-label {
		color: $grey76-color;
	}

	.orders-filter-content .period-filter .period-filter-fields .MuiFormHelperText-root.Mui-error {
		color: $orangeE5-color;
	}

	.orders-filter-content .status-filter .status-filter-fields .MuiCheckbox-colorSecondary.Mui-checked {
		color: $blue5D-color;
	}

	.orders-list-header .list-header {
		color: $whiteF8-color;
	}

	.orders-list-items .list-item.web .order-info div span,
	.order-info-row .equip span {
		color: $whiteF8-color;
	}

	.orders-list-items .list-item.mobile .order-info .order-info-row .mobile-label,
	.protocol,
	.status,
	.created {
		color: $whiteF8-color;
	}

	.orders-list-header .list-header .MuiSvgIcon-root {
		color: $grey76-color;
	}

	.orders-list-header .list-header .MuiSvgIcon-root:hover {
		color: $blue5D-color;
	}

	.orders-status-label .status-label span {
		color: $whiteF8-color;
	}

	.modal-component {
		.modal-header-content {
			color: $whiteF8-color;

			.modal-header-close-content {
				color: $grey27-color;
			}
		}
	}

	.equip-orders-list-content {
		color: $whiteF8-color;
		background-color: $grey20-color;
	}

	.custom-button-primary,
	.custom-button-secondary {
		color: $whiteF8-color;
	}

	.notify-center-main-content {
		.notify-center-header {
			color: $whiteF8-color;

			.notify-center-close {
				.MuiSvgIcon-root {
					color: $grey33-color;
				}
			}
		}

		.notify-center-option {
			color: $blue5D-color;
		}
		.notify-center-footer {
			color: $whiteF8-color;
		}
	}

	.notify-center-mobile-content {
		.notify-center-header {
			color: $whiteF8-color;
		}

		.go-back {
			color: $whiteF8-color;
		}

		.notify-center-search {
			color: $whiteF8-color;

			.input-search-field input.MuiInputBase-input,
			.input-search-field .MuiSvgIcon-root,
			.input-search-field input.MuiOutlinedInput-input::placeholder {
				color: $whiteF8-color;
			}
		}
	}

	.notify-list-item-mobile,
	.notify-list-item {
		.title {
			span {
				color: $whiteF8-color;
			}
		}

		.notify-item-menu {
			color: $blue5D-color;
		}

		.options {
			.mark-as,
			.unmark-as {
				color: $blue5D-color;
			}

			.notify-time {
				color: $whiteF8-color;
			}
		}
	}
	.notifications-list-content .notifications-list-header .list-header {
		color: $whiteF8-color;
	}

	.notifications-main-content .notifications-header-content .options-list-area .notification-more-options .MuiSvgIcon-root {
		color: $whiteF8-color;
	}

	.notifications-list-items {
		.notifications-list-item {
			color: $whiteF8-color;

			.options .MuiButton-root {
				color: $whiteF8-color;
			}
		}
	}

	.notifications-main-content .notifications-header-content .title-page-area {
		color: $blue5D-color;
	}

	.notification-message-alert {
		color: $grey76-color;
	}

	.equip-web-info-content {
		.equip-info-name {
			color: $blue5D-color;
		}

		.equip-info-location,
		.equip-info-warrant {
			color: $whiteF8-color;
		}

		.equip-open-service {
			color: $blue5D-color;

			.briefcase-icon {
				fill: $blue5D-color;
			}
		}
	}

	.equip-mobile-info-content {
		.equip-info-warrant span {
			color: $blue5D-color;
		}
		.equip-info-warrant b {
			color: $blue5D-color;
		}
		.mobile-navigator-component {
			color: $blue5D-color;
		}

		.equip-info-extra .equip-info-location span {
			color: $whiteF8-color;
		}

		.equip-info-location,
		.equip-info-warrant {
			color: $grey33-color;
		}

		.equip-open-service {
			color: $blue5D-color;

			.briefcase-icon {
				fill: $blue5D-color;
			}
		}
	}

	.equip-orders-list-items,
	.equip-orders-list-mobile-items {
		.list-item:nth-child(odd) {
			color: $whiteF8-color;
		}
		.list-item .order-info div {
			color: $whiteF8-color;
		}
	}

	.equip-list-content .equip-list-header .list-header {
		color: $whiteF8-color;
	}

	.equip-list-items .list-item.web .equip-info {
		color: $whiteF8-color;
	}

	.equip-list-items .list-item.mobile .equip-info .equip-info-row {
		color: $whiteF8-color;
	}

	.equip-orders-list-mobile-content {
		.equip-orders-title {
			color: $whiteF8-color;
		}
		.equip-orders-list-header .list-header {
			color: $whiteF8-color;
		}
	}

	.service-order-web-info-content {
		.service-order-equip {
			color: $blue5D-color;
		}

		.service-order-info-location,
		.service-order-info-protocol,
		.service-order-info-visit,
		.service-order-info-tech-name,
		.service-order-info-tech-contact {
			color: $whiteF8-color;
		}

		.service-order-equip-files {
			span,
			svg {
				color: $blue5D-color;
				fill: $blue5D-color;
			}
		}
	}

	.service-order-mobile-info-content {
		.mobile-navigator-component {
			color: $blue5D-color;
		}

		.service-order-info-location,
		.service-order-info-warrant,
		.service-order-info-extra {
			color: $whiteF8-color;
		}

		.service-order-equip-files {
			span,
			svg {
				color: $blue5D-color;
				fill: $blue5D-color;
			}
		}

		.service-order-contact {
			.contact-area {
				.call-app-button {
					color: $whiteF8-color;
				}

				.whats-app-button {
					color: $blue5D-color;
				}
			}

			.service-order-tech-numbers-modal {
				.tech-number-option {
					color: $whiteF8-color;
				}
			}
		}
	}

	.config-user-info {
		span {
			color: $whiteF8-color;
		}
	}

	.service-order-timeline-file-link {
		div {
			span {
				color: $blue5D-color;
			}
		}
	}

	.service-order-timeline-file-link div:hover {
		span {
			color: $whiteF8-color;
		}
	}

	.service-orders-files-center-content {
		.files-center-description-content {
			color: $whiteF8-color;
		}

		.files-center-header-content {
			color: $whiteF8-color;
		}

		.files-center-list-content {
			.files-center-list-item-content {
				color: $whiteF8-color;
			}
		}
	}

	.response-modal-web {
		color: $whiteF8-color;

		.modal-header-content {
			color: $whiteF8-color;
		}
	}

	.response-modal-mobile {
		color: $whiteF8-color;

		.modal-header-image .modal-header-close-content .MuiSvgIcon-root {
			color: $blue5D-color;
		}

		.modal-main-content span {
			color: $whiteF8-color;
		}

		.modal-action-content {
			button {
				color: $whiteF8-color;
			}
		}
	}

	.upload-file-main-content {
		.upload-file-action {
			span,
			svg {
				color: $blue5D-color;
			}
		}

		.upload-file-list-item {
			span {
				color: $blue5D-color;
			}

			.upload-file-list-item-remove {
				.MuiSvgIcon-root {
					color: $whiteF8-color;
				}
			}
		}
	}

	.service-order-feedback-content {
		.feedback-question-order,
		.form-item-content-line {
			color: $blue5D-color;
		}
	}

	.service-order-timeline-content {
		color: $whiteF8-color;
	}

	.edit-account-content {
		.edit-account-options {
			color: $blue5D-color;
			span {
				color: $blue5D-color;
			}
		}
	}

	.mobile-settings-main-content,
	.mobile-app-settings-main-content,
	.mobile-edit-account-content {
		color: $grey33-color;
	}

	.mobile-app-settings-item label {
		color: $whiteF8-color;
	}

	.mobile-edit-account-content {
		.main-header {
			button {
				color: $whiteF8-color;
			}

			.edit-button {
				color: $whiteF8-color;
			}
		}
	}

	.mobile-help-main-content,
	.web-help-main-content {
		color: $whiteF8-color;
		.help-contact-area-content {
			.email-button,
			.tutorial-button {
				color: $whiteF8-color;
			}
		}

		.help-contact-area-action {
			a {
				color: $whiteF8-color;
			}
		}
	}

	.app-settings-main-content {
		.app-settings-item label {
			color: $whiteF8-color;
		}
	}

	.stores-settings-main-content {
		.stores-settings-header-content {
			.title-page-area {
				color: $blue5D-color;
			}
			.stores-view-type {
				color: $whiteF8-color;

				.MuiRadio-colorSecondary,
				.MuiRadio-colorSecondary.Mui-checked {
					color: $whiteF8-color;
				}
			}
		}
		.stores-settings-mobile-header-content {
			color: $whiteF8-color;

			.input-search-field .MuiSvgIcon-root,
			.input-search-field input.MuiOutlinedInput-input,
			.input-search-field input.MuiOutlinedInput-input::placeholder {
				color: $whiteF8-color;
			}

			.input-search-field div.MuiOutlinedInput-adornedStart:hover {
				color: $whiteF8-color;
			}

			input:-webkit-autofill,
			input:-webkit-autofill:hover,
			input:-webkit-autofill:focus {
				-webkit-text-fill-color: $whiteF8-color !important;
				-webkit-box-shadow: 0 0 0px 1000px $blue46-color inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}

		.stores-settings-mobile-list-options {
			.stores-view-type {
				color: $whiteF8-color;

				.MuiRadio-colorSecondary,
				.MuiRadio-colorSecondary.Mui-checked {
					color: $whiteF8-color;
				}
			}
		}

		.stores-settings-mobile-list-content,
		.stores-settings-list-content {
			.stores-settings-list-header {
				color: $whiteF8-color;
			}

			.stores-settings-mobile-list-item,
			.stores-settings-list-item {
				color: $whiteF8-color;

				.MuiCheckbox-colorSecondary,
				.MuiCheckbox-colorSecondary.Mui-checked {
					color: $whiteF8-color;
				}
			}
		}

		.stores-settings-mobile-list-content {
			.stores-settings-mobile-list-items {
				color: $whiteF8-color;
			}
		}
	}

	.custom-button-primary.outline-button,
	.custom-button-prisecondaryary.outline-button {
		color: $whiteF8-color;
		border-color: $whiteF8-color;
	}

	.equip-add-main-content {
		h4 {
			color: $whiteF8-color;
		}

		.equip-add-conflict-message {
			color: $whiteF8-color;
		}
	}

	.empty-user-main-content {
		.empty-user-main {
			.empty-user-message {
				color: $greyD7-color;
			}

			.empty-user-actions {
				span {
					color: $whiteF8-color;
				}

				.MuiSvgIcon-root {
					color: $whiteF8-color;
				}
			}
		}
	}

	.page-list-options-content {
		.icon-button-action {
			color: $blue5D-color;
			.MuiSvgIcon-root {
				color: $blue5D-color;
			}
		}
	}

	.paginator-main-content {
		.MuiPagination-root .MuiPagination-ul {
			li:first-child,
			li:last-child {
				.MuiSvgIcon-root {
					color: $greyD7-color !important;
					fill: $greyD7-color !important;
				}
			}
		}
	}
}

/** ESPECIAL STYLES FOR MATERIAL-UI DROPDOWN **/

body.dark-theme {
	.toggle-input {
		div {
			color: $whiteF8-color !important;
		}
	}

	.modal-main-content-success {
		.modal-component {
			.modal-main-content {
				color: $whiteF8-color;
			}

			.modal-header-content {
				color: $whiteF8-color;

				.modal-header-close-content {
					color: $grey27-color;
				}
			}
		}
		.modal-component-tutorial .modal-header-content-tutorial {
			color: $whiteF8-color;
		}
	}

	.address-checkbox {
		color: $whiteF8-color;
	}

	.MuiDialog-root .MuiPickersToolbar-toolbar {
		color: $whiteF8-color;
	}

	.MuiList-root.MuiMenu-list.MuiList-padding .MuiMenuItem-root {
		color: $blue5D-color;
	}

	.MuiListItem-button {
		color: $whiteF8-color;
	}

	.MuiDialog-root .MuiPaper-root,
	.MuiDialog-root .MuiPickersCalendarHeader-iconButton,
	.MuiDialog-root .MuiIconButton-label,
	.MuiDialog-root .MuiPickersCalendarHeader-dayLabel,
	.MuiDialog-root .MuiPickersBasePicker-pickerView {
		color: $whiteF8-color;
	}

	.MuiDialog-root .MuiPickersDay-daySelected {
		color: $whiteF8-color;
	}

	.MuiDialog-root .MuiPickersDay-daySelected:hover {
		color: $whiteF8-color;
	}

	.dropdown-list-item.dark-theme {
		color: $whiteF8-color;
	}

	#defect-field-selector-popup {
		color: $whiteF8-color;
	}

	.MuiDialog-root .MuiPickersDay-current {
		color: $blue5D-color;
	}

	.MuiDialog-root .MuiPickersDay-daySelected {
		color: $whiteF8-color;

		.MuiDialog-root .MuiPickersDay-current {
			color: inherit;
		}
	}

	.MuiDialogActions-root .MuiButton-root.MuiButton-textPrimary {
		color: $blue5D-color;
	}

	#update-notification-bar {
		color: $blue5D-color;
	}

	.version-area {
		color: $whiteF8-color;
	}
}
