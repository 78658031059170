.transfers-details-progress-content {
	width: 600px;
	height: auto;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;

	.loading-main-content {
		width: 350px;
		height: 100px;
		background-color: #fff;
		.loading-main span {
			margin-top: 20px;
		}
	}
}

.device-mobile .transfers-details-progress-content {
	width: calc(100vw - 40px);
}
