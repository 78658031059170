.university-pratica-card {
	justify-content: flex-start !important;
	background-size: auto;
	background-color: #f8f9fa;
	background-repeat: no-repeat;
	background-position-x: right;
	background-position-y: bottom;
	background-image: url('../../../../../assets/image/book2.png') !important;
}

.university-pratica-card-title {
	margin-bottom: 20px;
}

.university-pratica-card-main-contain {
	height: 100%;

	.courses-container {
		font-weight: bold;
		font-size: 14px;
		margin-top: 24px;
		.courses {
			font-weight: normal;
			font-size: 13px;
		}
	}
}

.image-book {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
	img {
		right: -20px;
		bottom: -20px;
		height: 182px;
		position: absolute;
	}
}
