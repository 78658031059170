.response-by-address {
  padding: 20px 10px 0;

}

.modal-change-address {
  height: auto;
}

.container-f {
  gap: 5px;
  width: 100%;
  padding: 20px;
  display: flex;
  margin: auto;
  padding: 20px;
  border-radius: 2px;
  border-style: solid;
  border-width: 0.5px;
  background: #ffffff;
  border-image-slice: 1;
  background-origin: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-image-source: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.02) 95%,
      rgba(0, 0, 0, 0.04));
  background-image: #ffffff,
    linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.02) 95%,
      rgba(0, 0, 0, 0.04));
}

.item-f {
  margin: 5px;
  color: #194883;
}

.text-align-l {
  text-align: left;
}