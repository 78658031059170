.service-order-equipment-edit {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.service-order-equipment-edit-content {
	overflow: none;
	height: auto;
	width: 550px;
	height: 700px;
	margin-top: 30px;
	margin-right: 20px;
	margin-left: 20px;
	border-radius: 3px;
	background-color: #fff;
	text-align: justify;
}

.service-order-equipment-edit-body {
	margin: 20px;
}

.service-order-equipment-edit-form {
	display: flex;
}

.service-order-equipment-edit-header {
	position: relative;
	padding: 20px;
	width: 100%;
}

.service-order-equipment-edit-header h4 {
	margin: 0px;
}

.service-order-equipment-edit-header-close {
	position: absolute;
	top: 15px;
	right: 15px;
}

.modal.udpateEquip {
	width: 100%;
	display: block;
}

.main-update-equip-container {
	width: 650px;
	height: 200px;
	position: relative;
}

.device-mobile .main-update-equip-container {
	height: 100%;
	width: 100%;
}

.main-update-equip-loading {
	min-height: 60px;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	font-weight: 400;
}

.main-update-equip-loading .loading-list div {
	margin-top: 0px;
}
