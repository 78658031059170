.mobile-help-main-content {
	position: absolute;
	width: 100vw;
	height: calc(var(--app-height) - 48px);
	top: 0;
	right: 0;
	z-index: 25;
	overflow-y: auto;

	.help-contact-area {
		padding: 20px;
		border-bottom: 2px solid;
	}

	.help-contact-area-title {
		margin-bottom: 10px;

		h5 {
			letter-spacing: 0.2px;
			font-weight: 500;
			font-size: 13px;
			margin: 0px;
		}
	}

	.help-contact-area-action {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			width: 156px;
			height: 30px;
			text-align: center;
			border-radius: 6px;
			text-align: center;
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.18px;
			border: 1px solid;
			padding: 1px 6px 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		a,
		a:hover,
		a:active,
		a:visited {
			text-decoration: none;
		}
	}

	.help-contact-area-content {
		margin-bottom: 20px;

		.phone-button,
		.phone-content,
		.email-button,
		.email-content,
		.tutorial-content,
		.tutorial-button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			height: 40px;
		}

		.phone-button,
		.email-button {
			height: 25px;
			width: 70px;
			border: none;
			text-align: center;
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			border: 1px solid;
			padding: 1px 6px 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			a {
				letter-spacing: 0.18px;
				font-weight: 400;
				font-size: 12px;
			}

			svg {
				width: 15px;
				height: 15px;
				margin-right: 4px;
				margin-top: 1px;
			}
		}

		.tutorial-button {
			height: 25px;
			width: 70px;
			border: none;
			text-align: center;
			border-radius: 4px;
			text-align: center;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.18px;
			border: 1px solid;
			padding-right: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			a {
				letter-spacing: 0.18px;
				font-weight: 400;
				font-size: 12px;
			}

			svg {
				width: 30px;
				height: 30px;
				margin-top: 1px;
				margin-right: -4px;
			}
		}

		.phone-content,
		.email-content,
		.tutorial-content,
		.tutorial-button {
			span {
				letter-spacing: 0.18px;
				font-weight: 400;
				font-size: 12px;
			}
		}

		a,
		a:hover,
		a:active,
		a:visited {
			text-decoration: none;
		}
	}

	.help-form-area {
		padding: 20px;
		height: calc(100% - 250px);
		overflow-y: auto;

		.help-form-area-content {
			width: 100%;
			height: 100%;
		}

		h5 {
			letter-spacing: 0.2px;
			font-weight: 500;
			font-size: 13px;
			margin: 0px 0px 10px;
		}

		.main-form-content {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
		}

		.main-form-content .form-item-content {
			min-height: 70px;
			height: auto;

			.characters-remaining{
				font-size: 10px;
				font-weight: 400;
				letter-spacing: 0.18px;
			}

			textarea {
				height: 110px !important;
				font-weight: 400;
			}

			.dropdown-content .MuiInputBase-root,
			.dropdown-content .MuiSelect-select {
				width: 100%;
				max-width: 100%;
			}
		}

		.help-form-action {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
		}
	}
}

.help-form-dropdown {
	width: calc(100% - 32px);

	.MuiMenu-paper {
		width: calc(100% - 32px);
	}
}
