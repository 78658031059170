/* ----------------------------------- */
/* ---------Universal Colors---------- */
/* ----------------------------------- */

// White Colors
$whiteFF-color: #ffffff;
$whiteF8-color: #f8f9fa;
$whiteF2-color: #f2f2f2;

// Blue Colors
$blueF1-color: #f1f5fd;
$blueD0-color: #d0dff0;
$blue5D-color: #5daeff;
$blue2F-color: #2f72cf;
$blue02-color: #025cb7;
$blue0b-color: #0b5591;
$blue00-color: #004992;
$blue28-color: #283b50;
$blue46-color: #004687;
$blue49-color: #004987;
$blue1e-color: #001e87;

// Blue with Opacity Colors
$blue02-80-color: #025db780;
$blue02-2D-color: #025db72d;
$blueF1-15-color: #f1f5fd15;

// Grey Colors
$greyE5-color: #e5e5e5;
$greyD8-color: #d8d8d8;
$greyC9-color: #c9c9c9;
$greyA6-color: #a6a6a6;
$grey76-color: #767676;
$grey75-color: #757575;
$grey70-color: #707070;
$grey6A-color: #6a6a6a;
$grey51-color: #515151;
$grey33-color: #333333;
$grey20-color: #202020;
$grey27-color: #272727;

// Black Colors
$black00-color: #333333;
$black00-29-color: #00000029;

// Black with Opacity Colors
$black00-69-color: #00000069;

// Black with Opacity Colors
$green04-color: #048243;

$aqua17-color: #17a2b8;

$orangeE5-color: #e54529;

$redC2-color: #c21618;

//Error Warning
$white-e5-color: #ffe5e5;

/* ----------------------------------- */
/* ---------Universal Colors---------- */
/* ----------------------------------- */

// White Colors
$whiteFF-color: #ffffff;
$whiteF8-color: #f8f9fa;
$whiteF2-color: #f2f2f2;

// Blue Colors
$blueF1-color: #f1f5fd;
$blueD0-color: #d0dff0;
$blue5D-color: #5daeff;
$blue2F-color: #2f72cf;
$blue02-color: #025cb7;
$blue0b-color: #0b5591;
$blue0C-color: #0c4992;
$blue00-color: #004687;
$blue46-color: #004687;
$blue1e-color: #1e4883;
$blue2D-color: #2d445f;
$blue28-color: #283b50;
$blue49-color: #004992;
$blue2f-color: #2f72cf;
$blue57-color: #57a7f9;

// Blue with Opacity Colors
$blue02-80-color: #025db780;
$blue02-2D-color: #025db72d;
$blueF1-15-color: #f1f5fd15;

// Grey Colors
$greyE5-color: #e5e5e5;
$greyD8-color: #d8d8d8;
$greyD7-color: #d7d7d7;
$greyC9-color: #c9c9c9;
$greyA6-color: #a6a6a6;
$grey76-color: #767676;
$grey75-color: #757575;
$grey70-color: #707070;
$grey6A-color: #6a6a6a;
$grey51-color: #515151;
$grey33-color: #333333;
$grey27-color: #272727;
$grey20-color: #202020;

// Black Colors
$black00-color: #000000;

// Black with Opacity Colors
$black00-69-color: #00000069;
$black00-29-color: #00000029;

// Black with Opacity Colors
$green04-color: #048243;

$aqua17-color: #17a2b8;

$orangeE5-color: #e54529;

$redC2-color: #c21618;
$redFF-color: #ffe5e5;
