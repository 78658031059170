.transfers-header-content {
	width: 100%;

	.transfers-header-title {
		margin: 0 0 20px;

		h3 {
			margin: 0px;
			padding: 0px;
			font-size: 30px;
			font-weight: 700;
			line-height: 30px;
		}
	}

	.options-list-area {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		.search-light {
			width: 400px;
		}

		button {
			width: 200px;
		}

	}
}

.device-mobile .transfers-header-content {
	width: 100%;
	margin-bottom: 10px;

	.transfers-header-title {
		margin: 0px 0px 20px;
		display: flex;
		align-items: center;

		h3 {
			margin: 0px;
			padding: 0px;
			font-size: 30px;
			font-weight: 700;
		}
	}

	.options-list-area {
		column-gap: 20px;
		.search-light {
			width: 65%;
		}

		button {
			font-size: 12px;
		}

	}
}
