.recover-account-form {
	width: 100%;
	height: auto;
	position: relative;

	.recover-account-form-content {
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	.recover-account-form-actions {
		margin-top: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;

		button {
			width: calc(50% - 10px);
			height: 36px;
			border-radius: 6px;
			border: none;
			letter-spacing: 0.2px;
			font-size: 13px;
			font-weight: 500;
			cursor: pointer;
		}
	}
}
