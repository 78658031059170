.header-new-visit-contain {
	display: flex;
	width: 760px;
	flex-direction: column;
	.form-header {
		text-align: left;
	}

	.data-contain {
		width: 100%;
		display: flex;
		max-width: 270px;
		justify-content: left;
		flex-direction: column;

		span {
			display: flex;
			font-size: 12px;
			font-weight: 700;
			margin-bottom: 10px;
		}
	}
}

.device-mobile .header-new-visit-contain {
	position: relative;
	width: 100%;
	height: 100%;

	.divider-component {
		margin-top: 10px;
	}

	.data-contain {
		width: 100%;
		max-width: 100%;
		margin-right: 0px;
	}
	.new-visit-button-action {
		display: flex;
		width: 100%;
		column-gap: 20px;
		justify-content: center;
		position: absolute;
		bottom: 0px;
	}
	.form-header .client-header-information {
		row-gap: 10px;
		flex-direction: column;

		div {
			width: 100%;
		}
	}
	.new-visit-technician-information .new-visit-select-technician .selected-technician-information .selected-technician-information-contact {
		margin-top: 10px;
		grid-template-columns: 1fr;
	}
}

.new-visit-technician-information {
	display: flex;
	margin-top: 15px;
	column-gap: 20px;
	row-gap: 20px;
	text-align: left;
	height: 64px;
	margin-bottom: 40px;
	align-items: flex-end;

	.new-visit-select-technician-action {
		display: flex;
		cursor: pointer;
		align-items: center;
		label {
			padding: 0px;
			cursor: pointer;
			margin-bottom: 0px;
		}
	}
}
.new-visit-select-technician {
	width: 100%;
	display: flex;
	padding: 10px;
	max-width: 470px;
	position: relative;
	border-radius: 3px;
	border: solid 1px #025cb7;

	.selected-technician-information {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.selected-technician-information-name {
			font-size: 13px;
			font-weight: 700;
			width: calc(100% - 87px);
		}

		.selected-technician-information-contact {
			display: grid;
			column-gap: 10px;
			font-size: 12px !important;
			font-weight: 400 !important;
			grid-template-columns: 0.6fr 1fr 1fr;
		}
	}
	.change-selected-technician-button {
		position: absolute;
		right: 10px;
	}
}

.new-visit-button-action {
	display: flex;
	column-gap: 20px;
	justify-content: center;
}
