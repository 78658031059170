.main-content-module {
	height: 100%;
	width: 100%;
	display: flex;
	position: fixed;

	#nav {
		display: flex;
		background-color: #fff;
	}

	.navHorizontal {
		display: flex;
		width: 100%;
		height: 65px;
		background-color: #d0dff0;
		position: absolute;
		left: 0;
		z-index: 1;
	}
}

.image-background {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: block;
	overflow: auto;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	background-color: red;
}

@media screen and (max-width: 991px) {
	.image-background {
		position: static;
	}
}

@media screen and (max-width: 767px) {
	.image-background {
		width: 100%;
		padding: 70px 0px 0px;
	}
}

body.modal-open {
	overflow: hidden;
}

.arrow {
	opacity: 0;
	transition: opacity 0.5s linear;
}

.btnShowMenu:hover .arrow {
	opacity: 1;
	transition: opacity 0.5s linear;
}

.btnShowMenu:not(:hover) .arrow {
	opacity: 0;
	transition: opacity 0.5s linear;
}

.arrowBtn:focus {
	outline: none;
}

.arrowBtn {
	background: transparent;
	padding: 0px;
}

.btnShowMenu {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	width: 30px;
	height: 100vh;
	padding-right: 5px;
	border-radius: 0px 30px 30px 0px;
	z-index: 2;
	right: 0px;
}

.sideMenu {
	display: flex;
	position: relative;
	top: 0%;
	right: auto;
	bottom: 0%;
	width: auto;
	max-width: 250px;
	background-color: #004687;
	border-radius: 0px 35px 35px 0px;
	z-index: 2;
	transition: all 0.4s ease-in-out 0s;
}

.sideMenu:hover {
	box-shadow: rgb(255 255 255 / 20%) -40px 0px 30px -20px inset;
}

.section {
	text-transform: none;
	background-color: #fff;
	padding: 85px 20px 0px;
	position: relative;
	transition: width 0.4s;
	width: calc(100% - 250px);
}

.section-menu-hide {
	width: calc(100% - 100px);

	a {
		color: var(--primary-highlight) !important;
	}
}

.main-page-component {
	width: 100vw;
	padding: 20px;
	position: fixed;
	max-width: 100vw;
	height: calc(var(--app-height) - 70px);
	max-height: calc(var(--app-height) - 70px);
}

.device-mobile .main-page-component {
	padding-bottom: 0px;
}
