.service-order-close-os {
	height: 480px;
	position: relative;
	.service-order-close-os-main-contain {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
}
.device-mobile .service-order-close-os {
	height: 100%;
}
