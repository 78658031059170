.notificationsContent {
	top: 55px;
	right: 110px;
	width: 400px;
	height: 500px;
	border-radius: 6px;
	position: absolute;
	background-color: #fff;
	box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.65);
}

.device-mobile .notificationsContent {
	right: 15px;
	top: initial;
	bottom: 70px;
	position: absolute;
	width: calc(100vw - 30px);
	height: calc(var(--app-height) - 35%);
}

.notify-center-header {
	position: relative;
	background-color: #2f72cf;
	font-size: 20px;
	font-weight: 600;
	color: #fff;
}

.requestCancelNotify {
	background-color: #c216181a !important;
}

.notify-center-close {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px;
	color: #fff;
	cursor: pointer;
}

.notify-center-option,
.notify-center-header,
.notify-center-footer {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notify-center-option,
.notify-center-footer {
	background-color: #f1f5fd;
	color: #2f72cf;
	font-size: 12px;
	font-weight: 500;
	text-align: center;
	cursor: pointer;
}

.notify-center-option.disabled {
	cursor: default;
	color: #2f72cf52;
}

.notify-center-footer {
	text-transform: uppercase;
}

.notify-center-content {
	height: calc(100% - 150px);
	overflow-y: scroll;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #f8f8f8;
}

.notification-item {
	width: 100%;
	height: 80px;
	padding: 15px;
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	flex-direction: column;
	cursor: pointer;
	background-color: transparent;
}

.notification-item > div {
	width: 100%;
}

.notification-item:hover {
	background-color: rgba(0, 70, 134, 0.1);
}

.notification-item-title {
	text-align: left;
	color: #333;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.notification-item-title.not-read {
	font-weight: 600;
}

.notification-item-title small {
	color: rgba(0, 0, 0, 0.4);
}

.notification-item-options {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}

.mark-as {
	font-size: 12px;
	color: #2f72cf;
	cursor: pointer;
}

.device-mobile .mark-as {
	white-space: nowrap;
}

.notify-time {
	color: #333;
	opacity: 0.8;
	font-size: 12px;
	text-align: end;
}
