.attachment-category-modal {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 10px;

    .container {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
        align-items: center;
    }
}

.action {
    display: flex;
    gap: 20px;
}
