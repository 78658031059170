.loading-main-content {
  width: 100%;
  height: 100%;
  display: flex;

  .loading-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}