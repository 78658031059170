.modal-main-backdrop-install-and-search {
  background: transparent;
  background-repeat: repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  backdrop-filter: blur(5px);

  .modal__component-install-and-search {
    position: fixed;
    margin-top: 20px;
    border-radius: 27px;
    background-color: #f7f8f8;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%);
    border-image-source: linear-gradient(to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04));

    .modal__component-header {
      height: 60px;
      display: flex;
      position: relative;
      padding-left: 20px;
      align-items: center;
      background-color: #f7f8f8;
      border-radius: 27px 27px 0px 0px;

      .modal__component-header-title {
        font-size: 24px;
        text-align: left;
        font-weight: 700;
        color: #194883;
        text-transform: uppercase;
        max-width: calc(100% - 20px);
      }

      .modal__component-header-close-action {
        right: 20px;
        cursor: pointer;
        position: absolute;
      }
    }

    .modal__component-main {
      padding: 20px;
    }

    .modal__component-footer {
      width: 100%;
      height: 26px;
      display: flex;
      flex-direction: row;

      & :first-child {
        border-radius: 0px 0px 0px 27px;
      }

      & :last-child {
        border-radius: 0px 0px 27px 0px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .modal__component-install-and-search {
      width: 100%;
      border-radius: 1px;
      margin-top: 0;
      height: 100%;

      .modal__component-header-title {
        font-size: 20px !important;
      }

      .modal__component-footer {
        display: none;
      }
    }

    .modal__component-header-close-action {
      display: none;
    }
  }
}