.reset-pass-completed {
	width: calc(100% + 20px);
	height: calc(100% + 20px);
	position: absolute;
	margin: -10px;
	top: 0;
	left: 0;
	z-index: 15;
	background-color: #004687;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	text-align: center;

	h4 {
		font-size: 13px;
		font-weight: 600;
		letter-spacing: 0.3px;
		text-transform: uppercase;
		margin: 40px 20px 30px;
	}

	span {
		text-decoration: underline;
		cursor: pointer;
		margin-bottom: 10px;
		font-size: 13px;
	}
}
