.modal.loading-scheduleOs {
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.schedule-os-preventive {
	position: relative;
	height: auto;
	width: 1000px;
	background-color: #fff;
	padding: 20px 0 20px 20px;
	border-radius: 3px;
	-webkit-box-shadow: 3px 2px 15px 2px rgba(0, 0, 0, 0.65);
	box-shadow: 3px 2px 15px 2px rgba(0, 0, 0, 0.65);
}

.loading-content {
	position: relative;
	height: 200px;
	width: 350px;
	min-width: unset;
	background-color: #fff;
	border-radius: 3px;
	-webkit-box-shadow: 3px 2px 15px 2px rgba(0, 0, 0, 0.65);
	box-shadow: 3px 2px 15px 2px rgba(0, 0, 0, 0.65);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.schedule-os-preventive-header h3 {
	margin-top: 0px;
	margin-bottom: 20px;
	font-size: 28px;
	line-height: 30px;
	font-weight: 500;
	text-align: left;
}

.header-close {
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
}

.header-close svg {
	width: 25px;
	height: 25px;
}

.schedule-os-preventive-list-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 100%;
	height: 30px;
	text-align: left;
	padding: 5px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 3px 3px 0px 0px;
	background-color: #f8f9fa;
}

.schedule-os-preventive-list-header .list-header {
	position: relative;
	float: left;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	font-size: 13px;
	font-weight: 500;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.schedule-os-preventive-list-header .list-header.client,
.schedule-os-preventive-list-items .list-item.client {
	width: 180px;
}

.schedule-os-preventive-list-header .list-header.equip-name,
.schedule-os-preventive-list-items .list-item.equip-name {
	width: 320px;
}

.schedule-os-preventive-list-header .list-header.serial,
.schedule-os-preventive-list-items .list-item.serial {
	width: 110px;
}

.schedule-os-preventive-list-header .list-header.location,
.schedule-os-preventive-list-items .list-item.location {
	width: calc(100% - 610px);
}

.schedule-os-preventive-list-items {
	max-height: 310px;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.device-mobile .schedule-os-preventive-list {
	height: 130px;
	width: 100%;
	overflow-y: auto;
}
.device-mobile .schedule-os-preventive-list-items .list-item-content {
	width: auto;
}

.schedule-os-preventive-list-items .list-item-content {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 100%;
	padding: 5px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.list-item-content .list-item {
	position: relative;
	float: left;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	font-size: 13px;
	font-weight: 400;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.schedule-os-preventive-form {
	width: 100%;
}

.form-item-inline {
	height: 65px;
}

.form-item-columns {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: row;
	height: 65px;
}

.schedule-os-contain {
	height: calc(100% - 70px);
}

.device-mobile .schedule-os-contain {
	height: calc(100% - 70px);
}

.device-mobile .form-item-columns {
	height: auto;
	row-gap: 20px;
	margin-bottom: 20px;
	flex-direction: column;
}

.device-mobile .schedule-os-preventive-form .form-item-columns > div {
	width: 100%;
}

.schedule-os-preventive-form .form-item-columns > div {
	width: calc((100% / 3) - 10px);
}

.form-item-columns > div > input.form-control,
.form-item-columns > div > input,
.form-item-inline > div > input {
	display: block;
	width: 100%;
	padding: 8px 12px !important;
	margin-bottom: 10px !important;
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
	background-color: #ffffff !important;
	border: 1px solid #cccccc !important;
	height: 40px !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
	border-radius: 0px !important;
}

.form-item-inline > div > input:focus-visible,
.form-item-columns > div > input:focus-visible,
.form-item-columns > div > input.form-control:focus-visible,
.form-item-columns > div > input.form-control:focus {
	outline: none !important;
	box-shadow: none !important;
	border-radius: 0px !important;
}

.tech-search-field .MuiFormControl-root {
	width: 100%;
	height: 40px;
	margin: 0px;
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
}

.tech-search-field .MuiInputBase-root {
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
	height: 40px;
}

#tech-search-popup {
	padding: 0px;
	border-radius: 0px !important;
	box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.15) !important;
	max-height: 192px;
}

#tech-search-popup .MuiAutocomplete-option {
	font-size: 14px !important;
	line-height: 1.428571429 !important;
	color: #333333 !important;
	text-transform: uppercase;
}

.tech-search-field .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
	padding: 0px 15px 0px 7px !important;
}

.tech-search-field .MuiOutlinedInput-root {
	border-radius: 0px !important;
}

.tech-search-field .MuiInput-underline:hover,
.tech-search-field .MuiInput-underline:hover:not(.Mui-disabled):before,
.tech-search-field .MuiOutlinedInput-notchedOutline,
.tech-search-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.tech-search-field .MuiInput-underline::before,
.tech-search-field .MuiInput-underline::after {
	border: 1px solid rgba(0, 0, 0, 0.24) !important;
}

.schedule-os-preventive-footer {
	height: 60px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.schedule-os-preventive-footer button {
	background: #004687;
	color: #fff;
	padding: 6px 16px;
	font-size: 0.875rem;
	min-width: 64px;
	font-weight: 500;
	line-height: 1.75;
	border-radius: 4px;
	text-transform: uppercase;
}

.schedule-os-preventive-footer button:disabled {
	opacity: 0.5;
	cursor: default;
}
