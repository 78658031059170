.card-prp {
  margin: auto;
  min-width: 270px;
  padding: 20px;
  position: relative;
  border: 0.5px solid;
  border-radius: 2px;
  background: #ffffff;
  border-image-slice: 1;
  background-origin: border-box;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-image-source: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.02) 95%,
      rgba(0, 0, 0, 0.04));

  .card-flex {
    display: flex;
    flex-direction: row;

    .title-card {
      width: 100%;
      min-width: 230px;
      font-size: 16px;
      font-weight: 700;
      color: #194883;
      text-align: left;
      text-transform: uppercase;
    }

    .label-distance {
      font-size: 14px;
      line-height: 1.43;
      color: rgba(0, 0, 0, 0.54);
      text-align: right;
      flex-grow: 1;
      gap: 5px;
      align-items: center;
    }

    .label-distance-mobile {
      display: none;
      font-size: 13px;
      line-height: 1.43;
      color: rgba(0, 0, 0, 0.54);
      text-align: left;
      gap: 5px;
      align-items: center;
    }
  }

  .body-card-container {
    text-align: left;

    .label-info {
      display: flex;
      font-weight: 400;
      color: #333333;
      margin-top: 15px;
      flex-direction: column;

      .container-phone {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;

        a {
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: #194883;
            text-decoration: underline;
          }
        }
      }

      span {
        text-transform: capitalize;
      }
    }
  }

  .card-foot {
    text-align: left;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-specialization {
      width: 100%;
      font-weight: bold;
      line-height: 1.25;
      color: #333333;
      text-align: left;

      .list-specialization {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 10px;

        .item-specialization {
          display: flex;
          justify-content: center;
          border: #194883 1px solid;
          color: #194883;
          border-radius: 2px;
          padding: 5px;
          flex: 1 0 130px;
        }
      }
    }

    .link-to-call {
      background-color: #194883;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      padding: 10px 20px;
      text-decoration: none !important;
    }

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .modal.tech-phone-list {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
}


@media screen and (max-width: 480px) {
  .link-to-call {
    position: absolute;
    top: 25px;
    right: 20px;
  }

  .label-distance {
    display: none;
  }

  .label-distance-mobile {
    display: flex !important;
  }
}

@media only screen and (min-width: 900px) {
  .card-prp {
    width: 700px;
  }

  .subtitle-card {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #b4b4b4;
  }
}

@media only screen and (max-width: 900px) {
  .card-prp {
    width: 700px;
  }

  .subtitle-card {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #b4b4b4;
  }
}

@media only screen and (max-width: 800px) {
  .card-prp {
    width: 100%;
  }

  .subtitle-card {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b0b0b0;
  }
}

@media only screen and (max-width: 600px) {
  .subtitle-card {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b0b0b0;
  }
}

@media only screen and (max-width: 360px) {
  .subtitle-card {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #b0b0b0;
  }
}