.renew-password-contain {
	width: 340px;
	height: auto;

	.renew-password-form {
		display: flex;
		margin-bottom: 20px;
		flex-direction: column;

		.renew-password-form-item {
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			.renew-password-form-item-descriptive {
				font-size: 12px;
				font-weight: 700;
				margin-bottom: 10px;
			}
		}

		.renew-password-roles-info {
			font-size: 10px;
		}
	}
}

.renew-password-actions {
	display: flex;
	flex-direction: row;
	justify-content: center;

	button {
		width: 108px;
	}

	& :first-child {
		margin-right: 20px;
	}
}

.device-mobile {
	.renew-password-contain {
		width: 100%;
		height: auto;

		.renew-password-form {
			display: flex;
			margin-bottom: 20px;
			flex-direction: column;

			.renew-password-form-item {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;

				.renew-password-form-item-descriptive {
					font-size: 12px;
					font-weight: 700;
					margin-bottom: 10px;
				}
			}

			.renew-password-roles-info {
				font-size: 10px;
				text-align: center;
			}
		}
	}

	.renew-password-actions {
		bottom: 70px;
		position: absolute;
		width: calc(100% - 40px);

		button {
			width: 108px;
		}

		& :first-child {
			margin-right: 20px;
		}
	}
}
