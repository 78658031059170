.service-indicators {
    height: auto;
    min-height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.service-indicators.closed .dots-indicator,
.service-indicators.closed .line-indicator {
    display: none;
}

.circle-indicator {
    position: absolute;
    top: 8px;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background-color: #505050;
}

.line-indicator {
    width: 2px;
    margin-top: 20px;
    min-height: 100%;
    background-color: rgb(133, 133, 133);
}

.line-indicator.first-last {
    min-height: 65%;
    margin-top: 0px;
}

.line-indicator.last {
    min-height: 80%;
    margin: 0px;
}

.dots-indicator {
    display: none;
    height: 20px;
    border-style: dashed;
    border-width: 1px;
    border-color: #858585;
}

.dots-indicator.last {
    display: unset;
}

.internal-message {
    width: 100%;
    text-align: left;
}