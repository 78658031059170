.product-details {
  margin: 0px -20px;
  height: 100%;
  overflow: scroll;

  .product-details-main {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    gap: 20px;

    .body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 20px;

      .body-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .product-details-items {
          max-width: 250px;
          display: flex;
          flex-direction: column;
          flex: 1 0 calc(20% - 20px);

          header {
            font-size: 12px;
            margin-bottom: 10px;
            justify-content: flex-start;
            font-weight: 600;
          }

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.descriptive {
            max-width: 100%;
            flex: 1 0 100%;
          }
        }
        .product-details-volumes {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          .product-details-volumes-item {
            display: flex;

            .product-details-volumes-items {
              display: flex;
              flex: 1 0 20%;
              gap: 5px;

              header {
                font-size: 12px;
                justify-content: flex-start;
                font-weight: 600;
              }

              span {
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .attachments {
        display: flex;
        gap: 20px;
      }
    }
  }
}

.device-mobile .product-details {
  .product-details-main {
    .body {
      .body-container {
        .product-details-items {
          flex: 1 0 calc(50% - 20px) !important;
          &.descriptive {
            max-width: 100%;
            flex: 1 0 100%;
          }
        }
        .product-details-volumes {
          .product-details-volumes-item {
            margin: 0px -20px;
            padding: 10px 20px;
            flex-wrap: wrap !important;
            gap: 10px;
            &:nth-child(odd) {
              background-color: #f2f2f2;
            }
            .product-details-volumes-items {
              flex: 1 0 calc(50% - 20px) !important;
            }
          }
        }
      }
      .attachments {
        flex-direction: column;
      }
    }
  }
}
