.table.notification {
	margin-bottom: 5px;
}

.table-content.notification {
	width: 100%;
}

.table-content {
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.table-header {
	height: 30px;
	text-align: left;
	padding: 5px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.2);
	border-radius: 3px 3px 0px 0px;
	background-color: #f8f9fa;
}

.table-item {
	padding: 5px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.table-item.notification.new {
	font-weight: 600;
}

.table-item.notification.read {
	font-weight: 400;
}

.device-mobile .table-item.notification.read {
	position: relative;
}

.table-header.notification,
.table-item.notification {
	padding: 5px 10px !important;
}

.notification-table-item-title,
.notification-table-header-item-title,
.notification-table-item-msg,
.notification-table-header-item-msg,
.notification-table-item-os,
.notification-table-header-item-os,
.notification-table-item-date,
.notification-table-header-item-date,
.notification-table-item-status,
.notification-table-header-item-status {
	position: relative;
	float: left;
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	font-size: 13px;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.device-mobile .notification-table-item-title,
.device-mobile .notification-table-header-item-title,
.device-mobile .notification-table-item-msg,
.device-mobile .notification-table-header-item-msg,
.device-mobile .notification-table-item-os,
.device-mobile .notification-table-header-item-os,
.device-mobile .notification-table-item-date,
.device-mobile .notification-table-header-item-date,
.device-mobile .notification-table-item-status,
.device-mobile .notification-table-header-item-status {
	width: 100%;
	padding: 0px;
	font-size: 12px;
	font-weight: 400;
	white-space: initial;
}

.notification-table-item-title,
.notification-table-header-item-title {
	width: 14%;
	cursor: pointer;
}

.notification-table-item-msg,
.notification-table-header-item-msg {
	width: calc(86% - 320px);
	cursor: pointer;
}

.notification-table-item-os,
.notification-table-header-item-os {
	width: 120px;
	cursor: pointer;
}

.notification-table-item-date,
.notification-table-header-item-date {
	width: 100px;
}

.notification-table-header-item-status {
	width: 80px;
}

.notification-table-item-status {
	width: 80px;
}

.notification-table-item-options {
	width: 20px;
}

.device-mobile .notification-table-item-options {
	position: absolute;
	right: 20px;
}

.notification-table-item-options .MuiButton-root {
	min-width: 100%;
}

.notification-table-item-options .MuiSvgIcon-root {
	font-size: 17px;
}

.notification-table-item-status .mark-as {
	color: #000;
	font-weight: unset;
}

.header-filter {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}

.header-filter snotification-page-headern:first-child {
	margin-right: 8px;
}

.header-filter .MuiSvgIcon-root {
	font-size: 20px !important;
}

.notifications-warning,
.notifications-count {
	font-size: 11px;
	text-align: left;
	display: block;
}

.menu-box-item {
	z-index: 100;
	top: 0;
}

.menu-item {
	margin: 0px;
	color: rgba(51, 51, 51, 0.84);
	font-size: 14px;
	line-height: 12px;
	text-align: center;
}

.menu-item:hover {
	background-color: rgba(0, 0, 0, 0.19);
}
