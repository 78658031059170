.device-mobile .container-item {
	background-color: #f9f8fa;
	padding: 10px;

	details summary::-webkit-details-marker {
		display: none;
	}

	.items-list-details {
		&:focus-visible {
			outline: none;
		}

		.items-list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			align-items: center;
			font-size: 12px;
			gap: 10px;

			.items-list-order-item {
				padding-left: 0px;
				margin-bottom: 0;

				header {
					font-size: 12px;
					font-weight: 700;
					margin: 0;
				}

				.select-light-container,
				.input-container {
					height: 33px;
				}
			}

			.items-list-order-product {
				display: flex;
				align-items: center;
				grid-column: 1 / -1;

				.container-product.viewing {
					width: calc(100% - 29px);
				}

				.container-product {
					display: flex;
					flex-direction: column;
					width: calc(100% - 58px);
					span {
						font-size: 14px;
						font-weight: 500;
						white-space: normal;
					}
				}

				.arrow-icon {
					transform: rotate(0deg);
					margin-right: 5px;
				}

				.delete-icon {
					margin-left: 5px;
				}
			}

			.items-list-order-select {
				max-width: 100%;
				display: grid;
				grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
				grid-column: 1 / -1;
				column-gap: 10px;

				.container-accessories {
					.select-accessories {
						display: flex;
						gap: 10px;
					}
				}

				.container-storage-type,
				.container-operation-type {
					span {
						display: inline-block;
						max-width: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			.items-list-invoice-order-item {
				header {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			.items-list-order-purchase-order-item {
				header {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			&:last-child {
				margin-top: 10px;
			}
		}
	}

	&:nth-child(odd) {
		background-color: #e5e5e5;
	}
}

details[open] .arrow-icon {
	transform: rotate(180deg) !important;
}

@media (height >= 980px) and (width >= 480px) {
	.items-list-order-select {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)) !important;
	}
}
