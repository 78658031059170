.equipment {
	width: 70%;
	font-size: 20px;
	font-weight: 600;
	line-height: 20px;
	text-align: left;
}

.equipment.align-header {
	padding-left: 31px;
}

.serial-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 5px;
	margin-top: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.description {
	padding-right: 5px;
	line-height: 20px;
	font-weight: 600;
	text-align: left;
}

.warranty {
	padding-right: 5px;
	line-height: 20px;
	font-weight: 600;
	text-align: right;
}

.sale {
	padding-right: 5px;
	line-height: 20px;
	font-weight: 600;
	text-align: right;
}

.pointer {
	width: 40%;
	cursor: pointer;
}

.description.tech {
	padding-left: 20px;
	background-color: transparent;
}

.info {
	font-style: normal;
	font-weight: 500;
	text-align: left;
}

.technician {
	display: block;
	margin-bottom: 40px;
}

.tech-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.tech-name {
	position: relative;
	width: 100%;
	padding-left: 0px;
	line-height: 17px;
	text-align: left;
}

.div-block-7 {
	display: none;
}

.schedule-date {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 25px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
}

.activity {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 130px;
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	border: 1px none #000;
}

.circle {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 20px;
	background-color: #505050;
}

.time-line {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-top: 8px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}
.form-item-content-line .line,
.map .line {
	width: 2px;
	height: 130px;
	background-color: #858585;
	-o-object-fit: none;
	object-fit: none;
}

.dots {
	height: 15px;
	border-style: dashed;
	border-width: 1px;
	border-color: #858585;
}

.address {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.tabs {
	margin-top: 80px;
}

.tabs-menu {
	position: fixed;
	z-index: 3;
	width: 100%;
	max-width: 1200px;
}

.tabs-content {
	margin-top: 85px;
}

.container-10 {
	position: fixed;
	top: 83px;
}

.navbar-4 {
	margin-top: 114px;
}

.navbar-5 {
	position: fixed;
	width: 100%;
	margin-top: 80px;
}

.company-selecion {
	display: block;
	width: 50%;
	text-align: left;
}

.dropdown-toggle {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
}

.heading-4 {
	margin-bottom: 40px;
	text-align: left;
}

.text-block-6 {
	font-weight: 500;
}

.feature-section {
	padding-top: 100px;
	padding-bottom: 100px;
}

.flex-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
}

.feature-image-mask {
	width: 100%;
	margin-right: 0px;
}

.feature-image {
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
	-o-object-position: 50% 50%;
	object-position: 50% 50%;
}

.div-block-9 {
	margin-right: 40px;
}

.feature-image-mask-2 {
	width: 100%;
	margin-right: 40px;
}

.table-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.45);
	font-size: 16px;
	line-height: 40px;
	font-weight: 500;
	text-align: center;
}

.table-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 15px;
	padding-bottom: 15px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.45);
	background-color: #fff;
	color: rgba(51, 51, 51, 0.89);
	text-align: center;
}

.table-item:hover {
	background-color: rgba(0, 0, 0, 0.09);
}

.container-11 {
	margin-top: 70px;
	margin-bottom: 50px;
}

.text-block-7 {
	font-size: 15px;
	line-height: 13px;
}

.dropdown-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.link-block-6 {
	display: block;
	text-decoration: none;
}

.container-12 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.equip {
	width: 30%;
	margin-right: 15px;
	margin-bottom: 15px;
	padding-top: 30px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	background-color: #fff;
}

.equipment-columns {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.45);
	background-color: #fff;
	color: rgba(51, 51, 51, 0.89);
	text-align: center;
}

.equipment-columns:hover {
	background-color: rgba(0, 0, 0, 0.09);
}

.equipments-list {
	margin-top: 70px;
	margin-bottom: 50px;
}

.item-equipment {
	font-size: 18px;
}

.list-item-2 {
	font-size: 17px;
}

.item-os {
	font-size: 17px;
}

.item-eq-name {
	margin-bottom: 5px;
}

.os-list {
	margin-top: 40px;
	margin-bottom: 50px;
}

.section-lists {
	position: relative;
	padding: 140px 56px 120px;
	background-color: #fff;

	text-align: center;
	text-transform: none;
}

.section-lists.image-background {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: block;
	overflow: auto;
	margin-top: 90px;
	padding-top: 20px;
	padding-bottom: 50px;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	background-color: rgba(219, 219, 219, 0.41);
}

.company-bar {
	max-width: 1200px;
	margin-top: auto;
	margin-bottom: auto;
}

.company-bar.client-nav {
	display: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.company-selecion-copy {
	display: block;
	width: 50%;
	text-align: left;
}

.company-selecion-mobile {
	display: block;
	width: 50%;
	text-align: center;
}

.company-dropdown-toggle {
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 15px;
}

.header-bar {
	max-width: 1200px;
}

.header-bar.client-nav {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.text-block-8 {
	display: none;
}

.text-block-10 {
	font-weight: 400;
}

.form {
	height: 600px;
	overflow-y: scroll;
}

.device-mobile .form {
	width: calc(100vw - 40px);
	height: calc(var(--app-height) - 110px);
	overflow-y: scroll;
}

.device-mobile .form .request-header {
	width: calc(100vw - 50px);
}

.form .request-header .serial-number {
	display: grid;
	grid-template-columns: auto 1fr;
	justify-items: start;
}

.device-mobile .form .request-header .serial-number .description {
	flex-direction: column;
}

.request-footer {
	padding-top: 30px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.form-2 {
	text-align: right;
}

.form-area {
	margin-bottom: 20px;
}

.field-title {
	margin-top: 4px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
	text-align: left;
	text-transform: none;
}

.radio-button-field {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
	text-align: left;
}

.radio-button-label {
	line-height: 16px;
	font-weight: 500;
}

.radio-button {
	margin-top: 0px;
	margin-right: 15px;
}

.equip-history,
.form-address {
	margin-bottom: 10px;
	padding-right: 5px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	text-align: left;
}

.equip-history-items {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.equip-history-items div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.device-mobile .equip-history-items {
	flex-direction: column;
	align-items: flex-start;
}

.equip-history-items label {
	margin: 0 5px 0 0;
}

.form-address.tech {
	padding-left: 20px;
}

.request-title {
	margin: 20px 20px 0px;
	font-size: 30px;
	line-height: 40px;
	font-weight: 700;
	text-align: left;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.container-14 {
	margin-top: 60px;
}

.image-5 {
	width: 60%;
	margin-bottom: 48px;
}

.form-block {
	margin-right: auto;
	margin-left: auto;
}

.field-label {}

.field-label-2 {}

.submit-button {
	margin-top: 20px;
}

.div-block-10 {
	position: static;
	width: 50%;
	margin: auto;
}

.section-6 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.tools {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 25px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 21px;
}

.link-block-7 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #000;
	font-size: 17px;
	text-decoration: none;
}

.link-block-8 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 25px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-block-14 {
	text-decoration: none;
}

.list-os-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.os-date {
	width: 10%;
}

.os-protocol {
	width: 10%;
}

.os-region {
	width: 6%;
}

.os-client {
	width: 10%;
}

.os-equipment {
	width: 25%;
}

.os-n-s {
	width: 6%;
}

.os-status {
	width: 15%;
}

.os-visit {
	width: 12%;
}

.os-type {
	width: 10%;
}

.list-os {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #fff;
}

.list-os:hover {
	background-color: rgba(0, 0, 0, 0.21);
}

.image-6 {
	margin-right: 5px;
}

.request-address {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.request-contact {
	padding-top: 14px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.form-deviser-header {
	height: 1px;
}

.form-deviser {
	height: 0.5px;
	margin-left: 15px;
	margin-right: 15px;
	background-color: rgba(0, 0, 0, 0.37);
	border-style: solid;
	border-width: 0.5px;
}

.request-technician1 {
	margin-top: 20px;
	height: 400px;
	overflow-y: scroll;
	align-self: flex-start;
}

.device-mobile .request-technician1 {
	height: calc(var(--app-height) - 220px);
	font-size: 12px !important;
	font-weight: 400 !important;
}

.device-mobile .request-technician1 #name_tech {
	font-size: 24px;
}

.request-technician {
	padding-top: 14px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.request-details #obs :focus-visible {
	outline: none;
}

.request-details {
	position: relative;
}

.description-copy {
	padding-right: 5px;

	line-height: 20px;
	font-style: normal;
	font-weight: 600;
	text-align: left;
}

.description-copy.tech {
	padding-left: 20px;
}

.visit-date {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-style: italic;
}

.tech {
	display: block;
	margin-bottom: 20px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	background-color: #fff;
	text-decoration: none;
	cursor: pointer;
}

.div-block-11 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 9px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.tech-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-style: normal;
}

.text-block-15 {
	font-size: 30px;
	line-height: 30px;
}

.text-block-16 {
	margin-bottom: 2px;
	text-align: left;
}

.div-block-12 {
	padding: 10px;
	background-color: transparent;
}

.tech-id {
	margin-bottom: 15px;
	text-align: left;
}

.text-block-17 {
	padding: 14px;
	color: #0b3a7d;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
}

.bold-text {
	font-weight: 600;
}

.bold-text-2 {
	font-weight: 600;
}

.bold-text-3 {
	font-weight: 600;
}

.div-block-13 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 20px;
	margin-bottom: 10px;
	margin-left: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.search-tech-result {
	display: block;
	margin-right: 20px;
	margin-bottom: 10px;
	margin-left: 20px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.text-block-18 {
	color: #fff;
}

.text-block-19 {
	color: rgba(0, 0, 0, 0.66);
	font-weight: 700;
}

.div-block-14 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	padding-top: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(0, 0, 0, 0.47);
}

.new-service-order-form-field-title .name {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	margin-bottom: -1px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 17px;
}

.info-set {
	line-height: 20px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
}

.Serial-Text {
	font-style: normal;
	font-weight: 500;
	text-align: center;
	border-radius: 6px;
}

.details {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 14px;
	font-weight: 300;
}

.loading-content {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}

.loading-content div {
	margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
	.icon {
		color: #fff;
	}

	.menu-button {
		left: 10%;
	}

	.nav-menu.options {
		width: auto;
	}

	.full-bar.client-nav {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.service-order {
		display: block;
		width: auto;
		height: auto;
		margin-top: 40px;
	}

	.so-follow-up {
		width: auto;
	}

	.so-header {
		width: auto;
	}

	.protocol {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.navbar-5 {
		margin-top: 0px;
	}

	.equip {
		width: auto;
	}

	.section-lists.image-background {
		position: static;
	}

	.company-bar.client-nav {
		display: none;
	}

	.company-selecion-copy {
		display: none;
	}

	.column-8 {
		display: none;
	}

	.column-9 {
		display: none;
	}

	.action-eq {
		display: none;
	}

	.header-bar.client-nav {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.text-block-8 {
		display: none;
	}

	.request-details {
		width: auto;
	}

	.request-footer {
		width: auto;
	}

	.request-address {
		width: auto;
	}

	.request-technician {
		width: auto;
	}
}

@media screen and (max-width: 767px) {
	.icon {
		color: #fff;
	}

	.menu-button {
		left: 10%;
	}

	.section.image-background {
		width: 100%;
		padding: 0px;
	}

	.full-bar.client-nav {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.service-order {
		width: 100%;
		margin-top: 0px;
	}

	.so-follow-up {
		padding-right: 15px;
	}

	.tech-info {
		width: 100%;
	}

	.tech-info:hover {
		background-color: rgba(0, 0, 0, 0.07);
	}

	.tech-name {
		width: 90%;
	}

	.div-block-7 {
		display: block;
	}

	.heading-4 {
		margin-left: 25px;
	}

	.feature-section {
		padding: 40px 20px;
	}

	.flex-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.feature-image-mask {
		margin-right: 0px;
	}

	.feature-image {
		margin-right: auto;
		margin-left: auto;
	}

	.feature-image-mask-2 {
		margin-right: 0px;
	}

	.table-item {
		padding-left: 15px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-bottom-color: rgba(219, 219, 219, 0.41);
	}

	.text-block-7 {
		text-align: left;
	}

	.equipment-columns {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.item-eq-name {
		font-weight: 500;
		text-align: left;
	}

	.section-lists.image-background {
		width: 100%;
		padding: 0px;
	}

	.list-item-3 {
		display: none;
	}

	.column-5 {
		margin-bottom: 10px;
	}

	.column-6 {
		margin-bottom: 15px;
	}

	.column-7 {
		margin-bottom: 10px;
	}

	.list-item-4 {
		display: none;
	}

	.list-item-5 {
		display: none;
	}

	.header-bar.client-nav {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column-10 {
		margin-bottom: 10px;
	}

	.column-11 {
		margin-bottom: 16px;
	}

	.text-block-8 {
		display: block;
	}

	.text-block-9 {
		display: none;
	}

	.text-block-10 {
		font-weight: 500;
	}

	.text-block-11 {
		font-weight: 600;
		text-align: left;
	}

	.text-block-12 {
		text-align: left;
	}

	.text-block-13 {
		text-align: left;
	}

	.request-form.image-background {
		width: 100%;
		padding: 0px;
	}

	.div-block-10 {
		width: 70%;
	}
}

@media screen and (max-width: 479px) {
	.nav-link {
		color: #fff;
	}

	.nav-link.dark.user {
		display: none;
	}

	.icon {
		color: #fff;
	}

	.menu-button {
		position: relative;
		left: 15%;
		display: block;
		margin-top: -5px;
		background-color: transparent;
	}

	.menu-button.w--open {
		background-color: #0e315c;
	}

	.heading {
		margin-top: 60px;
		font-size: 14px;
	}

	.paragraph {
		font-size: 28px;
		line-height: 130%;
	}

	.heading-2 {
		font-size: 38px;
	}

	.text-block {
		margin-top: 25px;
		font-size: 18px;
	}

	.section-2 {
		height: 100%;
	}

	.heading-3 {
		font-size: 38px;
	}

	.div-block {
		width: 80%;
	}

	.div-block-2 {
		display: inline-block;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.container-6 {
		position: relative;
		display: block;
		height: 100%;
		margin-right: auto;
		margin-bottom: 40px;
		margin-left: auto;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
		text-align: center;
	}

	.container-8 {
		margin-top: 50px;
	}

	.column-2 {
		margin-top: 40px;
	}

	.column-3 {
		margin-top: 40px;
	}

	.column-4 {
		margin-top: 40px;
	}

	.div-block-3 {
		right: auto;
		margin-left: -100px;
		text-align: center;
	}

	.link-block-3 {
		display: inline-block;
		margin-right: 20px;
	}

	.nav-menu {
		background-color: #fff;
	}

	.icon-2 {
		margin-left: 5px;
	}

	.icon-3 {
		margin-right: 5px;
	}

	.service-order {
		position: relative;
		overflow: auto;
	}

	.so-follow-up {
		position: static;
	}

	.descri-o {
		padding-bottom: 0px;
	}

	.tech-name {
		width: 80%;
	}

	.activity {
		position: relative;
		margin-bottom: 15px;
	}

	.table-item {
		border-bottom-color: #dbdbdb;
	}

	.text-block-7 {
		text-align: left;
	}

	.item-eq-name {
		font-weight: 500;
		text-align: left;
	}

	.column-5 {
		margin-bottom: 10px;
	}

	.column-6 {
		margin-bottom: 15px;
	}

	.column-7 {
		margin-bottom: 9px;
	}

	.text-block-11 {
		text-align: left;
	}

	.text-block-12 {
		text-align: left;
	}

	.text-block-13 {
		text-align: left;
	}

	.request-details {
		position: static;
	}

	.div-block-10 {
		width: 90%;
	}
}

[class^='w-icon-'],
[class*=' w-icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.w-icon-slider-right:before {
	content: '\e600';
}

.w-icon-slider-left:before {
	content: '\e601';
}

.w-icon-nav-menu:before {
	content: '\e602';
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
	content: '\e603';
}

.w-icon-file-upload-remove:before {
	content: '\e900';
}

.w-icon-file-upload-icon:before {
	content: '\e903';
}

html {
	height: 100%;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

html.w-mod-touch * {
	background-attachment: scroll !important;
}

.w-block {
	display: block;
}

.w-inline-block {
	max-width: 100%;
	display: inline-block;
}

.w-clearfix:before,
.w-clearfix:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-clearfix:after {
	clear: both;
}

.w-hidden {
	display: none;
}

.submit-button-2 {
	border-radius: 4px;
	background-color: #004687;
	padding-left: 105px;
}

.submit-button-2:disabled {
	cursor: not-allowed;
	filter: grayscale(100);
}

.w-button2 {
	display: inline-block;
	padding: 9px 15px;
	color: white;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
}

.w-button {
	display: inline-block;
	padding: 9px 15px;
	color: white;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
}

input.w-button {
	-webkit-appearance: button;
}

html[data-w-dynpage] [data-w-cloak] {
	color: transparent !important;
}

.w-webflow-badge,
.w-webflow-badge * {
	position: static;
	left: auto;
	top: auto;
	right: auto;
	bottom: auto;
	z-index: auto;
	display: block;
	visibility: visible;
	overflow: visible;
	overflow-x: visible;
	overflow-y: visible;
	width: auto;
	height: auto;
	max-height: none;
	max-width: none;
	min-height: 0;
	min-width: 0;
	margin: 0;
	padding: 0;
	float: none;
	clear: none;
	border: 0 none transparent;
	border-radius: 0;
	background: none;
	background-image: none;
	background-position: 0% 0%;
	background-size: auto auto;
	background-repeat: repeat;
	background-origin: padding-box;
	background-clip: border-box;
	background-attachment: scroll;
	background-color: transparent;
	box-shadow: none;
	opacity: 1;
	transform: none;
	transition: none;
	direction: ltr;
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-style: inherit;
	font-variant: inherit;
	text-align: inherit;
	text-decoration: inherit;
	text-indent: 0;
	text-transform: inherit;
	text-shadow: none;
	font-smoothing: auto;
	vertical-align: baseline;
	cursor: inherit;
	white-space: inherit;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
}

.w-webflow-badge {
	position: fixed !important;
	display: inline-block !important;
	visibility: visible !important;
	opacity: 1 !important;
	z-index: 2147483647 !important;
	top: auto !important;
	right: 12px !important;
	bottom: 12px !important;
	left: auto !important;
	color: #aaadb0 !important;
	background-color: #fff !important;
	border-radius: 3px !important;
	padding: 6px 8px 6px 6px !important;
	font-size: 12px !important;
	opacity: 1 !important;
	line-height: 14px !important;
	text-decoration: none !important;
	transform: none !important;
	margin: 0 !important;
	width: auto !important;
	height: auto !important;
	overflow: visible !important;
	white-space: nowrap;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.w-webflow-badge>img {
	display: inline-block !important;
	visibility: visible !important;
	opacity: 1 !important;
	vertical-align: middle !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	margin-bottom: 10px;
}

h1 {
	font-size: 38px;
	line-height: 44px;
	margin-top: 20px;
}

h2 {
	font-size: 32px;
	line-height: 36px;
	margin-top: 20px;
}

h3 {
	font-size: 24px;
	line-height: 30px;
	margin-top: 20px;
}

h4 {
	font-size: 18px;
	line-height: 24px;
	margin-top: 10px;
}

h5 {
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
}

h6 {
	font-size: 12px;
	line-height: 18px;
	margin-top: 10px;
}

p {
	margin-top: 0;
	margin-bottom: 0px;
}

blockquote {
	margin: 0 0 10px 0;
	padding: 10px 20px;
	border-left: 5px solid #e2e2e2;
	font-size: 18px;
	line-height: 22px;
}

figure {
	margin: 0;
	margin-bottom: 10px;
}

figcaption {
	margin-top: 5px;
	text-align: center;
}

.w-list-unstyled {
	padding-left: 0;
	list-style: none;
}

.w-embed:before,
.w-embed:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-embed:after {
	clear: both;
}

.w-video {
	width: 100%;
	position: relative;
	padding: 0;
}

.w-video iframe,
.w-video object,
.w-video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}

button,
html input[type='button'],
input[type='reset'] {
	border: 0;
	cursor: pointer;
	-webkit-appearance: button;
}

.w-form-button {
	display: flex;
	width: 100%;
	column-gap: 20px;
	margin-bottom: 20px;
	justify-content: center;
}

.w-form-done {
	display: none;
	padding: 20px;
	text-align: center;
	background-color: #dddddd;
}

.w-form-fail {
	display: none;
	margin-top: 10px;
	padding: 10px;
	background-color: #ffdede;
}

label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.w-input,
.w-select {
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 20px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
	color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
	color: #999;
	opacity: 1;
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
	color: #999;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
	color: #999;
}

.w-input:focus,
.w-select:focus {
	border-color: #3898ec;
	outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
	cursor: not-allowed;
	background-color: #eeeeee;
}

textarea.w-input,
textarea.w-select {
	height: auto;
}

.w-select {
	background-color: #f3f3f3;
}

.w-select[multiple] {
	height: auto;
}

.w-form-label {
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
	margin-bottom: 0px;
	padding-left: 7px;
}

.w-radio {
	display: block;
	margin-bottom: 5px;
	padding-left: 20px;
}

.w-radio:before,
.w-radio:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-radio:after {
	clear: both;
}

.w-radio-input {
	display: flex;
	flex-direction: row;
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	float: left;
	margin-left: -20px;
}

.w-radio-input {
	margin-top: 3px;
}

.w-file-upload {
	display: block;
	margin-bottom: 10px;
}

.w-file-upload-input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -100;
}

.w-file-upload-default,
.w-file-upload-uploading,
.w-file-upload-success {
	display: inline-block;
	color: #333333;
}

.w-file-upload-error {
	display: block;
	margin-top: 10px;
}

.w-file-upload-default.w-hidden,
.w-file-upload-uploading.w-hidden,
.w-file-upload-error.w-hidden,
.w-file-upload-success.w-hidden {
	display: none;
}

.w-file-upload-uploading-btn {
	display: flex;
	font-size: 14px;
	font-weight: normal;
	cursor: pointer;
	margin: 0;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	background-color: #fafafa;
}

.w-file-upload-file {
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
	margin: 0;
	padding: 8px 9px 8px 11px;
	border: 1px solid #cccccc;
	background-color: #fafafa;
}

.w-file-upload-file-name {
	font-size: 14px;
	font-weight: normal;
	display: block;
}

.w-file-remove-link {
	margin-top: 3px;
	margin-left: 10px;
	width: auto;
	height: auto;
	padding: 3px;
	display: block;
	cursor: pointer;
}

.w-icon-file-upload-remove {
	margin: auto;
	font-size: 10px;
}

.w-file-upload-error-msg {
	display: inline-block;
	color: #ea384c;
	padding: 2px 0;
}

.w-file-upload-info {
	display: inline-block;
	line-height: 38px;
	padding: 0 12px;
}

.w-file-upload-label {
	display: inline-block;
	font-size: 14px;
	font-weight: normal;
	cursor: pointer;
	margin: 0;
	padding: 8px 12px;
	border: 1px solid #cccccc;
	background-color: #fafafa;
}

.w-icon-file-upload-icon,
.w-icon-file-upload-uploading {
	display: inline-block;
	margin-right: 8px;
	width: 20px;
}

.w-icon-file-upload-uploading {
	height: 20px;
}

.w-container {
	margin-left: auto;
	margin-right: auto;
	max-width: 940px;
}

.w-container:before,
.w-container:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-container:after {
	clear: both;
}

.w-container .w-row {
	margin-left: -10px;
	margin-right: -10px;
}

.w-row:before,
.w-row:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-row:after {
	clear: both;
}

.w-row .w-row {
	margin-left: 0;
	margin-right: 0;
}

.w-col {
	position: relative;
	float: left;
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
}

.w-col .w-col {
	padding-left: 0;
	padding-right: 0;
}

.w-col-1 {
	width: 8.33333333%;
}

.w-col-2 {
	width: 16.66666667%;
}

.w-col-3 {
	width: 25%;
}

.w-col-4 {
	width: 33.33333333%;
}

.w-col-5 {
	width: 41.66666667%;
}

.w-col-6 {
	width: 50%;
}

.w-col-7 {
	width: 58.33333333%;
}

.w-col-8 {
	width: 66.66666667%;
}

.w-col-9 {
	width: 75%;
}

.w-col-10 {
	width: 83.33333333%;
}

.w-col-11 {
	width: 91.66666667%;
}

.w-col-12 {
	width: 100%;
}

.w-hidden-main {
	display: none !important;
}

@media screen and (max-width: 991px) {
	.w-container {
		max-width: 728px;
	}

	.w-hidden-main {
		display: inherit !important;
	}

	.w-hidden-medium {
		display: none !important;
	}

	.w-col-medium-1 {
		width: 8.33333333%;
	}

	.w-col-medium-2 {
		width: 16.66666667%;
	}

	.w-col-medium-3 {
		width: 25%;
	}

	.w-col-medium-4 {
		width: 33.33333333%;
	}

	.w-col-medium-5 {
		width: 41.66666667%;
	}

	.w-col-medium-6 {
		width: 50%;
	}

	.w-col-medium-7 {
		width: 58.33333333%;
	}

	.w-col-medium-8 {
		width: 66.66666667%;
	}

	.w-col-medium-9 {
		width: 75%;
	}

	.w-col-medium-10 {
		width: 83.33333333%;
	}

	.w-col-medium-11 {
		width: 91.66666667%;
	}

	.w-col-medium-12 {
		width: 100%;
	}

	.w-col-stack {
		width: 100%;
		left: auto;
		right: auto;
	}
}

@media screen and (max-width: 767px) {
	.w-hidden-main {
		display: inherit !important;
	}

	.w-hidden-medium {
		display: inherit !important;
	}

	.w-hidden-small {
		display: none !important;
	}

	.w-row,
	.w-container .w-row {
		margin-left: 0;
		margin-right: 0;
	}

	.w-col {
		width: 100%;
		left: auto;
		right: auto;
	}

	.w-col-small-1 {
		width: 8.33333333%;
	}

	.w-col-small-2 {
		width: 16.66666667%;
	}

	.w-col-small-3 {
		width: 25%;
	}

	.w-col-small-4 {
		width: 33.33333333%;
	}

	.w-col-small-5 {
		width: 41.66666667%;
	}

	.w-col-small-6 {
		width: 50%;
	}

	.w-col-small-7 {
		width: 58.33333333%;
	}

	.w-col-small-8 {
		width: 66.66666667%;
	}

	.w-col-small-9 {
		width: 75%;
	}

	.w-col-small-10 {
		width: 83.33333333%;
	}

	.w-col-small-11 {
		width: 91.66666667%;
	}

	.w-col-small-12 {
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	.w-container {
		max-width: none;
	}

	.w-hidden-main {
		display: inherit !important;
	}

	.w-hidden-medium {
		display: inherit !important;
	}

	.w-hidden-small {
		display: inherit !important;
	}

	.w-hidden-tiny {
		display: none !important;
	}

	.w-col {
		width: 100%;
	}

	.w-col-tiny-1 {
		width: 8.33333333%;
	}

	.w-col-tiny-2 {
		width: 16.66666667%;
	}

	.w-col-tiny-3 {
		width: 25%;
	}

	.w-col-tiny-4 {
		width: 33.33333333%;
	}

	.w-col-tiny-5 {
		width: 41.66666667%;
	}

	.w-col-tiny-6 {
		width: 50%;
	}

	.w-col-tiny-7 {
		width: 58.33333333%;
	}

	.w-col-tiny-8 {
		width: 66.66666667%;
	}

	.w-col-tiny-9 {
		width: 75%;
	}

	.w-col-tiny-10 {
		width: 83.33333333%;
	}

	.w-col-tiny-11 {
		width: 91.66666667%;
	}

	.w-col-tiny-12 {
		width: 100%;
	}
}

.w-widget {
	position: relative;
}

.w-widget-map {
	width: 100%;
	height: 400px;
}

.w-widget-map label {
	width: auto;
	display: inline;
}

.w-widget-map img {
	max-width: inherit;
}

.w-widget-map .gm-style-iw {
	text-align: center;
}

.w-widget-map .gm-style-iw>button {
	display: none !important;
}

.w-widget-twitter {
	overflow: hidden;
}

.w-widget-twitter-count-shim {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 28px;
	height: 20px;
	text-align: center;
	background: white;
	border: #758696 solid 1px;
	border-radius: 3px;
}

.w-widget-twitter-count-shim * {
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
	position: relative;
	font-size: 15px;
	line-height: 12px;
	text-align: center;
	color: #999;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
	position: relative;
	display: block;
}

.w-widget-twitter-count-shim.w--large {
	width: 36px;
	height: 28px;
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
	font-size: 18px;
	line-height: 18px;
}

.w-widget-twitter-count-shim:not(.w--vertical) {
	margin-left: 5px;
	margin-right: 8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
	margin-left: 6px;
}

.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
	top: 50%;
	left: 0;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
	border-color: rgba(117, 134, 150, 0);
	border-right-color: #5d6c7b;
	border-width: 4px;
	margin-left: -9px;
	margin-top: -4px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
	border-width: 5px;
	margin-left: -10px;
	margin-top: -5px;
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: white;
	border-width: 4px;
	margin-left: -8px;
	margin-top: -4px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
	border-width: 5px;
	margin-left: -9px;
	margin-top: -5px;
}

.w-widget-twitter-count-shim.w--vertical {
	width: 61px;
	height: 33px;
	margin-bottom: 8px;
}

.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.w-widget-twitter-count-shim.w--vertical:before {
	border-color: rgba(117, 134, 150, 0);
	border-top-color: #5d6c7b;
	border-width: 5px;
	margin-left: -5px;
}

.w-widget-twitter-count-shim.w--vertical:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: white;
	border-width: 4px;
	margin-left: -4px;
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
	font-size: 18px;
	line-height: 22px;
}

.w-widget-twitter-count-shim.w--vertical.w--large {
	width: 76px;
}

.w-widget-gplus {
	overflow: hidden;
}

.w-background-video {
	position: relative;
	overflow: hidden;
	height: 500px;
	color: white;
}

.w-background-video>video {
	background-size: cover;
	background-position: 50% 50%;
	position: absolute;
	margin: auto;
	width: 100%;
	height: 100%;
	right: -100%;
	bottom: -100%;
	top: -100%;
	left: -100%;
	object-fit: cover;
	z-index: -100;
}

.w-background-video>video::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}

.w-slider {
	position: relative;
	height: 300px;
	text-align: center;
	background: #dddddd;
	clear: both;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	tap-highlight-color: rgba(0, 0, 0, 0);
}

.w-slider-mask {
	position: relative;
	display: block;
	overflow: hidden;
	z-index: 1;
	left: 0;
	right: 0;
	height: 100%;
	white-space: nowrap;
}

.w-slide {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 100%;
	white-space: normal;
	text-align: left;
}

.w-slider-nav {
	position: absolute;
	z-index: 2;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	padding-top: 10px;
	height: 40px;
	text-align: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	tap-highlight-color: rgba(0, 0, 0, 0);
}

.w-slider-nav.w-round>div {
	border-radius: 100%;
}

.w-slider-nav.w-num>div {
	width: auto;
	height: auto;
	padding: 0.2em 0.5em;
	font-size: inherit;
	line-height: inherit;
}

.w-slider-nav.w-shadow>div {
	box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
}

.w-slider-nav-invert {
	color: #fff;
}

.w-slider-nav-invert>div {
	background-color: rgba(34, 34, 34, 0.4);
}

.w-slider-nav-invert>div.w-active {
	background-color: #222;
}

.w-slider-dot {
	position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
	background-color: rgba(255, 255, 255, 0.4);
	cursor: pointer;
	margin: 0 3px 0.5em;
	transition: background-color 100ms, color 100ms;
}

.w-slider-dot.w-active {
	background-color: #fff;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
	position: absolute;
	width: 80px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	cursor: pointer;
	overflow: hidden;
	color: white;
	font-size: 40px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.w-slider-arrow-left [class^='w-icon-'],
.w-slider-arrow-right [class^='w-icon-'],
.w-slider-arrow-left [class*=' w-icon-'],
.w-slider-arrow-right [class*=' w-icon-'] {
	position: absolute;
}

.w-slider-arrow-left {
	z-index: 3;
	right: auto;
}

.w-slider-arrow-right {
	z-index: 4;
	left: auto;
}

.w-icon-slider-left,
.w-icon-slider-right {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 1em;
	height: 1em;
}

.w-dropdown {
	display: inline-block;
	position: relative;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	z-index: 900;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
	position: relative;
	vertical-align: top;
	text-decoration: none;
	color: #222222;
	padding: 20px;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
	white-space: nowrap;
}

.w-dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: inline-block;
	cursor: pointer;
	padding-right: 40px;
}

.w-icon-dropdown-toggle {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	margin-right: 20px;
	width: 1em;
	height: 1em;
}

.w-dropdown-list {
	position: absolute;
	background: #dddddd;
	display: none;
	min-width: 100%;
}

.w-dropdown-list.w--open {
	display: block;
}

.w-dropdown-link {
	padding: 10px 20px;
	display: block;
	color: #222222;
}

.w-dropdown-link.w--current {
	color: #0082f3;
}

@media screen and (max-width: 767px) {
	.w-nav-brand {
		padding-left: 10px;
	}
}

/**
   * ## Note
   * Safari (on both iOS and OS X) does not handle viewport units (vh, vw) well.
   * For example percentage units do not work on descendants of elements that
   * have any dimensions expressed in viewport units. It also doesn’t handle them at
   * all in `calc()`.
   */
/**
   * Wrapper around all lightbox elements
   *
   * 1. Since the lightbox can receive focus, IE also gives it an outline.
   * 2. Fixes flickering on Chrome when a transition is in progress
   *    underneath the lightbox.
   */
.w-lightbox-backdrop {
	color: #000;
	cursor: auto;
	font-size: medium;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: normal;
	list-style: disc;
	text-align: flex-start;
	text-indent: 0;
	text-shadow: none;
	text-transform: none;
	visibility: visible;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	word-wrap: normal;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	color: #fff;
	font-size: 17px;
	line-height: 1.2;
	font-weight: 300;
	text-align: center;
	background: rgba(0, 0, 0, 0.9);
	z-index: 2000;
	outline: 0;
	/* 1 */
	opacity: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-transform: translate(0, 0);
	/* 2 */
}

/**
   * Neat trick to bind the rubberband effect to our canvas instead of the whole
   * document on iOS. It also prevents a bug that causes the document underneath to scroll.
   */
.w-lightbox-backdrop,
.w-lightbox-container {
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.w-lightbox-content {
	position: relative;
	height: 100vh;
	overflow: hidden;
}

.w-lightbox-view {
	position: absolute;
	width: 100vw;
	height: 100vh;
	opacity: 0;
}

.w-lightbox-view:before {
	content: '';
	height: 100vh;
}

/* .w-lightbox-content */
.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
	height: 86vh;
}

.w-lightbox-frame,
.w-lightbox-view:before {
	display: inline-block;
	vertical-align: middle;
}

/*
   * 1. Remove default margin set by user-agent on the <figure> element.
   */
.w-lightbox-figure {
	position: relative;
	margin: 0;
	/* 1 */
}

.w-lightbox-group .w-lightbox-figure {
	cursor: pointer;
}

/**
   * IE adds image dimensions as width and height attributes on the IMG tag,
   * but we need both width and height to be set to auto to enable scaling.
   */
.w-lightbox-img {
	width: auto;
	height: auto;
	max-width: none;
}

/**
   * 1. Reset if style is set by user on "All Images"
   */
.w-lightbox-image {
	display: block;
	float: none;
	/* 1 */
	max-width: 100vw;
	max-height: 100vh;
}

.w-lightbox-group .w-lightbox-image {
	max-height: 86vh;
}

.w-lightbox-caption {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0.5em 1em;
	background: rgba(0, 0, 0, 0.4);
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.w-lightbox-embed {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.w-lightbox-control {
	position: absolute;
	top: 0;
	width: 4em;
	background-size: 24px;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.w-lightbox-left {
	display: none;
	bottom: 0;
	left: 0;
	/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0 0h5v23h23v5h-28z" opacity=".4"/><path d="m1 1h3v23h23v3h-26z" fill="#fff"/></g></svg> */
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==');
}

.w-lightbox-right {
	display: none;
	right: 0;
	bottom: 0;
	/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0-0h28v28h-5v-23h-23z" opacity=".4"/><path d="m1 1h26v26h-3v-23h-23z" fill="#fff"/></g></svg> */
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+');
}

/*
   * Without specifying the with and height inside the SVG, all versions of IE render the icon too small.
   * The bug does not seem to manifest itself if the elements are tall enough such as the above arrows.
   * (http://stackoverflow.com/questions/16092114/background-size-differs-in-internet-explorer)
   */
.w-lightbox-close {
	right: 0;
	height: 2.6em;
	/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 18 17" width="18" height="17"><g transform="rotate(45)"><path d="m0 0h7v-7h5v7h7v5h-7v7h-5v-7h-7z" opacity=".4"/><path d="m1 1h7v-7h3v7h7v3h-7v7h-3v-7h-7z" fill="#fff"/></g></svg> */
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=');
	background-size: 18px;
}

/**
   * 1. All IE versions add extra space at the bottom without this.
   */
.w-lightbox-strip {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 1vh;
	line-height: 0;
	/* 1 */
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
}

/*
   * 1. We use content-box to avoid having to do `width: calc(10vh + 2vw)`
   *    which doesn’t work in Safari anyway.
   * 2. Chrome renders images pixelated when switching to GPU. Making sure
   *    the parent is also rendered on the GPU (by setting translate3d for
   *    example) fixes this behavior.
   */
.w-lightbox-item {
	display: inline-block;
	width: 10vh;
	padding: 2vh 1vh;
	/* 1 */
	cursor: pointer;
	-webkit-transform: translate3d(0, 0, 0);
	/* 2 */
}

.w-lightbox-active {
	opacity: 0.3;
}

.w-lightbox-thumbnail {
	position: relative;
	height: 10vh;
	background: #222;
	overflow: hidden;
}

.w-lightbox-thumbnail-image {
	position: absolute;
	top: 0;
	left: 0;
}

.w-lightbox-thumbnail .w-lightbox-tall {
	top: 50%;
	width: 100%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.w-lightbox-thumbnail .w-lightbox-wide {
	left: 50%;
	height: 100%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

/*
   * Spinner
   *
   * Absolute pixel values are used to avoid rounding errors that would cause
   * the white spinning element to be misaligned with the track.
   */
.w-lightbox-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	border: 5px solid rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	-webkit-animation: spin 0.8s infinite linear;
	animation: spin 0.8s infinite linear;
}

.w-lightbox-spinner:after {
	content: '';
	position: absolute;
	top: -4px;
	right: -4px;
	bottom: -4px;
	left: -4px;
	border: 3px solid transparent;
	border-bottom-color: #fff;
	border-radius: 50%;
}

/*
   * Utility classes
   */
.w-lightbox-hide {
	display: none;
}

.w-lightbox-noscroll {
	overflow: hidden;
}

@media (min-width: 768px) {
	.w-lightbox-content {
		height: 96vh;
		margin-top: 2vh;
	}

	.w-lightbox-view,
	.w-lightbox-view:before {
		height: 96vh;
	}

	/* .w-lightbox-content */
	.w-lightbox-group,
	.w-lightbox-group .w-lightbox-view,
	.w-lightbox-group .w-lightbox-view:before {
		height: 84vh;
	}

	.w-lightbox-image {
		max-width: 96vw;
		max-height: 96vh;
	}

	.w-lightbox-group .w-lightbox-image {
		max-width: 82.3vw;
		max-height: 84vh;
	}

	.w-lightbox-left,
	.w-lightbox-right {
		display: block;
		opacity: 0.5;
	}

	.w-lightbox-close {
		opacity: 0.8;
	}

	.w-lightbox-control:hover {
		opacity: 1;
	}
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
	opacity: 0;
}

.w-richtext:before,
.w-richtext:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-richtext:after {
	clear: both;
}

.w-richtext[contenteditable='true']:before,
.w-richtext[contenteditable='true']:after {
	white-space: initial;
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after,
.w-richtext .w-richtext-figure-selected[data-rt-type='video'] div:after {
	outline: 2px solid #2895f7;
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div,
.w-richtext .w-richtext-figure-selected[data-rt-type='image'] div {
	outline: 2px solid #2895f7;
}

.w-richtext figure.w-richtext-figure-type-video>div:after,
.w-richtext figure[data-rt-type='video']>div:after {
	content: '';
	position: absolute;
	display: none;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
}

.w-richtext figure {
	position: relative;
	max-width: 60%;
}

.w-richtext figure>div:before {
	cursor: default !important;
}

.w-richtext figure img {
	width: 100%;
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
	opacity: 0.6;
}

.w-richtext figure div {
	/* fix incorrectly sized selection border in the data manager */
	font-size: 0px;
	color: transparent;
}

.w-richtext figure.w-richtext-figure-type-image,
.w-richtext figure[data-rt-type='image'] {
	display: table;
}

.w-richtext figure.w-richtext-figure-type-image>div,
.w-richtext figure[data-rt-type='image']>div {
	display: inline-block;
}

.w-richtext figure.w-richtext-figure-type-image>figcaption,
.w-richtext figure[data-rt-type='image']>figcaption {
	display: table-caption;
	caption-side: bottom;
}

.w-richtext figure.w-richtext-figure-type-video,
.w-richtext figure[data-rt-type='video'] {
	width: 60%;
	height: 0;
}

.w-richtext figure.w-richtext-figure-type-video iframe,
.w-richtext figure[data-rt-type='video'] iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.w-richtext figure.w-richtext-figure-type-video>div,
.w-richtext figure[data-rt-type='video']>div {
	width: 100%;
}

.w-richtext figure.w-richtext-align-center {
	margin-right: auto;
	margin-left: auto;
	clear: both;
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image>div,
.w-richtext figure.w-richtext-align-center[data-rt-type='image']>div {
	max-width: 100%;
}

.w-richtext figure.w-richtext-align-normal {
	clear: both;
}

.w-richtext figure.w-richtext-align-fullwidth {
	width: 100%;
	max-width: 100%;
	text-align: center;
	clear: both;
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.w-richtext figure.w-richtext-align-fullwidth>div {
	display: inline-block;
	/* padding-bottom is used for aspect ratios in video figures
        we want the div to inherit that so hover/selection borders in the designer-canvas
        fit right*/
	padding-bottom: inherit;
}

.w-richtext figure.w-richtext-align-fullwidth>figcaption {
	display: block;
}

.w-richtext figure.w-richtext-align-floatleft {
	float: left;
	margin-right: 15px;
	clear: none;
}

.w-richtext figure.w-richtext-align-floatright {
	float: right;
	margin-left: 15px;
	clear: none;
}

.w-nav {
	position: relative;
	background: #dddddd;
	z-index: 1000;
}

.w-nav:before,
.w-nav:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-nav:after {
	clear: both;
}

.w-nav-brand {
	position: relative;
	float: left;
	text-decoration: none;
	color: #333333;
}

.w-nav-link {
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	color: #222222;
	padding: 20px;
	text-align: left;
	margin-left: auto;
	margin-right: auto;
}

.w-nav-link.w--current {
	color: #0082f3;
}

.w-nav-menu {
	position: relative;
	float: right;
}

[data-nav-menu-open] {
	display: block !important;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background: #c8c8c8;
	text-align: center;
	overflow: visible;
	min-width: 200px;
}

.w--nav-link-open {
	display: block;
	position: relative;
}

.w-nav-overlay {
	position: absolute;
	overflow: hidden;
	display: none;
	top: 100%;
	left: 0;
	right: 0;
	width: 100%;
}

.w-nav-overlay [data-nav-menu-open] {
	top: 0;
}

.w-nav[data-animation='over-left'] .w-nav-overlay {
	width: auto;
}

.w-nav[data-animation='over-left'] .w-nav-overlay,
.w-nav[data-animation='over-left'] [data-nav-menu-open] {
	right: auto;
	z-index: 1;
	top: 0;
}

.w-nav[data-animation='over-right'] .w-nav-overlay {
	width: auto;
}

.w-nav[data-animation='over-right'] .w-nav-overlay,
.w-nav[data-animation='over-right'] [data-nav-menu-open] {
	left: auto;
	z-index: 1;
	top: 0;
}

.w-nav-button {
	position: relative;
	float: right;
	padding: 18px;
	font-size: 24px;
	display: none;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.w-nav-button.w--open {
	background-color: #c8c8c8;
	color: white;
}

.w-nav[data-collapse='all'] .w-nav-menu {
	display: none;
}

.w-nav[data-collapse='all'] .w-nav-button {
	display: block;
}

.w--nav-dropdown-open {
	display: block;
}

.w--nav-dropdown-toggle-open {
	display: block;
}

.w--nav-dropdown-list-open {
	position: static;
}

@media screen and (max-width: 991px) {
	.w-nav[data-collapse='medium'] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse='medium'] .w-nav-button {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.w-nav[data-collapse='small'] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse='small'] .w-nav-button {
		display: block;
	}

	.w-nav-brand {
		padding-left: 10px;
	}
}

@media screen and (max-width: 479px) {
	.w-nav[data-collapse='tiny'] .w-nav-menu {
		display: none;
	}

	.w-nav[data-collapse='tiny'] .w-nav-button {
		display: block;
	}
}

.w-tabs {
	position: relative;
}

.w-tabs:before,
.w-tabs:after {
	content: ' ';
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2;
}

.w-tabs:after {
	clear: both;
}

.w-tab-menu {
	position: relative;
}

.w-tab-link {
	position: relative;
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	padding: 9px 30px;
	text-align: left;
	cursor: pointer;
	color: #222222;
	background-color: #dddddd;
}

.w-tab-link.w--current {
	background-color: #c8c8c8;
}

.w-tab-content {
	position: relative;
	display: block;
	overflow: hidden;
}

.w-tab-pane {
	position: relative;
	display: none;
}

.w--tab-active {
	display: block;
}

@media screen and (max-width: 479px) {
	.w-tab-link {
		display: block;
	}
}

.w-ix-emptyfix:after {
	content: '';
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.w-dyn-empty {
	padding: 10px;
	background-color: #dddddd;
}

.w-dyn-hide {
	display: none !important;
}

.w-dyn-bind-empty {
	display: none !important;
}

.w-condition-invisible {
	display: none !important;
}

.select-tech-header {
	width: 815px;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: flex-start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.tech-search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 10px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.address-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.get-serial-number {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.div-block-15 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
	-webkit-box-pack: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.description-copy {
	padding-right: 5px;

	line-height: 20px;
	font-style: normal;
	font-weight: 600;
	text-align: left;
}

.info---serial-number {
	padding-right: 15px;
	padding-left: 15px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.45);
	border-radius: 4px;
	background-color: #fff;
	line-height: 21px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
}

.toast-success-container {
	color: #000 !important;
	border-radius: 8px !important;
	background: #ffffff !important;
	border: 1px solid blue !important;
	width: 200%;
	height: 200%;
	font-size: 18px !important;
}

.toast-success-container-after {
	overflow: hidden;
	position: relative;
}

.modal.resultMsg {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-list-component {
	height: 400px;
	align-items: center
}