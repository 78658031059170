.config-user-main-content {
	width: 610px;
	margin-bottom: 20px;

	.config-user-info {
		display: grid;
		column-gap: 20px;
		row-gap: 20px;
		margin-bottom: 20px;
		grid-template-columns: auto auto auto;

		.config-user-info-item {
			display: flex;
			flex-direction: column;

			.user-info-descriptive {
				font-size: 12px;
				font-weight: 700;
			}
			.user-information {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
	.change-password-action {
		display: flex;
		font-size: 12px;
		font-weight: 700;
		color: #004992;
		background-color: transparent;

		& :first-child {
			margin-right: 5px;
		}
	}
}
.config-user-actions {
	display: flex;
	justify-content: center;

	button {
		width: 92px;
	}

	& :last-child {
		margin-left: 20px;
	}
}

.device-mobile {
	.config-user-main-content {
		width: 100%;
		margin-bottom: 20px;

		.config-user-info {
			display: flex;
			row-gap: 20px;
			flex-direction: column;

			.config-user-info-item {
				display: flex;
				flex-direction: column;

				.user-info-descriptive {
					font-size: 12px;
					font-weight: 700;
				}
				.user-information {
					font-size: 12px;
					font-weight: 400;
				}
			}
		}
		.change-password-action {
			display: flex;
			font-size: 12px;
			font-weight: 700;
			color: #004992;
			background-color: transparent;

			& :first-child {
				margin-right: 5px;
			}
		}
	}

	.config-user-actions {
		bottom: 70px;
		position: absolute;
		width: calc(100% - 40px);

		button {
			width: 92px;
		}

		& :last-child {
			margin-left: 20px;
		}
	}
}
