.select-install-and-search {
  display: block;
  width: 100%;
  border: none;
  border-radius: 1px;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  appearance: none;
  color: #6c757d;
  padding-left: 10px;
  height: 40px;
  background-color: #EDEEF0;
  background-image: url('../../../../assets/image/path.svg');
  background-repeat: no-repeat, repeat;
  background-position:
    right 0.7em top 50%,
    0 0;
  background-size:
    0.85em auto,
    100%;
}