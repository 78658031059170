.new-recognition-card {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .new-recognition-card-header {
        label {
            font-weight: 400;
            font-size: 13px;
        }
    }

    .new-recognition-card-select-employee {
        label {
            font-weight: 500;
            font-size: 12px;
        }
    }

    .new-recognition-card-select-guidelines {
        display: flex;
        flex-direction: column;
        gap: 10px;
        label {
            font-weight: 500;
            font-size: 12px;
        }

        .new-recognition-card-select-guidelines-options {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 10px;

            .guideline-option {
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: center;

                label {
                    font-weight: 400;
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .new-recognition-card-select-guidelines-error {
            font-size: 11px !important;
            width: 100%;
            color: #C21618;
        }
    }

    .textarea-component .textarea-span-error-container {
       span {
           font-size: 11px !important;
           width: 100%;
       } 
    }
    .new-recognition-card-description {
        .new-recognition-card-description-count-characters {
            display: flex;
            justify-content: flex-end;
            font-size: 10px;
            font-weight: 500;
            color: #A6A6A6;
            margin-bottom: 0;
            width: 70px;
            margin-left: auto;
            margin-top: -16px;
        }
        label {
            font-weight: 400;
            font-size: 12px;
        }
        textarea {
            font-size: 12px;
        }
    }
}

.new-recognition-card-actions {
    display: flex;
    gap: 20px;
    justify-content: center;

    button {
        width: 100px;
    }
}

.card-component-render {
    z-index: -10000000;
    position: fixed;
}